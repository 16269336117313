import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { OverlayTrigger, Popover, Row, Col } from "react-bootstrap";

//ACTIONS
import * as BookingDataActions from "../../../../../../../../store/actions/bookingData";
import * as GlobalVarsActions from "../../../../../../../../store/actions/globalVars";

//STYLES
import * as TemplatesStyles from "./styles";

const times = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30"
];

function Diary(props) {
    const dispatch = useDispatch();

    const bookingData = useSelector((state) => state.bookingData);
    const globalVars = useSelector((state) => state.globalVars);
    const organisation = useSelector((state) => state.organisation);
    const userProfile = useSelector((state) => state.userProfile);

    const topDatesRow = useRef(null);
    const dataBody = useRef(null);

    const [settings, setSettings] = useState({
        times: [],
        startTime: "",
        finishTime: "",
        interval: "",
        dates: ["", "", "", "", "", "", ""],
        dayIndex: 0,
        days: [],
        weekSystemUUID: "",
        currentDay: "",
        pastWeek: false
    });

    const [layout, setLayout] = useState({
        slotClass: "",
        dayClass: "",
        days: []
    });

    useEffect(() => {
        setup();
    }, []);

    useEffect(() => {
        WorkOutDayDates(globalVars.topControls.weekBG, settings.days);
    }, [globalVars.topControls.weekBG]);

    function setup() {
        const layoutDays = props.layoutData.days.split(",");
        const days = [];

        const startTime = props.layoutData.startTime;
        const finishTime = props.layoutData.finishTime;
        const timeInterval = props.layoutData.timeInterval;

        for (const day of layoutDays) {
            days.push(day == "true");
        }

        let totalDays = 0;
        const dayDates = [];
        const weekBG = moment(props.weekBG);
        for (const [index, day] of days.entries()) {
            if (day) {
                totalDays++;
                dayDates.push(moment(weekBG.add(1, "d")).format("DD/MM/YYYY"));
            } else {
                dayDates.push("");
            }
        }

        const times = BuildTimes(startTime, finishTime, timeInterval);
        setLayout((prevState) => {
            return { ...prevState, slotClass: "session" + totalDays + "_slot", dayClass: "session" + totalDays + "_days", days: days };
        });

        setSettings((prevState) => {
            return {
                ...prevState,
                dates: dayDates,
                days: days,
                currentDay: moment().format("DD/MM/YYYY"),
                times: times,
                startTime: startTime,
                finishTime: finishTime,
                interval: timeInterval
            };
        });
    }

    function WorkOutDayDates(week, days) {
        const weekBG = moment(week);
        const dayDates = [];
        for (const [index, day] of days.entries()) {
            if (day) {
                dayDates.push(moment(weekBG.add(1, "d")).format("DD/MM/YYYY"));
            } else {
                dayDates.push("");
            }
        }
        setSettings((prevState) => {
            return {
                ...prevState,
                dates: dayDates
            };
        });
    }

    function BuildTimes(sTime, fTime, interval) {
        const ti = [];
        if (sTime !== "Start Time" && fTime !== "Finish Time" && interval !== 0) {
            const start = moment(sTime, "HH:mm");
            const finish = moment(fTime, "HH:mm");

            while (start.isBefore(finish)) {
                ti.push(start.format("HH:mm"));
                start.add(interval, "m");
            }

            setSettings((prevState) => {
                return { ...prevState, times: ti };
            });
        }

        return ti;
    }

    function handleBookClick(event) {
        if (!organisation.locked && userProfile.room_Write) {
            const { id } = event.target;
            console.log(id);
            const IDs = id.toString().split("-");

            dispatch(GlobalVarsActions.UpdateRoomSessionID(id));

            dispatch(GlobalVarsActions.UpdateRoomWeekBegin(settings.dates[0]));
            dispatch(GlobalVarsActions.UpdateRoomDate(settings.dates[IDs[0]]));

            dispatch(GlobalVarsActions.UpdateRoomSessionLabel(IDs[1]));
            dispatch(GlobalVarsActions.UpdateDiaryStartTime(settings.startTime));
            dispatch(GlobalVarsActions.UpdateDiaryFinishTime(settings.finishTime));
            dispatch(GlobalVarsActions.UpdateDiaryTimeInterval(settings.interval));

            dispatch(GlobalVarsActions.UpdateBookingOpen());
        }
    }

    function handleScrollDown() {
        dataBody.current.scrollTo(0, dataBody.current.scrollTop + 60);
    }

    function handleScrollUp() {
        dataBody.current.scrollTo(0, dataBody.current.scrollTop - 60);
    }

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column"
                // border: "2px solid orange"
            }}
        >
            <Row ref={topDatesRow}>
                <Col sm={1}></Col>
                <Col sm={10}>
                    <table style={TemplatesStyles.layout} width="100%" border="1px">
                        <thead>
                            <tr>
                                <td style={{ ...TemplatesStyles.layout, ...TemplatesStyles[layout.slotClass] }}>Session</td>
                                {layout.days.map((day, index) => {
                                    if (day) {
                                        return (
                                            <td
                                                style={
                                                    settings.currentDay === settings.dates[index]
                                                        ? {
                                                              ...TemplatesStyles[layout.dayClass],
                                                              ...TemplatesStyles.layout,
                                                              ...TemplatesStyles.currentDay
                                                          }
                                                        : {
                                                              ...TemplatesStyles[layout.dayClass],
                                                              ...TemplatesStyles.layout
                                                          }
                                                }
                                                key={index}
                                            >
                                                {dayNames[index]} <br /> {settings.dates[index]}
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                    </table>
                </Col>
                <Col sm={1}></Col>
            </Row>
            {topDatesRow.current != null && (
                <Row style={{ height: globalVars.mainBodyHeight - topDatesRow.current.clientHeight - 10 }}>
                    <Col sm={1}></Col>
                    <Col sm={10} ref={dataBody} style={{ height: "100%", overflow: "scroll" }}>
                        <table style={TemplatesStyles.layout} width="100%" border="1px">
                            <tbody>
                                {settings.times.map((time, timeIndex) => {
                                    let placement = "bottom";
                                    if (timeIndex >= settings.times.length - 4) {
                                        placement = "top";
                                    }
                                    return (
                                        <tr key={timeIndex}>
                                            <td style={{ ...TemplatesStyles[layout.slotClass], ...TemplatesStyles.layout }}>{time}</td>
                                            {layout.days.map((day, dayIndex) => {
                                                if (day) {
                                                    const name = bookingData.week + "-" + formatString(dayIndex) + "-" + time.replace(":", "");
                                                    if (bookingData.data[name] != null) {
                                                        if (bookingData.data[name].type == "single") {
                                                            return (
                                                                <SingleSlot
                                                                    key={dayIndex}
                                                                    index={dayIndex}
                                                                    session={time.replace(":", "")}
                                                                    data={bookingData.data[name]}
                                                                    placement={placement}
                                                                    dayClass={layout.dayClass}
                                                                />
                                                            );
                                                        } else if (bookingData.data[name].type == "repeat") {
                                                            return (
                                                                <RepeatSlot
                                                                    key={dayIndex}
                                                                    index={dayIndex}
                                                                    session={time.replace(":", "")}
                                                                    data={bookingData.data[name]}
                                                                    placement={placement}
                                                                    dayClass={layout.dayClass}
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <EmtpySlot
                                                                    key={dayIndex}
                                                                    index={dayIndex}
                                                                    session={time.replace(":", "")}
                                                                    book={handleBookClick}
                                                                    dayClass={layout.dayClass}
                                                                />
                                                            );
                                                        }
                                                    } else {
                                                        return <LoadingSlot key={dayIndex} />;
                                                    }
                                                }
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Col>
                    <Col sm={1}>
                        <div style={TemplatesStyles.buttons}>
                            <div className="scrollBar_upButton" onClick={handleScrollUp}>
                                <i className="fa-solid fa-angle-up"></i>
                            </div>
                            <div className="scrollBar_downButton" onClick={handleScrollDown}>
                                <i className="fa-solid fa-angle-down"></i>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
}

function LoadingSlot() {
    return <td style={TemplatesStyles.loadingLayout}>Loading...</td>;
}

function EmtpySlot(props) {
    const organisation = useSelector((state) => state.organisation);
    const globalVars = useSelector((state) => state.globalVars);
    const userProfile = useSelector((state) => state.userProfile);

    return (
        <td
            className={!organisation.locked && userProfile.room_Write ? "custom_emptySlot" : "custom_emptySlotDisabled"}
            style={
                globalVars.roomDetail.sessionID == `${props.index}-${props.session}`
                    ? TemplatesStyles.layoutSelected
                    : { ...TemplatesStyles.layout, ...TemplatesStyles[props.dayClass] }
            }
            key={props.index}
            id={props.index + "-" + props.session}
            onClick={props.book}
        >
            Book
        </td>
    );
}

function SingleSlot(props) {
    const timeID = props.data.firstSession.split("-")[1];
    const startTime = `${timeID[0]}${timeID[1]}:${timeID[2]}${timeID[3]}`;
    const startIndex = times.findIndex((ti) => {
        return ti == startTime;
    });
    const endTime = times[startIndex + parseInt(props.data.sessionTotal)];

    return (
        <OverlayTrigger
            id="overLay"
            placement={props.placement}
            delay={{ show: 1000, hide: 0 }}
            overlay={
                <Popover style={{ width: "100%" }}>
                    <Popover.Header>More Details...</Popover.Header>
                    <Popover.Body>
                        <strong>Class: </strong>
                        {props.data.sessionDes} <br />
                        <strong>Session: </strong> {startTime} - {endTime}
                        <br />
                        <strong>Booking Type: </strong> Single
                        {props.data.comments != "" && (
                            <>
                                <br />
                                <strong>Comments: </strong>
                                {props.data.comments}
                            </>
                        )}
                    </Popover.Body>
                </Popover>
            }
        >
            <td
                style={{ ...TemplatesStyles.layout, ...TemplatesStyles.singleSlot, ...TemplatesStyles[props.dayClass] }}
                key={props.index}
                id={props.index + "-" + props.session}
            >
                {props.data.user} <br /> {GetDepartment(props.data.department)}
            </td>
        </OverlayTrigger>
    );
}

function RepeatSlot(props) {
    const timeID = props.data.firstSession.split("-")[1];
    const startTime = `${timeID[0]}${timeID[1]}:${timeID[2]}${timeID[3]}`;
    const startIndex = times.findIndex((ti) => {
        return ti == startTime;
    });
    const endTime = times[startIndex + parseInt(props.data.sessionTotal)];

    return (
        <OverlayTrigger
            id="overLay"
            placement={props.placement}
            delay={{ show: 1000, hide: 0 }}
            overlay={
                <Popover style={{ width: "100%" }}>
                    <Popover.Header>More Details...</Popover.Header>
                    <Popover.Body>
                        <strong>Class: </strong>
                        {props.data.sessionDes} <br />
                        <strong>Session: </strong> {startTime} - {endTime}
                        <br />
                        <strong>Booking Type: </strong> Repeat - {props.data.repeatType} <br />
                        <strong>Repeat Until: </strong> {props.data.repeatUntil}
                        {props.data.comments != "" && (
                            <>
                                <br />
                                <strong>Comments: </strong>
                                {props.data.comments}
                            </>
                        )}
                    </Popover.Body>
                </Popover>
            }
        >
            <td
                style={{ ...TemplatesStyles.layout, ...TemplatesStyles.repeatSlot, ...TemplatesStyles[props.dayClass] }}
                key={props.index}
                id={props.index + "-" + props.session}
            >
                {props.data.user} <br /> {GetDepartment(props.data.department)}
            </td>
        </OverlayTrigger>
    );
}

//SCRIPT GLOBAL METHODS
function formatString(time) {
    if (time.toString().includes("b")) {
        time = time.replace("b", "");

        if (time.toString().length == 1) {
            return "b0" + time;
        } else {
            return time;
        }
    } else {
        if (time.toString().length == 1) {
            return "0" + time;
        } else {
            return time;
        }
    }
}

function GetDepartment(id) {
    const organisation = useSelector((state) => state.organisation);

    for (const department of organisation.departments) {
        if (department.uuid == id) {
            return department.name;
        }
    }
}

export default Diary;

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useParams, useNavigate } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as OrganisationActions from "../../../store/actions/organistion";
import * as BookingsActions from "../../../store/actions/bookings";
import * as RoomDetailsActions from "../../../store/actions/roomDetails";
import * as UserProfileActions from "../../../store/actions/userProfile";
import * as preBuiltFeaturesActions from "../../../store/actions/preBuiltFeatures";

//STYLES

//COMPONENTS
import PortalHome from "./portalHome";
import Security from "./frontEnd/pods/security/files/security";
import ChangeOfSeniorRequest from "./frontEnd/pods/security/files/changeofSeniorRequest";

function OrganisationPortal() {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user);
    const globalVars = useSelector((state) => state.globalVars);
    const UserProfileAdminGlobalSettings = useSelector((state) => state.UserProfileAdminGlobalSettings);

    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        getOrganisation();
    }, []);

    function getOrganisation() {
        dispatch(BookingsActions.UpdateBookingDetails(false));
        dispatch(RoomDetailsActions.UpdateShowSwitch(false));
        const data = { orgID: params.orgID, userProfiles: user.profiles, userSettings: UserProfileAdminGlobalSettings };
        console.log(data);
        Axios.post("/orgRouter/getOrganisation", data)
            .then((res) => {
                console.log(res.data);
                if (res.data.error != "Yes") {
                    dispatch(OrganisationActions.orgUpdateName(res.data.organisation.name));
                    dispatch(OrganisationActions.orgUpdateSignInLocal(res.data.organisation.auth_Local == "true"));
                    dispatch(OrganisationActions.orgUpdateSignInGoogle(res.data.organisation.auth_Google == "true"));
                    dispatch(OrganisationActions.orgUpdateMessage(res.data.organisation.message));
                    dispatch(OrganisationActions.orgUpdateUseDepartments(res.data.organisation.useDepartments == "true"));
                    dispatch(OrganisationActions.orgUpdateNoOfDepartments(res.data.organisation.noOfDepartments));
                    dispatch(OrganisationActions.orgUpdateDepartments(res.data.departments));
                    dispatch(OrganisationActions.orgUpdateAllocatedRooms(res.data.organisation.allocatedRooms));
                    dispatch(OrganisationActions.orgUpdateRedeemedRooms(res.data.organisation.redeemedRooms));
                    dispatch(OrganisationActions.orgUpdateRooms(res.data.rooms));
                    dispatch(OrganisationActions.orgUpdateLayouts(res.data.layouts));
                    dispatch(OrganisationActions.orgUpdateHolidays(res.data.holidays));
                    dispatch(OrganisationActions.orgUpdateLocked(res.data.organisation.locked == "true"));
                    dispatch(OrganisationActions.orgUpdateNewUserPasswords(res.data.organisation.newUserPasswords == "true"));
                    dispatch(OrganisationActions.orgUpdateResetUserPasswords(res.data.organisation.resetUserPasswords == "true"));
                    dispatch(preBuiltFeaturesActions.SetPreBuiltFeatures(res.data.features));

                    //USER PROFILE
                    if (!(res.data.organisation.useProfiles == "true") || res.data.userProfile.default) {
                        //default
                        dispatch(UserProfileActions.DefaultSettings());
                    } else {
                        dispatch(
                            UserProfileActions.Update_User_Settings(
                                res.data.userProfile.rooms,
                                res.data.userProfile.bookings,
                                res.data.userProfile.administrator,
                                res.data.userProfile.weeks
                            )
                        );
                    }

                    if (user.auth == false) {
                        navigate("/org/" + params.orgID + "/security/signIn", { replace: true });
                    } else {
                        if (user.orgID != params.orgID) {
                            navigate("/org/" + user.orgID + "/security/wrongOrganisation", { replace: true });
                        } else if (user.requestedPassword == "true") {
                            navigate("/org/" + params.orgID + "/security/changePassword", { replace: true });
                        } else if (user.new == "true") {
                            navigate("/org/" + params.orgID + "/security/createPassword", { replace: true });
                        } else if (user.SARequest == "true") {
                            navigate("/org/" + params.orgID + "/changeOfSeniorRequest"), { replace: true };
                        } else {
                            if (globalVars.fromSignIn) {
                                navigate("/org/" + params.orgID, { replace: true });
                            }
                        }
                    }
                    setLoaded(true);
                } else {
                    navigate("/", { replace: true });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div style={{ height: `${globalVars.mainBodyHeight}px` }}>
            {isLoaded && (
                <div style={{ height: `${globalVars.mainBodyHeight}px` }}>
                    <Routes>
                        <Route path="/*" element={<PortalHome />} />
                        <Route path="/security/*" element={<Security />} />
                        <Route path="/changeOfSeniorRequest" element={<ChangeOfSeniorRequest />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default OrganisationPortal;

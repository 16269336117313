import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { Button, ListGroup, Image, Modal, Form, Dropdown } from "react-bootstrap";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";
import * as UserProfileAdminSettingsActions from "../../../../../../../store/actions/globalSettings/userProfileAdminGlobalSettings";

//STYLES
import * as ProfileSettingsStyles from "../styles/profileSettings";

//IMAGE
import helpImg from "../../../images/help.png";

function ProfileSettings() {
    const dispatch = useDispatch();
    const params = useParams();

    const UserProfileAdminGlobalSettings = useSelector((state) => state.UserProfileAdminGlobalSettings);

    const [settings, setSettings] = useState({
        name: "",
        userUUID: "",
        nonProfileUsers: [],
        profileUsers: [],
        defaultPro: false,
        rooms: [],
        weeksTitle: "Weeks",
        tab: "bookings"
    });

    const [users, setUsers] = useState({
        all: [],
        profile: [],
        nonProfile: []
    });

    const [help, setHelp] = useState({
        open: false,
        heading: "",
        message: "",
        message2: ""
    });

    function handleCloseHelp() {
        setHelp((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [userHistory, setHistory] = useState({
        users: []
    });

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateAdminMenuID("userProfiles"));
        if (params.id == "default") {
            loadProfile(true);
        } else {
            loadProfile(false);
        }
    }, []);

    function loadProfile(defaultPro) {
        const data = { orgID: params.orgID, profileUUID: params.id, userSettings: UserProfileAdminGlobalSettings };
        Axios.post("/orgAdmin/userProfiles/loadProfile", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    let name = "";
                    if (defaultPro) {
                        name = "Default Profile (Access Level)";
                    } else {
                        name = data.userSettings.name;
                    }
                    setSettings((prevState) => {
                        return { ...prevState, name: name, defaultPro: defaultPro };
                    });
                }

                //USERS
                getProfileUsers(defaultPro, data.users);

                //ROOMS
                const UPRooms = data.UPRooms;
                const orgRooms = data.orgRooms;
                console.log(UPRooms);

                let rooms = [];

                if (!defaultPro) {
                    orgRooms.map((room) => {
                        let exsit = false;

                        UPRooms.map((UPRoom) => {
                            if (room.uuid == UPRoom.roomUUID) {
                                console.log(UPRoom);
                                exsit = true;
                                const obj = {
                                    uuid: room.uuid,
                                    name: room.name,
                                    view: UPRoom.view == "true",
                                    write: UPRoom.write == "true",
                                    edit: UPRoom.edit == "true",
                                    delete: UPRoom.delete == "true",
                                    repeat: UPRoom.repeat == "true"
                                };
                                rooms.push(obj);
                            }
                        });

                        if (!exsit) {
                            const roomObj = {
                                uuid: room.uuid,
                                name: room.name,
                                view: false,
                                write: false,
                                edit: false,
                                delete: false,
                                repeat: false
                            };
                            rooms.push(roomObj);
                        }
                    });

                    //USER SETTINGS
                    let settings = {};
                    const sectionKeys = Object.keys(UserProfileAdminGlobalSettings);

                    for (const key of sectionKeys) {
                        const settingKeys = Object.keys(UserProfileAdminGlobalSettings[key]);
                        const sectionSettings = {};
                        for (const setting of settingKeys) {
                            const type = UserProfileAdminGlobalSettings[key][setting].type;
                            let value = null;
                            if (type === "Boolean") {
                                value = data.userSettings[setting] === "true";
                            } else if (type === "Text") {
                                value = data.userSettings[setting];
                            }
                            sectionSettings[setting] = { type: type, value: value };
                        }
                        settings[key] = sectionSettings;
                    }

                    dispatch(UserProfileAdminSettingsActions.SetSettings(settings));

                    let title = "";
                    const id = settings["weeks"]["goBackWeeks"].value;

                    if (id === "0") {
                        title = "None";
                    } else if (id === "1") {
                        title = `${id} week`;
                    } else {
                        title = `${id} weeks`;
                    }

                    //     //SAVING THE ROOMS AND THE WEEK TITLE
                    setSettings((prevState) => {
                        return { ...prevState, rooms: rooms, weeksTitle: title };
                    });
                } else {
                    orgRooms.map((room) => {
                        const roomObj = { uuid: room.uuid, name: room.name, view: true, write: true, edit: false, delete: false, repeat: false };
                        rooms.push(roomObj);
                    });

                    setSettings((prevState) => {
                        return { ...prevState, rooms: rooms };
                    });
                }

                // handleResize();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getProfileUsers(defaultPro, allUsers) {
        let nonProfileUsers = [];
        let profileUsers = [];

        if (!defaultPro) {
            allUsers.map((user) => {
                const profiles = user.userProfiles.split(",");
                if (profiles.includes(params.id.toString())) {
                    profileUsers.push(user);
                } else {
                    nonProfileUsers.push(user);
                }
            });

            setUsers((prevState) => {
                return { ...prevState, all: allUsers, profile: profileUsers, nonProfile: nonProfileUsers };
            });
        } else {
            allUsers.map((user) => {
                if (user.userProfiles == "") {
                    profileUsers.push(user);
                }
            });

            setUsers((prevState) => {
                return { ...prevState, all: allUsers, profile: profileUsers, nonProfile: nonProfileUsers };
            });
        }
    }

    function showHelp() {
        const message =
            "The priority system only affects the rooms in the profiles. If the same room in two different profiles is marked ‘view’, the priority profile settings are set for that room.";
        const message2 =
            "The priority system does not affect any other settings in the profile. If a setting is turned on in any profile that is assigned to a particular user, the setting is on for that user.";

        setHelp({ heading: "Help: Priority System", message: message, message2: message2, open: true });
    }

    function onChange() {
        //DO NOTHING
    }

    //USERS
    function handleUserClicked(uuid) {
        setSettings((prevState) => {
            return { ...prevState, userUUID: uuid };
        });
    }

    function handleMoveUserIntoProfile() {
        if (settings.userUUID == "") {
            setModal({ heading: "Move User", message: "You need to select an user first", open: true });
        } else {
            const userList = [...users.all];
            userList.map((user) => {
                if (user.uuid == settings.userUUID) {
                    let profiles = [];

                    if (user.userProfiles != "") {
                        profiles = user.userProfiles.split(",");
                    }

                    if (profiles.length > 5) {
                        setModal({ heading: "Move User", message: "This user has already got 5 profile assigned to them", open: true });
                    } else {
                        profiles.push(params.id);
                        user.userProfiles = profiles.toString();
                    }

                    insertUserHistory(settings.userUUID);
                    getProfileUsers(settings.defaultPro, userList);
                }
            });
        }
    }

    function handleMoveUserOutProfile() {
        if (settings.userUUID == "") {
            setModal({ heading: "Move User", message: "You need to select an user first", open: true });
        } else {
            const userList = [...users.all];
            userList.map((user) => {
                if (user.uuid == settings.userUUID) {
                    let profiles = [];

                    if (user.userProfiles != "") {
                        profiles = user.userProfiles.split(",");
                    }

                    const ID = profiles.indexOf(params.id.toString());

                    profiles.splice(ID, 1);

                    user.userProfiles = profiles.toString();

                    insertUserHistory(settings.userUUID);
                    getProfileUsers(settings.defaultPro, userList);
                }
            });
        }
    }

    function insertUserHistory(uuid) {
        if (!userHistory.users.includes(uuid)) {
            setHistory(() => {
                return { users: [...userHistory.users, uuid] };
            });
        }
    }

    function handleRoomSelectProperty(uuid, property) {
        const rms = settings.rooms;

        rms.forEach((room) => {
            if (room.uuid == uuid) {
                if (room[property]) {
                    //turns to false
                    room[property] = false;

                    if (property == "view") {
                        room.write = false;
                        room.edit = false;
                        room.delete = false;
                        room.repeat = false;
                    }
                } else {
                    //turns to true
                    room[property] = true;
                }
            }
        });

        setSettings((prevState) => {
            return { ...prevState, rooms: rms };
        });
    }

    function handleChangeTab(tab) {
        setSettings((prevState) => {
            return { ...prevState, tab: tab };
        });
    }

    //SETTINGS
    function checkSettingOnClick(event) {
        const { id, name, checked } = event.target;

        dispatch(UserProfileAdminSettingsActions.UpdateSetting(name, id, checked));
    }

    function changeAdminSetting(event) {
        const { id, checked } = event.target;

        dispatch(UserProfileAdminSettingsActions.UpdateAdministratorSettings(id, checked));
    }

    function changeWeekSettingHandle(event) {
        const { id, name } = event.target;

        dispatch(UserProfileAdminSettingsActions.UpdateSetting(name, "goBackWeeks", id));

        let title = "";

        if (id === "0") {
            title = "None";
        } else if (id === "1") {
            title = `${id} week`;
        } else {
            title = `${id} weeks`;
        }

        setSettings((prevState) => {
            return { ...prevState, weeksTitle: title };
        });
    }

    function handleUpdateProfile() {
        const usersToUpdate = [];

        userHistory.users.map((id) => {
            users.all.map((user) => {
                if (user.uuid == id) {
                    usersToUpdate.push(user);
                }
            });
        });

        const data = {
            orgID: params.orgID,
            profileUUID: params.id,
            usersToUpdate: usersToUpdate,
            profileUserCount: users.profile.length,
            rooms: settings.rooms,
            userSettings: UserProfileAdminGlobalSettings
        };

        Axios.post("/orgAdmin/userProfiles/saveProfile", data)
            .then((res) => {
                const data = res.data;
                if (data.message == "User Profile Updated") {
                    setModal({ heading: "Profile Settings", message: data.message, open: true });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div style={ProfileSettingsStyles.body}>
            <div style={ProfileSettingsStyles.mainHeading}>
                <h2>Profile Settings</h2>
                <h3>{settings.name}</h3>
            </div>
            <div style={ProfileSettingsStyles.topBanner}>
                {!settings.defaultPro && (
                    <div>
                        <Button variant="primary" onClick={handleUpdateProfile}>
                            Update
                        </Button>
                        <Image style={ProfileSettingsStyles.helpImage} src={helpImg} onClick={showHelp}></Image>
                    </div>
                )}
            </div>
            <div style={ProfileSettingsStyles.restBody}>
                <div style={{ height: "40%" }}>
                    <div style={ProfileSettingsStyles.usersListContainer}>
                        <div style={ProfileSettingsStyles.usersListHeading}>
                            <strong>Users in this profile:</strong>
                        </div>
                        <div style={ProfileSettingsStyles.usersList}>
                            <ListGroup>
                                {settings.defaultPro ? (
                                    <div>
                                        {users.profile.map((user, index) => {
                                            return (
                                                <ListGroup.Item href={"#" + index} active={user.uuid == settings.userUUID ? true : false} key={index}>
                                                    {user.displayName}
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div>
                                        {users.profile.map((user, index) => {
                                            return (
                                                <ListGroup.Item
                                                    href={"#" + index}
                                                    key={index}
                                                    action
                                                    active={user.uuid == settings.userUUID ? true : false}
                                                    onClick={() => handleUserClicked(user.uuid)}
                                                >
                                                    {user.displayName}
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </div>
                                )}
                            </ListGroup>
                        </div>
                    </div>
                    <div style={ProfileSettingsStyles.usersCenterContainer}>
                        {!settings.defaultPro ? (
                            <div style={ProfileSettingsStyles.usersCenterContainerBtn}>
                                <Button onClick={handleMoveUserIntoProfile}>Move into profile</Button>
                                <br />
                                <Button onClick={handleMoveUserOutProfile}>Move out of profile</Button>
                            </div>
                        ) : null}
                    </div>
                    <div style={ProfileSettingsStyles.usersListContainer}>
                        {!settings.defaultPro ? (
                            <div style={{ height: "100%" }}>
                                <div style={ProfileSettingsStyles.usersListHeading}>
                                    <strong>Users not in this profile:</strong>
                                </div>
                                <div style={ProfileSettingsStyles.usersList}>
                                    <ListGroup>
                                        {users.nonProfile.map((user, index) => {
                                            return (
                                                <ListGroup.Item
                                                    href={"#" + index}
                                                    key={index}
                                                    action
                                                    active={user.uuid == settings.userUUID ? true : false}
                                                    onClick={() => handleUserClicked(user.uuid)}
                                                >
                                                    {user.displayName}
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <strong>
                                    This profile cannot be updated as it is the default profile which the system uses if a user is not assigned to a
                                    profile. <br />
                                    <br />
                                    This is the profile for User Level which is set up by High-View Studios. <br /> <br />
                                    An &apos;user&apos; can see all the rooms and make bookings.
                                    <br /> <br />
                                    An &apos;admin&apos; can make bookings on behalf of any user and any department. They are also able to make
                                    repeated bookings and able to delete any booking. <br /> <br />
                                    To have custom settings please make a profile and assign users to it.
                                </strong>
                            </div>
                        )}
                    </div>
                </div>
                <br />
                <div style={ProfileSettingsStyles.roomsContainer}>
                    <strong>Rooms:</strong> <br />
                    <div style={ProfileSettingsStyles.roomsWarning}>
                        <strong>Rooms are affected by the priority system, see &apos;help&apos; for more information.</strong> <br />
                        <strong>The edit feature is not built yet.</strong>
                    </div>
                    <div style={ProfileSettingsStyles.columnRight}>
                        <Button variant="primary" disabled>
                            Selected
                        </Button>
                        <Button variant="outline-primary" disabled>
                            Unselected
                        </Button>
                    </div>
                    <div style={ProfileSettingsStyles.roomsListContainer}>
                        <ListGroup>
                            {settings.rooms.map((room, index) => {
                                return (
                                    <ListGroup.Item key={index}>
                                        {settings.defaultPro ? (
                                            <div>
                                                <div style={ProfileSettingsStyles.rooms_title}>{room.name}</div>
                                                <div style={ProfileSettingsStyles.rooms_buttonContainer}>
                                                    <Button variant={room.view ? "primary" : "outline-primary"} disabled>
                                                        View
                                                    </Button>
                                                </div>
                                                <div style={ProfileSettingsStyles.rooms_buttonContainer}>
                                                    {room.view && (
                                                        <Button variant={room.write ? "primary" : "outline-primary"} disabled>
                                                            Write
                                                        </Button>
                                                    )}
                                                </div>
                                                <div style={ProfileSettingsStyles.rooms_buttonContainer}>
                                                    {room.view && (
                                                        <Button variant={room.edit ? "primary" : "outline-primary"} disabled>
                                                            Edit
                                                        </Button>
                                                    )}
                                                </div>
                                                <div style={ProfileSettingsStyles.rooms_buttonContainer}>
                                                    {room.view && (
                                                        <Button variant={room.delete ? "primary" : "outline-primary"} disabled>
                                                            Delete
                                                        </Button>
                                                    )}
                                                </div>
                                                <div style={ProfileSettingsStyles.rooms_buttonContainer}>
                                                    {room.view && (
                                                        <Button variant={room.repeat ? "primary" : "outline-primary"} disabled>
                                                            Repeat
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div style={ProfileSettingsStyles.rooms_title}>{room.name}</div>
                                                <div style={ProfileSettingsStyles.rooms_buttonContainer}>
                                                    <Button
                                                        id={index == 0 ? "viewBtn" : null}
                                                        style={ProfileSettingsStyles.buttonWidth}
                                                        variant={room.view ? "primary" : "outline-primary"}
                                                        onClick={() => handleRoomSelectProperty(room.uuid, "view")}
                                                    >
                                                        View
                                                    </Button>
                                                </div>
                                                <div style={ProfileSettingsStyles.rooms_buttonContainer}>
                                                    <Button
                                                        id={index == 0 ? "writeBtn" : null}
                                                        style={
                                                            room.view
                                                                ? ProfileSettingsStyles.buttonWidth
                                                                : {
                                                                      ...ProfileSettingsStyles.buttonWidth,
                                                                      ...ProfileSettingsStyles.hide
                                                                  }
                                                        }
                                                        variant={room.write ? "primary" : "outline-primary"}
                                                        onClick={() => handleRoomSelectProperty(room.uuid, "write")}
                                                    >
                                                        {settings.writeBtnSize <= 60 ? <i className="fas fa-pencil-alt"></i> : "Write"}
                                                    </Button>
                                                </div>
                                                <div style={ProfileSettingsStyles.rooms_buttonContainer}>
                                                    <Button
                                                        id={index == 0 ? "editBtn" : null}
                                                        style={
                                                            room.view
                                                                ? ProfileSettingsStyles.buttonWidth
                                                                : {
                                                                      ...ProfileSettingsStyles.buttonWidth,
                                                                      ...ProfileSettingsStyles.hide
                                                                  }
                                                        }
                                                        variant={room.edit ? "primary" : "outline-primary"}
                                                        onClick={() => handleRoomSelectProperty(room.uuid, "edit")}
                                                    >
                                                        {settings.editBtnSize <= 50 ? <i className="fas fa-edit"></i> : "Edit"}
                                                    </Button>
                                                </div>
                                                <div style={ProfileSettingsStyles.rooms_buttonContainer}>
                                                    <Button
                                                        id={index == 0 ? "deleteBtn" : null}
                                                        style={
                                                            room.view
                                                                ? ProfileSettingsStyles.buttonWidth
                                                                : {
                                                                      ...ProfileSettingsStyles.buttonWidth,
                                                                      ...ProfileSettingsStyles.hide
                                                                  }
                                                        }
                                                        variant={room.delete ? "primary" : "outline-primary"}
                                                        onClick={() => handleRoomSelectProperty(room.uuid, "delete")}
                                                    >
                                                        {settings.deleteBtnSize <= 70 ? <i className="fas fa-trash-alt"></i> : "Delete"}
                                                    </Button>
                                                </div>
                                                <div style={ProfileSettingsStyles.rooms_buttonContainer}>
                                                    <Button
                                                        id={index == 0 ? "repeatBtn" : null}
                                                        style={
                                                            room.view
                                                                ? ProfileSettingsStyles.buttonWidth
                                                                : {
                                                                      ...ProfileSettingsStyles.buttonWidth,
                                                                      ...ProfileSettingsStyles.hide
                                                                  }
                                                        }
                                                        variant={room.repeat ? "primary" : "outline-primary"}
                                                        onClick={() => handleRoomSelectProperty(room.uuid, "repeat")}
                                                    >
                                                        {settings.repeatBtnSize <= 70 ? <i className="fas fa-redo-alt"></i> : "Repeat"}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    </div>
                </div>
                {!settings.defaultPro && (
                    <div style={ProfileSettingsStyles.settingsContainer}>
                        <strong>User Settings</strong>
                        <div style={ProfileSettingsStyles.settingsAbove}>
                            <div
                                style={
                                    settings.tab == "bookings"
                                        ? { ...ProfileSettingsStyles.settings_selectedTab, left: "0" }
                                        : { ...ProfileSettingsStyles.settings_tab, left: "0" }
                                }
                                onClick={handleChangeTab.bind(this, "bookings")}
                            >
                                Bookings
                            </div>
                            <div
                                style={
                                    settings.tab == "administration"
                                        ? { ...ProfileSettingsStyles.settings_selectedTab, left: "25%" }
                                        : { ...ProfileSettingsStyles.settings_tab, left: "25%" }
                                }
                                onClick={handleChangeTab.bind(this, "administration")}
                            >
                                Administration
                            </div>
                            <div
                                style={
                                    settings.tab == "weeks"
                                        ? { ...ProfileSettingsStyles.settings_selectedTab, left: "50%" }
                                        : { ...ProfileSettingsStyles.settings_tab, left: "50%" }
                                }
                                onClick={handleChangeTab.bind(this, "weeks")}
                            >
                                Weeks
                            </div>
                        </div>
                        <div style={ProfileSettingsStyles.settings}>
                            {settings.tab == "bookings" && (
                                <div>
                                    <Form.Check
                                        type="checkbox"
                                        id="viewAllUsers"
                                        name="bookings"
                                        checked={UserProfileAdminGlobalSettings.bookings.viewAllUsers.value}
                                        label="View all users"
                                        onClick={checkSettingOnClick}
                                        onChange={onChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="viewAllDepartments"
                                        name="bookings"
                                        checked={UserProfileAdminGlobalSettings.bookings.viewAllDepartments.value}
                                        label="View all departments"
                                        onClick={checkSettingOnClick}
                                        onChange={onChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="viewAllOrgBookings"
                                        name="bookings"
                                        checked={UserProfileAdminGlobalSettings.bookings.viewAllOrgBookings.value}
                                        label="View all organisation's bookings"
                                        onClick={checkSettingOnClick}
                                        onChange={onChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="viewOverviewAllTimetables"
                                        name="bookings"
                                        checked={UserProfileAdminGlobalSettings.bookings.viewOverviewAllTimetables.value}
                                        label="View overview of all timetable rooms"
                                        onClick={checkSettingOnClick}
                                        onChange={onChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="viewOverviewAllDiaries"
                                        name="bookings"
                                        checked={UserProfileAdminGlobalSettings.bookings.viewOverviewAllDiaries.value}
                                        label="View overview of all diary rooms"
                                        onClick={checkSettingOnClick}
                                        onChange={onChange}
                                    />
                                </div>
                            )}
                            {settings.tab == "administration" && (
                                <div>
                                    <div style={ProfileSettingsStyles.settings_adminHalf}>
                                        <Form.Check
                                            type="checkbox"
                                            id="admin_orgAdminAccess"
                                            name="administrator"
                                            checked={UserProfileAdminGlobalSettings.administrator.orgAdminAccess.value}
                                            label="Organisation Admin Access"
                                            disabled
                                            onChange={onChange}
                                        />{" "}
                                        <br />
                                        Select one section to activite the Organisation Admin Access
                                    </div>
                                    <div style={ProfileSettingsStyles.settings_adminHalf}>
                                        <strong>Sections: </strong>
                                        <br />
                                        <br />
                                        <Form.Check
                                            type="checkbox"
                                            id="admin_loginSettings"
                                            name="dministrator"
                                            checked={UserProfileAdminGlobalSettings.administrator.admin_loginSettings.value}
                                            label="Login Settings"
                                            onClick={changeAdminSetting}
                                            onChange={onChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="admin_departments"
                                            name="administrator"
                                            checked={UserProfileAdminGlobalSettings.administrator.admin_departments.value}
                                            label="Departments"
                                            onClick={changeAdminSetting}
                                            onChange={onChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="admin_users"
                                            name="administrator"
                                            checked={UserProfileAdminGlobalSettings.administrator.admin_users.value}
                                            label="Users"
                                            onClick={changeAdminSetting}
                                            onChange={onChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="admin_rooms"
                                            name="administrator"
                                            checked={UserProfileAdminGlobalSettings.administrator.admin_rooms.value}
                                            label="Rooms"
                                            onClick={changeAdminSetting}
                                            onChange={onChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="admin_layouts"
                                            name="administrator"
                                            checked={UserProfileAdminGlobalSettings.administrator.admin_layouts.value}
                                            label="Layouts"
                                            onClick={changeAdminSetting}
                                            onChange={onChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="admin_weekSystemHolidays"
                                            name="administrator"
                                            checked={UserProfileAdminGlobalSettings.administrator.admin_weekSystemHolidays.value}
                                            label="Week System / Holidays"
                                            onClick={changeAdminSetting}
                                            onChange={onChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="admin_userProfiles"
                                            name="administrator"
                                            checked={UserProfileAdminGlobalSettings.administrator.admin_userProfiles.value}
                                            label="User Profiles"
                                            onClick={changeAdminSetting}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            )}
                            {settings.tab == "weeks" && (
                                <div>
                                    <Dropdown>
                                        Number of weeks to go back:
                                        <Dropdown.Toggle variant="primary">{settings.weeksTitle}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item id="0" name="weeks" onClick={changeWeekSettingHandle}>
                                                0 Weeks
                                            </Dropdown.Item>
                                            <Dropdown.Item id="1" name="weeks" onClick={changeWeekSettingHandle}>
                                                1 Week
                                            </Dropdown.Item>
                                            <Dropdown.Item id="2" name="weeks" onClick={changeWeekSettingHandle}>
                                                2 Weeks
                                            </Dropdown.Item>
                                            <Dropdown.Item id="4" name="weeks" onClick={changeWeekSettingHandle}>
                                                4 Weeks
                                            </Dropdown.Item>
                                            <Dropdown.Item id="8" name="weeks" onClick={changeWeekSettingHandle}>
                                                8 Weeks
                                            </Dropdown.Item>
                                            <Dropdown.Item id="12" name="weeks" onClick={changeWeekSettingHandle}>
                                                12 Weeks
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <br />
                                    You can only go back up to 12 weeks
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div style={{ height: "20px", clear: "both" }}></div>
            </div>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={help.open} onHide={handleCloseHelp}>
                <Modal.Header closeButton>
                    <Modal.Title>{help.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {help.message}
                    <br /> <br /> {help.message2}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseHelp}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ProfileSettings;

export const body = {
    paddingTop: "30px",
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%"
};

export const heading = {
    textAlign: "center"
};

export const bookingSlot = {
    backgroundColor: "darkgreen",
    color: "white"
};

export const collideSlot = {
    backgroundColor: "orange"
};

import Axios from "axios";

export const SET_DATA = "SET_DATA";

export const UpdateBookingData = (data, orgID, roomID, weekSlot, days, weekBG) => {
    return {
        type: SET_DATA,
        data: data,
        orgID: orgID,
        roomID: roomID,
        week: weekSlot,
        days: days,
        weekBG: weekBG
    };
};

export const GetData = (roomID, weekBG, days) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const user = getState().user;
            // const globalVars = getState().globalVars;
            const organisation = getState().organisation;

            const weekSlot = formatString(weekBG.week()) + "-" + weekBG.format("YY");

            const mySQLData = {
                orgID: user.orgID,
                room: roomID,
                week: weekSlot,
                days: days,
                weekBG: weekBG.format("DD/MM/YYYY")
            };
            console.log(mySQLData);
            Axios.post("/bookings/getBookings", mySQLData)
                .then((res) => {
                    const recievedData = res.data;
                    console.log(recievedData.bookings);
                    dispatch({
                        type: SET_DATA,
                        data: recievedData.bookings,
                        orgID: user.orgID,
                        roomID: roomID,
                        week: weekSlot,
                        days: days,
                        weekBG: weekBG,
                        loaded: true
                    });
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    };
};

function formatString(time) {
    if (time.toString().includes("b")) {
        time = time.replace("b", "");

        if (time.toString().length == 1) {
            return "b0" + time;
        } else {
            return time;
        }
    } else {
        if (time.toString().length == 1) {
            return "0" + time;
        } else {
            return time;
        }
    }
}

export const breakID = {
    paddingTop: "20px",
    paddingLeft: "10px"
};

export const slotID = {
    padding: "10px"
};

export const breakPadding = {
    paddingTop: "10px"
};

export const colorModal90w = {
    width: "270px"
};

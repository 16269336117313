import React from "react";
import { useSelector } from "react-redux";

function MenuButton(props) {
    const globalVars = useSelector((state) => state.globalVars);

    return (
        <div>
            <div
                className={globalVars.adminMenuSelection == props.followOnID ? "mainMenu_buttonSelected" : "mainMenu_button"}
                onClick={props.click.bind(this, props.followOnID)}
            >
                {props.title}
            </div>
        </div>
    );
}

export default MenuButton;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, ListGroup, Modal, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";
import * as OrganisationActions from "../../../../../../../store/actions/organistion";

//STYLES
import * as RoomsStyles from "../styles/rooms";

function Rooms() {
    const dispatch = useDispatch();
    const params = useParams();

    const organisation = useSelector((state) => state.organisation);

    const [settings, setSettings] = useState({
        rooms: [],
        layouts: [],
        showAdd: true
    });

    const modalDefaultValues = {
        open: false,
        name: "",
        description: "",
        countersMax: {
            name: 50,
            description: 100
        },
        weekSystem: false,
        selectedLayout: 0,
        layoutEditLabel: "",
        triedSubmit: false,
        bottomError: "",
        edit: false,
        editUUID: 0
    };

    const [modal, setModal] = useState(modalDefaultValues);

    function handleModalClose() {
        setModal(modalDefaultValues);
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [bModal, setBModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleBModalClose() {
        setBModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateAdminMenuID("rooms"));
        onLoad();
    }, []);

    function onLoad() {
        const data = { orgID: params.orgID };
        Axios.post("/orgAdmin/layouts/getLayouts", data)
            .then((res) => {
                setSettings((prevState) => {
                    return { ...prevState, layouts: res.data.layouts, rooms: organisation.rooms };
                });
                checkIfAddRoomsAllowed(organisation.allocatedRooms, organisation.redeemedRooms, res.data.layouts.length);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function checkIfAddRoomsAllowed(allocatedRooms, redeemedRooms, noOfLayouts) {
        if (allocatedRooms === redeemedRooms) {
            setSettings((prevState) => {
                return { ...prevState, showAdd: false };
            });
        } else if (noOfLayouts === 0) {
            setSettings((prevState) => {
                return { ...prevState, showAdd: false };
            });
        } else {
            setSettings((prevState) => {
                return { ...prevState, showAdd: true };
            });
        }
    }

    function handleOpenAddModal() {
        setModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleOpenEditModal(uuid) {
        const room = settings.rooms.find((roo) => roo.uuid == uuid);
        console.log(room);

        setModal((prevState) => {
            return {
                ...prevState,
                edit: true,
                editUUID: uuid,
                name: room.name,
                description: room.description,
                weekSystem: room.weekSystem == "true",
                layoutEditLabel: settings.layouts.find((layout) => layout.uuid == room.layout).name,
                open: true
            };
        });
    }

    function handleModalTextChange(event) {
        const { value, name } = event.target;

        setModal((prevState) => {
            return { ...prevState, [name]: value };
        });
    }

    function handleWeekCheckChanged(event) {
        const { checked } = event.target;

        if (checked) {
            const data = { orgID: params.orgID };

            Axios.post("/orgAdmin/rooms/getMainOrgWeekSystem", data)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.weekSystem == "false") {
                        setBModal({
                            heading: "Week System",
                            message:
                                "You cannot turn on a room week system until you turn on the main week system in the 'Week System / Holidays' tab",
                            open: true
                        });

                        setModal((prevState) => {
                            return { ...prevState, weekSystem: false };
                        });
                    } else {
                        setModal((prevState) => {
                            return { ...prevState, weekSystem: checked };
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setModal((prevState) => {
                return { ...prevState, weekSystem: checked };
            });
        }
    }

    function handleLayoutClicked(id) {
        setModal((prevState) => {
            return { ...prevState, selectedLayout: id };
        });
    }

    function handleAddRoom() {
        if (modal.name == "") {
            setModal((prevState) => {
                return { ...prevState, triedSubmit: true };
            });
        } else {
            if (checkNameExsist(modal.name)) {
                setModal((prevState) => {
                    return { ...prevState, bottomError: "There is already a room with this name", triedSubmit: true };
                });
            } else {
                const data = {
                    orgID: params.orgID,
                    name: modal.name,
                    details: checkForRoomDetails(),
                    description: modal.description,
                    layout: settings.layouts[modal.selectedLayout].uuid,
                    weekSystem: modal.weekSystem
                };

                Axios.post("/orgAdmin/rooms/addRoom", data)
                    .then((res) => {
                        const data = res.data;

                        if (data.message === "Successfully added room") {
                            dispatch(OrganisationActions.orgUpdateRedeemedRooms(data.redeemedRooms));

                            setSettings((prevState) => {
                                return { ...prevState, rooms: data.rooms };
                            });

                            setModal(modalDefaultValues);

                            checkIfAddRoomsAllowed(organisation.allocatedRooms, data.redeemedRooms, settings.layouts.length);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }

    function checkNameExsist(name) {
        let check = false;

        for (const room of settings.rooms) {
            if (room.name == name) {
                check = true;
                break;
            }
        }

        return check;
    }

    function checkForRoomDetails() {
        if (modal.description !== "") {
            return "True";
        } else {
            return "False";
        }
    }

    function handleOpenRemoveYN() {
        setModalYN({
            heading: "Remove Room",
            message: `Are you sure you want to delete this room and all it's bookings?`,
            acceptName: "Yes",
            acceptFunction: handleDeleteRoomAccept,
            showAccept: true,
            cancelName: "No",
            showCancel: true,
            open: true
        });
    }

    function handleDeleteRoomAccept() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        const data = { orgID: params.orgID, roomUUID: modal.editUUID };

        Axios.post("/orgAdmin/rooms/deleteRoom", data)
            .then((res) => {
                const data = res.data;

                if (data.message === "Successfully deleted room") {
                    dispatch(OrganisationActions.orgUpdateRedeemedRooms(data.redeemedRooms));

                    setSettings((prevState) => {
                        return { ...prevState, rooms: data.rooms };
                    });
                    setModal(modalDefaultValues);

                    checkIfAddRoomsAllowed(organisation.allocatedRooms, data.redeemedRooms, settings.layouts.length);

                    dispatch(GlobalVarsActions.ResetToDefaults());
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleUpdate() {
        if (modal.name == "") {
            setModal((prevState) => {
                return { ...prevState, triedSubmit: true };
            });
        } else {
            const data = {
                orgID: params.orgID,
                uuid: modal.editUUID,
                name: modal.name,
                details: checkForRoomDetails(),
                description: modal.description
            };

            Axios.post("/orgAdmin/rooms/updateRoom", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "Yes") {
                        setModal((prevState) => {
                            return { ...prevState, bottomError: data.message, triedSubmit: true };
                        });
                    } else {
                        setSettings((prevState) => {
                            return { ...prevState, rooms: data.rooms };
                        });
                        setModal(modalDefaultValues);

                        checkIfAddRoomsAllowed(organisation.allocatedRooms, organisation.redeemedRooms, settings.layouts.length);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <div style={RoomsStyles.body}>
            <div style={RoomsStyles.topSection}>
                <div style={RoomsStyles.firstHalfTopSection}>
                    <div style={RoomsStyles.roomsCounts}>Allocated Rooms: {organisation.allocatedRooms}</div>
                    <div style={RoomsStyles.roomsCounts}>Redeemed Rooms: {organisation.redeemedRooms}</div>
                </div>
                <div style={RoomsStyles.secondHalfTopSection}>{settings.showAdd && <Button onClick={handleOpenAddModal}>Add</Button>}</div>
            </div>
            <div style={RoomsStyles.bottomContainer}>
                Double click on a room to edit it
                <div style={RoomsStyles.listContainer}>
                    <ListGroup>
                        {settings.rooms.map((room, index) => {
                            return (
                                <ListGroup.Item key={index} action onDoubleClick={handleOpenEditModal.bind(this, room.uuid)}>
                                    {room.name}
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </div>
            </div>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.edit ? "Edit" : "Add"} Room</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            isInvalid={modal.triedSubmit && modal.name.length == 0 ? true : false}
                            type="text"
                            name="name"
                            value={modal.name}
                            maxLength={modal.countersMax.name}
                            onChange={handleModalTextChange}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                        <div style={RoomsStyles.counter}>{`${modal.countersMax.name - modal.name.length}`}</div>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Description:</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="description"
                            rows={2}
                            value={modal.description}
                            onChange={handleModalTextChange}
                            maxLength={modal.countersMax.description}
                        />
                        <div style={RoomsStyles.counter}>{`${modal.countersMax.description - modal.description.length}`}</div>
                    </Form.Group>
                    <br />
                    {modal.edit ? (
                        <div>Week System: {modal.weekSystem ? "Enabled" : "Disabled"}</div>
                    ) : (
                        <Form.Check label="Use Week System" checked={modal.weekSystem} onChange={handleWeekCheckChanged} />
                    )}
                    <br />
                    {modal.edit ? (
                        <div>Layout: {modal.layoutEditLabel}</div>
                    ) : (
                        <div>
                            Layout:
                            {settings.layouts.length > 0 && (
                                <Dropdown>
                                    <Dropdown.Toggle>{settings.layouts[modal.selectedLayout].name}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {settings.layouts.map((layout, index) => {
                                            return (
                                                <Dropdown.Item key={index} onClick={handleLayoutClicked.bind(this, index)}>
                                                    {layout.name}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                    )}
                    <br />
                    {modal.triedSubmit && <div style={RoomsStyles.error}>{modal.bottomError}</div>}
                </Modal.Body>
                <Modal.Footer>
                    {modal.edit ? (
                        <div>
                            <Button onClick={handleOpenRemoveYN}>Remove</Button>
                            <Button onClick={handleUpdate}>Update</Button>
                        </div>
                    ) : (
                        <Button onClick={handleAddRoom}>Add</Button>
                    )}
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
            <Modal show={bModal.open} onHide={handleBModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{bModal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{bModal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleBModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Rooms;

import React from "react";
import { Row, Col } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";

//STYLES
import * as NavBarStyles from "./styles/navBarStyles";

import { TopControls } from "../new/private/frontEnd/pods/bookings/files/roomView";

function Nav() {
    return (
        <div style={NavBarStyles.mainBody}>
            <Row style={{ margin: "0" }}>
                <Col sm={3} style={{ padding: "0" }}>
                    <br />
                    <div style={NavBarStyles.title}>
                        <div style={NavBarStyles.topTitle}>My STAFF</div>
                        <div style={NavBarStyles.SubTitle}>Space, Time, Flexible, Facilities</div>
                    </div>
                </Col>
                <Col sm={9}>
                    <br />
                    <Routes>
                        <Route path="/org/:orgID/*" element={<div></div>} />
                        <Route path="/org/:orgID/room/:id" element={<TopControls />} />
                    </Routes>
                </Col>
            </Row>
        </div>
    );
}

export default Nav;

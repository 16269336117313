import * as Colours from "../../../../../../../globalStyles/colors";

export const body = {
    height: "100%"
    // width: "100%"
};

export const innerContainer = {
    backgroundColor: Colours.primary,
    width: "80%",
    borderRadius: "25px",
    color: "white",
    // fontSize: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
};

//Sign In
export const visible = {
    visibility: "visible"
};

export const hidden = {
    visibility: "hidden"
};

//Change Password & Create Password
export const changePasswordContainer = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export const controlsContainer = {
    width: "60%"
};

export const passwordHelpText = {
    width: "95%",
    color: "yellow"
};

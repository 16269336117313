import * as Colors from "./globalStyles/colors";

export const window = {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
};

export const backColor = {
    backgroundColor: Colors.background
};

import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import ServerPath from "../../../../../../../ServerPath";

//ACTIONS
import * as UserActions from "../../../../../../../store/actions/user";

//STYLES
import * as SecurityStyles from "../styles/security";

function SignIn() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [message, setmessage] = useState("none");

    const organisation = useSelector((state) => state.organisation);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        document.title = "STAFF - Sign In";
        onOpen();
    }, []);

    function onOpen() {
        if (user.auth) {
            navigate("/");
        }
    }

    function logIn(event) {
        event.preventDefault();
        const email = document.getElementById("txtEmail").value;
        const password = document.getElementById("txtPassword").value;

        const data = { email: email, password: password };
        Axios.post("/userLogin/login", data)
            .then((res) => {
                console.log(res.data);
                const message = res.data.message;

                if (message === "Logged in successful") {
                    dispatch(UserActions.userUpdateAuth(true));
                    dispatch(UserActions.userUpdateUUID(res.data.uuid));
                    dispatch(UserActions.userUpdateName(res.data.displayName));
                    dispatch(UserActions.userUpdateUserDepartments(res.data.departments));
                    dispatch(UserActions.userUpdateRole(res.data.role));
                    dispatch(UserActions.userUpdateNew(res.data.new));
                    dispatch(UserActions.userUpdateRequestedPassword(res.data.requestedPassword));
                    dispatch(UserActions.userUpdateOrgID(res.data.orgID));
                    dispatch(UserActions.userUpdateSARequest(res.data.SARequest));
                    dispatch(UserActions.userUpdateProfiles(res.data.userProfiles));

                    navigate("/");
                } else {
                    setmessage(res.data.info);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            logIn(event);
        }
    }

    function handleForgotPasswordClick() {
        navigate("../forgotPassword");
    }

    function handleGoogleLink() {
        const host = ServerPath();

        window.location.href = `${host}/userLogin/auth/google`;
    }

    return (
        <div style={{ width: "50%" }}>
            {organisation.signInLocal ? (
                <div>
                    <br />
                    <Form.Label>Email:</Form.Label>
                    <Form.Control id="txtEmail" type="email" />
                    <br />
                    <Form.Label>Password:</Form.Label>
                    <Form.Control id="txtPassword" type="password" onKeyPress={handleKeyPress} />
                    <br />
                    <div style={message == "none" ? SecurityStyles.hidden : SecurityStyles.visible}>{message}</div>
                    <Button variant="success" onClick={logIn}>
                        Log in
                    </Button>
                    <br />
                    <Button variant="success" onClick={handleForgotPasswordClick}>
                        Forgot Password
                    </Button>
                    <br />
                    <br />
                </div>
            ) : (
                <div></div>
            )}
            {organisation.signInGoogle ? (
                <Button variant="danger" onClick={handleGoogleLink}>
                    <i className="fab fa-google"></i> Log in with Google
                </Button>
            ) : (
                <div></div>
            )}
        </div>
    );
}

export default SignIn;

export const body = {
    paddingTop: "30px",
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%"
};

export const divRight = {
    textAlign: "right",
    height: "10%"
};

export const usersContainer = {
    height: "50%",
    overflow: "scroll"
};

export const userModalNameSide = {
    width: "50%",
    float: "left",
    paddingRight: "5px"
};

export const userModalDepartmentsSide = {
    width: "50%",
    height: "30vh",
    float: "left",
    paddingLeft: "5px",
    overflow: "scroll"
};

export const userModalDepartmentsSideInside = {
    height: "100%",
    overflow: "scroll"
};

export const departmentSelectedCore = {
    width: "25%",
    float: "left"
};

export const departmentName = {
    width: "50%",
    float: "left"
};

export const hiddenDiv = {
    width: "50%",
    float: "left",
    opacity: "0"
};

export const departmentsHeadings = {
    width: "50%",
    float: "left"
};

export const roleErrorShow = {
    color: "red"
};

export const roleErrorHidden = {
    visibility: "hidden"
};

export const departmentsErrors = {
    textAlign: "right"
};

export const addUserError = {
    color: "red",
    fontWeight: "bold"
};

export const listItem = {
    cursor: "pointer"
};

export const detailsTitle = {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "bold"
};

export const subTitle = {
    textAlign: "center",
    fontWeight: "bold"
};

export const halfContainer = {
    width: "50%",
    float: "left"
};

export const halfContainerCenter = {
    width: "50%",
    float: "left",
    textAlign: "center"
};

export const halfContainerRight = {
    width: "50%",
    float: "left",
    textAlign: "right"
};

export const formControl = {
    width: "50%"
};

export const departmentList = {
    height: "75%",
    overflow: "scroll"
};

export const advanceButton = {
    width: "35%"
};

export const senoirAdminRole = {
    fontWeight: "bold",
    color: "red"
};

import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import LayoutSettings from "./files/layoutSettings";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    });

    return (
        <div style={{ height: "100%" }}>
            {isLoaded && (
                <div style={{ height: "100%" }}>
                    <Routes>
                        <Route path="/" element={<LayoutSettings />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;

import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as UserActions from "../../../../../../../store/actions/user";

//STYLES
import * as SecurityStyles from "../styles/security";

function ForgotPassword() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [message, setmessage] = useState("none");
    const [sent, setSent] = useState(false);

    const organisation = useSelector((state) => state.organisation);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        document.title = "STAFF - Sign In";
    }, []);

    function requestPassword(event) {
        event.preventDefault();

        const email = document.getElementById("txtEmail").value;

        if (email != "") {
            const data = { email: email, resetUserPasswords: false };
            Axios.post("/security/requestPassword", data)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.userError == "Yes") {
                        setmessage(res.data.message);
                    } else {
                        setSent(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function handleBackToSignIn() {
        navigate("../signIn");
    }

    return (
        <div>
            {sent ? (
                <div>
                    <p>Your new password has been requested, please check your email</p>
                    <Button variant="success" onClick={handleBackToSignIn}>
                        Back to Sign In
                    </Button>
                </div>
            ) : (
                <div>
                    Forgot your password?
                    <br /> <br />
                    Please enter your email below:
                    <br />
                    <br />
                    <Form.Control id="txtEmail" type="email" />
                    <br />
                    <div style={message == "none" ? SecurityStyles.hidden : SecurityStyles.visible}>{message}</div>
                    <br />
                    <Button variant="success" onClick={requestPassword}>
                        Request new password
                    </Button>
                </div>
            )}
        </div>
    );
}

export default ForgotPassword;

import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as UserActions from "../../../../../../../store/actions/user";

//STYLES
import * as SecurityStyles from "../styles/security";

function CreatePassword() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [message, setmessage] = useState("none");
    const [passwordHelp, setPasswordHelp] = useState(false);

    const organisation = useSelector((state) => state.organisation);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        document.title = "STAFF - Sign In";
    }, []);

    function setPassword(event) {
        event.preventDefault();

        const oldPassword = document.getElementById("txtCurrentPassword").value;
        const newPassword = document.getElementById("txtNewPassword").value;
        const confirmPassword = document.getElementById("txtConfirmPassword").value;

        if (oldPassword != "" && newPassword != "" && confirmPassword != "") {
            const data = { oldPassword: oldPassword, newPassword: newPassword, confirmPassword: confirmPassword, userID: user.uuid };

            Axios.post("/security/createPassword", data)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.userError == "Yes") {
                        if (res.data.message == "Your password is not strong enough") {
                            setmessage(res.data.message);
                            setPasswordHelp(true);
                        } else {
                            setPasswordHelp(false);
                            setmessage(res.data.message);
                        }
                    } else {
                        if (res.data.message == "Updated user first password") {
                            dispatch(UserActions.userUpdateNew(true));

                            navigate("/", { replace: true });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <div style={SecurityStyles.changePasswordContainer}>
            <div style={SecurityStyles.controlsContainer}>
                Hello, and welcome to My STAFF
                <br />
                Please create a new password
                <br /> <br />
                <Form.Label>Current Password:</Form.Label>
                <Form.Control id="txtCurrentPassword" type="password" />
                <br />
                <Form.Label>New Password:</Form.Label>
                <Form.Control id="txtNewPassword" type="password" />
                <br />
                <Form.Label>Confirm Password:</Form.Label>
                <Form.Control id="txtConfirmPassword" type="password" />
            </div>
            <br />
            {message != "none" && message}
            {passwordHelp ? (
                <div style={SecurityStyles.passwordHelpText}>
                    Your password must contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character. It also requires to be a minimum 8
                    characters in length
                </div>
            ) : null}
            <Button variant="success" onClick={setPassword}>
                Set Password
            </Button>
        </div>
    );
}

export default CreatePassword;

import * as ReducerActions from "../actions/globalVars";
import update from "react-addons-update";
import moment from "moment";

const initalState = {
    mainBodyHeight: 0,
    body10thHeight: 0,

    bookingOpen: false,
    bookingEdit: false,
    editBookingData: {},

    roomDetail: {
        roomName: "",
        roomID: "",
        sessionID: "",
        sessionLabel: "",
        date: "",
        weekBeginDate: "",
        totalSessions: 0,
        dayList: [],
        layoutData: {},
        weekSystem: false,
        weekUUID: "",
        startTime: "",
        finishTime: "",
        timeInterval: ""
    },
    topControls: {
        weekBG: "",
        holiday: ""
    },
    mainMenu: {
        menu: "",
        followOnID: ""
    },
    adminMenuSelection: "",
    menuTransition: "",

    collideBookingsUpdate: false,

    fromSignIn: false
};

export default (state = initalState, action) => {
    switch (action.type) {
        case ReducerActions.UPDATE_BOOKING_OPEN:
            return { ...state, bookingOpen: true, bookingEdit: false };
        case ReducerActions.UPDATE_BOOKING_EDIT:
            return { ...state, bookingOpen: true, bookingEdit: true, editBookingData: action.data };
        case ReducerActions.UPDATE_BOOKING_CLOSE:
            return { ...state, bookingOpen: false, bookingEdit: false };
        case ReducerActions.UPDATE_ROOM_NAME:
            return update(state, {
                roomDetail: {
                    roomName: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_ROOM_ID:
            return update(state, {
                roomDetail: {
                    roomID: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_ROOM_LAYOUT_DATA:
            return update(state, {
                roomDetail: {
                    layoutData: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_ROOM_SESSION_ID:
            return update(state, {
                roomDetail: {
                    sessionID: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_ROOM_SESSION_LABEL:
            return update(state, {
                roomDetail: {
                    sessionLabel: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_ROOM_DATE:
            return update(state, {
                roomDetail: {
                    date: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_ROOM_WEEK_BEGIN:
            return update(state, {
                roomDetail: {
                    weekBeginDate: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_ROOM_TOTAL_SESSIONS:
            return update(state, {
                roomDetail: {
                    totalSessions: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_ROOM_DAY_LIST:
            return update(state, {
                roomDetail: {
                    dayList: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_ROOM_WEEK_SYSTEM:
            return update(state, {
                roomDetail: {
                    weekSystem: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_ROOM_WEEK_UUID:
            return update(state, {
                roomDetail: {
                    weekUUID: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_DIARY_START_TIME:
            return update(state, {
                roomDetail: {
                    startTime: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_DIARY_FINISH_TIME:
            return update(state, {
                roomDetail: {
                    finishTime: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_TOPCONTROLS_WEEKBG:
            return update(state, {
                topControls: {
                    weekBG: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_TOPCONTROLS_HOLIDAY:
            return update(state, {
                topControls: {
                    holiday: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_DIARY_TIME_INTERVAL:
            return update(state, {
                roomDetail: {
                    timeInterval: { $set: action.value }
                }
            });
        case ReducerActions.UPDATE_MENU_ID:
            return update(state, {
                mainMenu: {
                    menu: { $set: action.menu },
                    followOnID: { $set: action.followOnID }
                }
            });
        case ReducerActions.UPDATE_ADMINMENU_ID:
            return { ...state, adminMenuSelection: action.value };
        case ReducerActions.UPDATE_FORCE_COLLIDED_BOOKINGS:
            return { ...state, collideBookingsUpdate: action.value };
        case ReducerActions.UPDATE_FROM_SIGNIN:
            return { ...state, fromSignIn: action.value };
        case ReducerActions.RESET_TO_DEFAULTS:
            return { ...state, roomDetail: ReducerActions.ResetDefaults };
        case ReducerActions.UPDATE_MENU_TRANSITION:
            return { ...state, menuTransition: action.value };
        case ReducerActions.UPDATE_MAINBODY_HEIGHT:
            return { ...state, mainBodyHeight: action.height, body10thHeight: action.body10thHeight };
        default:
            return state;
    }
};

import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import moment from "moment";

//ACTIONS
import * as GlobalVarsActions from "../../../store/actions/globalVars";
import * as UserActions from "../../../store/actions/user";
import * as RoomDetailsActions from "../../../store/actions/roomDetails";
import * as UserProfileActions from "../../../store/actions/userProfile";
import * as BookingsActions from "../../../store/actions/bookings";
import * as BookingDataActions from "../../../store/actions/bookingData";

//STYLES
import * as PortalHomeStyles from "./components/styles/portalHome";

//COMPONENTS
import MenuButton from "./components/menuButton";

import RoomView from "./frontEnd/pods/bookings/files/roomView";
import YourBookings from "./frontEnd/pods/bookings/files/yourBookings";
import OrgAdminPortal from "./admin/orgAdminPortal";
import OrganisationBookings from "./admin/pods/bookings/files/organisationBookings";

function PortalHome() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const titleHeight = useRef(null);
    const organisation = useSelector((state) => state.organisation);
    const userProfile = useSelector((state) => state.userProfile);
    const user = useSelector((state) => state.user);
    const globalVars = useSelector((state) => state.globalVars);

    const [itemsMenu, setItemsMenu] = useState([]);

    useEffect(async () => {
        findYourItems();

        //GET START OF THE WEEK
        const date = moment().startOf("week");
        dispatch(GlobalVarsActions.UpdateTopControls_weekBG(date));

        const herfData = window.location.pathname.split("/");

        if (herfData[3] == "room") {
            const daySlots = findDaySlots(herfData[4]);
            const data = await GetRoomBookings(params.orgID, herfData[4], date, daySlots);
            dispatch(BookingDataActions.UpdateBookingData(data.data, data.orgID, data.roomID, data.weekSlot, data.days, data.weekBG));
        }
    }, []);

    function findYourItems() {
        const items = [{ name: "Your Bookings", followOnID: "yourBookings" }];

        if (userProfile.userSettings.administrator.orgAdminAccess) {
            items.push({ name: "Organisation Administrator", followOnID: "orgAdmin" });
        }

        if (userProfile.userSettings.bookings.viewAllOrgBookings) {
            items.push({ name: "Organisation Bookings", followOnID: "orgBookings" });
        }

        items.push({ name: "Log Out", followOnID: "logout" });

        setItemsMenu(items);
    }

    async function handleRoomSelection(roomID) {
        const daySlots = findDaySlots(roomID);
        const data = await GetRoomBookings(params.orgID, roomID, globalVars.topControls.weekBG, daySlots);
        dispatch(BookingDataActions.UpdateBookingData(data.data, data.orgID, data.roomID, data.weekSlot, data.days, data.weekBG));
        navigate(`./room/${roomID}`);
    }

    function findDaySlots(roomID) {
        const room = organisation.rooms.find((room) => room.uuid == roomID);
        const layout = organisation.layouts.find((layout) => layout.uuid == room.layout);
        const daySlots = [];
        const days = layout.days.split(",");

        if (layout.layout == "Diary") {
            const ti = [];
            const start = moment(layout.startTime, "HH:mm");
            const finish = moment(layout.finishTime, "HH:mm");

            while (start.isBefore(finish)) {
                ti.push(start.format("HH:mm"));
                start.add(layout.timeInterval, "m");
            }

            for (const [index, day] of days.entries()) {
                if (day == "true") {
                    for (const time of ti) {
                        daySlots.push(`${formatString(index)}-${time.replace(":", "")}`);
                    }
                }
            }
        }
        if (layout.layout == "Timetable") {
            const order = layout.sessionOrder.split(",");
            console.log(order);

            for (const [index, day] of days.entries()) {
                if (day == "true") {
                    for (const session of order) {
                        daySlots.push(`${formatString(index)}-${formatString(session)}`);
                    }
                }
            }
        }

        return daySlots;
    }

    function handleItemSelection(followOnID) {
        if (followOnID == "logout") {
            Axios.post("/userLogin/logout")
                .then((res) => {
                    console.log(res.data.message);
                    if (res.data.message === "User logged out") {
                        let page;
                        if (user.role == "superAdmin") {
                            page = "/";
                        } else {
                            const orgID = user.orgID;
                            page = "/org/" + orgID + "/security/signIn";
                        }
                        dispatch(UserActions.userUpdateAuth(false));
                        dispatch(UserActions.userUpdateName(""));
                        dispatch(UserActions.userUpdateRole(""));
                        dispatch(UserActions.userUpdateNew(""));
                        dispatch(UserActions.userUpdateRequestedPassword(false));
                        dispatch(UserActions.userUpdateUserDepartments([]));
                        dispatch(UserActions.userUpdateOrgID(""));
                        dispatch(BookingsActions.UpdateBookingDetails(false));
                        dispatch(RoomDetailsActions.UpdateShowSwitch(false));
                        dispatch(UserProfileActions.ResetState());
                        navigate(page);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            dispatch(GlobalVarsActions.UpdateMenuTransition("all 0.2s ease-out"));
            navigate(`./${followOnID}`);
        }
    }

    const rooms = [
        { name: "1" },
        { name: "2" },
        { name: "3" },
        { name: "4" },
        { name: "5" },
        { name: "6" },
        { name: "7" },
        { name: "8" },
        { name: "9" },
        { name: "10" },
        { name: "11" },
        { name: "12" },
        { name: "13" }
    ];

    return (
        <div style={{ height: `${globalVars.mainBodyHeight}px` }}>
            <Row style={{ height: `${globalVars.mainBodyHeight}px`, margin: "0" }}>
                <Col sm={2}>
                    {globalVars.mainBodyHeight == 0 ? (
                        <div>
                            <strong>Loading...</strong>
                        </div>
                    ) : (
                        <div>
                            <Row style={{ borderRight: "1px solid black", height: `${globalVars.body10thHeight * 6}px` }}>
                                <Col style={{ height: "100%" }}>
                                    <Row style={{ height: `${globalVars.body10thHeight / 1.5}px` }}>
                                        <Col style={{ fontWeight: "bold" }}>Your Rooms:</Col>
                                    </Row>
                                    <Row style={{ height: `${globalVars.body10thHeight * 5}px` }}>
                                        <Col style={{ height: "100%", overflowY: "scroll" }}>
                                            {organisation.rooms.map((room, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        style={{ marginTop: "5px" }}
                                                        className={
                                                            globalVars.mainMenu.menu == "rooms" && globalVars.mainMenu.followOnID == room.uuid
                                                                ? "mainMenu_buttonSelected"
                                                                : "mainMenu_button"
                                                        }
                                                        onClick={handleRoomSelection.bind(this, room.uuid)}
                                                    >
                                                        {room.name}
                                                    </div>
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    borderTop: "1px solid black",
                                    borderRight: "1px solid black",
                                    height: `${globalVars.body10thHeight * 4}px`
                                }}
                            >
                                <Col style={{ height: "100%" }}>
                                    <Row style={{ height: `${globalVars.body10thHeight / 1.5}px` }}>
                                        <Col style={{ fontWeight: "bold" }}>Your Items:</Col>
                                    </Row>
                                    <Row style={{ height: `${globalVars.body10thHeight * 3}px` }}>
                                        <Col style={{ height: "100%", overflowY: "scroll" }}>
                                            {itemsMenu.map((item, index) => {
                                                return (
                                                    <MenuButton
                                                        key={index}
                                                        title={item.name}
                                                        menu={"items"}
                                                        followOnID={item.followOnID}
                                                        click={handleItemSelection}
                                                    />
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>
                <Col sm={10} style={{ padding: "0", height: `${globalVars.mainBodyHeight}px` }}>
                    <Routes>
                        <Route path="/room/:id" element={<RoomView />} />
                        <Route path="/yourBookings" element={<YourBookings />} />
                        <Route path="/orgBookings" element={<OrganisationBookings />} />
                        <Route path="/orgAdmin/*" element={<OrgAdminPortal debug={"10px"} />} />
                    </Routes>
                </Col>
            </Row>
        </div>
    );
}

function formatString(time) {
    if (time.toString().includes("b")) {
        time = time.replace("b", "");

        if (time.toString().length == 1) {
            return "b0" + time;
        } else {
            return time;
        }
    } else {
        if (time.toString().length == 1) {
            return "0" + time;
        } else {
            return time;
        }
    }
}

export function GetRoomBookings(orgID, roomID, weekBG, days) {
    return new Promise((resolve, reject) => {
        const weekSlot = formatString(weekBG.week()) + "-" + weekBG.format("YY");

        const mySQLData = {
            orgID: orgID,
            room: roomID,
            week: weekSlot,
            days: days,
            weekBG: weekBG.format("DD/MM/YYYY")
        };
        Axios.post("/bookings/getBookings", mySQLData)
            .then((res) => {
                resolve({
                    data: res.data.bookings,
                    orgID: orgID,
                    roomID: roomID,
                    weekSlot: weekSlot,
                    days: days,
                    weekBG: weekBG
                });
            })
            .catch((err) => {
                console.log(err);
            });
    });
}

export default PortalHome;

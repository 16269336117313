import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Card, Spinner } from "react-bootstrap";
import Axios from "axios";
import Switch from "react-switch";

//STYLES
import * as ServicesStyles from "../../styles/services";

function Services() {
    const [deleteDateBookings, setDeleteDateBookings] = useState({
        open: false,
        organisations: []
    });

    const [isChanging, setIsChanging] = useState(false);
    const [redirectTestSite, setRedirectTestSite] = useState(false);

    useEffect(() => {
        Axios.post("/siteAdministration/services/loadData")
            .then((res) => {
                const data = res.data;
                console.log(data);
                setRedirectTestSite(data.redirectTestSite == "true");
            })
            .catch((err) => console.log(err));
    }, []);

    function handleDeleteDateClose() {
        setDeleteDateBookings((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleDeleteDateBookings() {
        Axios.post("/siteAdministration/services/getOrganisations")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setDeleteDateBookings((prevState) => {
                        return { ...prevState, organisations: data.organisations, open: true };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleDeleteDateProcess(orgID) {
        setIsChanging(true);
        const data = { orgID: orgID };
        Axios.post("/siteAdministration/services/changeDeleteDate", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setIsChanging(false);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleRedirectTestSiteOnChange(checked) {
        console.log("change");
        const data = { checked: checked.toString() };
        Axios.post("/siteAdministration/services/redirectTestSiteChange", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setRedirectTestSite(checked);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
            <br />
            <Row>
                <Col style={{ border: "solid 2px", marginRight: "5px" }}>
                    <div style={ServicesStyles.container}>
                        One-Off Services <br /> <br />
                        <div>
                            <Button onClick={handleDeleteDateBookings}>Run Delete Date Bookings</Button>
                        </div>
                        <br />
                    </div>
                </Col>
                <Col style={{ border: "solid 2px", marginLeft: "5px" }}>
                    <div style={ServicesStyles.container}>
                        Maintenance <br /> <br />
                        <div style={ServicesStyles.redirectTestSite}>
                            Redirect to Test Site:
                            <Switch
                                className="customCSS-Content-New-Item-Switch"
                                onChange={handleRedirectTestSiteOnChange}
                                checked={redirectTestSite}
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                        </div>
                        <br />
                    </div>
                </Col>
            </Row>
            <Modal show={deleteDateBookings.open} onHide={handleDeleteDateClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Date Bookings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isChanging ? (
                        <div style={ServicesStyles.spinner}>
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <div>
                            {deleteDateBookings.organisations.map((org, index) => {
                                return (
                                    <div key={index}>
                                        <Card style={ServicesStyles.cardStyle} onClick={handleDeleteDateProcess.bind(this, org.orgID)}>
                                            <Card.Body>
                                                <Row>
                                                    <Col>{org.name}</Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDeleteDateClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Services;

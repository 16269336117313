import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";

//COMPONENTS
import OrganisationList from "./pods/organisationList";

//STYLES
import * as AdminStyles from "../styles/admin";

function Home() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [loaded, setLoaded] = useState(false);
    console.log(user);

    useEffect(() => {
        document.title = "STAFF - Administrator";
        if (!user.auth || user.role != "superAdmin") {
            console.log("here");
            navigate("/administrator/signIn", { replace: true });
        } else {
            setLoaded(true);
        }
    }, []);

    return (
        <div style={AdminStyles.body}>
            {loaded && (
                <div>
                    <Container fluid className="p-3">
                        <OrganisationList />
                    </Container>
                </div>
            )}
        </div>
    );
}

export default Home;

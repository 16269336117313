export const body = {
    width: "100%",
    display: "flex",
    justifyContent: "center"
    // border: "1px solid black"
};

export const formBody = {
    width: "50%"
    // border: "1px solid black"
};

export const contact = {
    fontSize: "1.3rem",
    textAlign: "left"
};

export const contactSent = {
    fontSize: "1.3rem",
    textAlign: "center"
};

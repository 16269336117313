export const body = {
    paddingTop: "30px",
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%"
};

export const helpSection = {
    width: "20%",
    float: "left",
    textAlign: "center",
    paddingRight: "10px"
};

export const controlsSection = {
    width: "40%",
    float: "left"
};

export const weeksSection = {
    width: "40%",
    float: "left",
    height: "100%"
};

export const numWeeksSubSection_title = {
    width: "20%"
};

export const numWeeksSubSection_btnsShow = {
    width: "80%",
    visibility: "visible"
};

export const numWeeksSubSection_btnsHidden = {
    width: "80%",
    visibility: "hidden"
};

export const weeksList = {
    height: "150px",
    border: "1px solid black",
    overflow: "scroll"
};

export const dateContainer = {
    display: "flex",
    alignItems: "center",
    columnGap: "10px"
};

export const sectionTitle = {
    fontWeight: "bold",
    textAlign: "center"
};

export const weeksNumContainer = {
    display: "flex",
    alignItems: "center",
    columnGap: "10px"
};

export const yearPreviewTitle = {
    height: "6%",
    marginLeft: "10px"
};

export const yearPreviewWeeksList = {
    height: "80%",
    marginLeft: "10px",
    overflow: "scroll",
    border: "1px solid black"
};

export const yearPreviewSaveSection = {
    height: "10%",
    display: "flex",
    justifyContent: "right",
    alignItems: "center"
};

export const yearTitlefirstHalf = {
    width: "50%",
    float: "left",
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontWeight: "bold"
};

export const yearTitleSecondHalf = {
    width: "50%",
    float: "left",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "right"
};

export const hidden = {
    visibility: "hidden"
};

export const show = {
    visibility: "visible"
};

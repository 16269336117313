import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

//COMPONENTS
import SignIn from "./signIn";
import ForgotPassword from "./forgotPassword";
import ChangePassword from "./changePassword";
import CreatePassword from "./createPassword";
import WrongOrganisation from "./wrongOrganisation";

//STYLES
import * as SecurityStyles from "../styles/security";

function Security(props) {
    const organisation = useSelector((state) => state.organisation);

    return (
        <div style={SecurityStyles.body}>
            <Row style={{ height: "100%" }}>
                <Col sm={7} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    <h1>{organisation.name}</h1> <br />
                    <h2>Welcome to your Booking Portal</h2>
                    <br />
                    <h3>Please Sign In!</h3>
                    <p>{organisation.message}</p>
                </Col>
                <Col sm={5} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div style={SecurityStyles.innerContainer}>
                        <Routes>
                            <Route path="/signIn" element={<SignIn />} />
                            <Route path="/forgotPassword" element={<ForgotPassword />} />
                            <Route path="/changePassword" element={<ChangePassword />} />
                            <Route path="/createPassword" element={<CreatePassword />} />
                            <Route path="/wrongOrganisation" element={<WrongOrganisation />} />
                        </Routes>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Security;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";
import * as OrganisationActions from "../../../../../../../store/actions/organistion";

//STYLES
import * as AdminLoginSettingsStyles from "../styles/loginSettings";

function AdminLoginSettings() {
    const dispatch = useDispatch();
    const params = useParams();

    const organisation = useSelector((state) => state.organisation);

    const [settings, setSettings] = useState({
        localLogin: false,
        googleLogin: false,
        message: "",
        counterMax: 50
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateAdminMenuID("loginSettings"));

        onLoad();
    }, []);

    function onLoad() {
        setSettings((prevState) => {
            return { ...prevState, localLogin: organisation.signInLocal, googleLogin: organisation.signInGoogle, message: organisation.message };
        });
    }

    function handleCheckChanged(event) {
        const { name, checked } = event.target;

        setSettings((prevState) => {
            return { ...prevState, [name]: checked };
        });
    }

    function handleTextChange(event) {
        const { value } = event.target;

        setSettings((prevState) => {
            return { ...prevState, message: value };
        });
    }

    function handleSave() {
        if (!settings.localLogin && !settings.googleLogin) {
            setModal({
                heading: "Login Settings",
                message: "At least one login method needs to be enabled",
                open: true
            });
        } else {
            const data = {
                orgID: params.orgID,
                auth_local: settings.localLogin.toString(),
                auth_google: settings.googleLogin.toString(),
                message: settings.message
            };

            Axios.post("/orgAdmin/loginSettings/update", data)
                .then((res) => {
                    if (res.data.message == "Login settings updated successfully") {
                        dispatch(OrganisationActions.orgUpdateSignInLocal(settings.localLogin));
                        dispatch(OrganisationActions.orgUpdateSignInGoogle(settings.googleLogin));
                        dispatch(OrganisationActions.orgUpdateMessage(settings.message));

                        setModal({
                            heading: "Login Settings",
                            message: "Login settings updated",
                            open: true
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <div style={AdminLoginSettingsStyles.body}>
            Login Options:
            <br />
            <br />
            <Form.Check name="localLogin" label="Local Login" checked={settings.localLogin} onChange={handleCheckChanged} />
            <Form.Check name="googleLogin" label="Google Login" checked={settings.googleLogin} onChange={handleCheckChanged} />
            <br />
            Login Message
            <br />
            <br />
            <Form.Control type="text" maxLength={settings.counterMax} value={settings.message} onChange={handleTextChange} />
            <div style={AdminLoginSettingsStyles.counter}>{settings.counterMax - settings.message.length}</div>
            <br />
            <div style={AdminLoginSettingsStyles.saveButton}>
                <Button onClick={handleSave}>Save</Button>
            </div>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AdminLoginSettings;

export const container = {
    fontSize: "1.5rem",
    padding: "5px"
};

export const cardStyle = {
    backgroundColor: "#1e56a0",
    color: "white",
    cursor: "pointer"
};

export const spinner = {
    textAlign: "center"
};

export const redirectTestSite = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "5px"
};

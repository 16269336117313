import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button, Modal, Form, ListGroup, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import ReactTooltip from "react-tooltip";

//SETTINGS
import * as AdminLayoutsSettings from "../../../../../../../globalSettings/adminLayoutsSettings";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";

//STYLES
import * as AdminLayoutsStyles from "../styles/layoutSettings";

//COMPONETS
import BreakSlot from "./breakSlot";
import SessionSlot from "./sessionSlot";

function AdminLayouts() {
    const dispatch = useDispatch();
    const params = useParams();
    const AdminLayoutsGlobalSettings = useSelector((state) => state.AdminLayoutsGlobalSettings);

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const times = [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30"
    ];

    const [settings, setSettings] = useState({
        selectedLayout: "Select",
        selectedTemplate: "template",
        layouts: [],
        sessions: 0,
        breaks: 0,
        layoutData: {},
        days: [false, false, false, false, false, false, false],
        startTime: "time",
        finishTime: "time",
        timeIntervalLbl: "30 Minutes",
        timeInterval: 30,
        times: []
    });

    const [addModal, setAddModal] = useState({
        open: false,
        heading: "",
        newName: ""
    });

    function handleAddModalClose() {
        setAddModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateAdminMenuID("layouts"));

        onLoad();
    }, []);

    function onLoad() {
        const data = { orgID: params.orgID };
        Axios.post("/orgAdmin/layouts/getLayouts", data)
            .then((res) => {
                setSettings((prevState) => {
                    return { ...prevState, layouts: res.data.layouts };
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handlePickLayout(uuid) {
        let currentLayout = {};
        AdminLayoutsSettings.resetSessions(dispatch);

        setSettings((prevState) => {
            return { ...prevState, sessions: 0, breaks: 0 };
        });

        for (const layout of settings.layouts) {
            if (layout.uuid == uuid) {
                currentLayout = layout;
                break;
            }
        }

        let layout = "template";
        if (currentLayout.layout != "") {
            layout = currentLayout.layout;
        }

        setSettings((prevState) => {
            return { ...prevState, selectedLayout: currentLayout.name, selectedTemplate: layout, layoutData: currentLayout };
        });

        loadSettings(currentLayout);
    }

    function loadSettings(layoutData) {
        if (layoutData.layout == "Timetable") {
            if (layoutData.days != "") {
                let days = layoutData.days.split(",");
                days.forEach((day, index) => {
                    days[index] = day == "true";
                });

                const data = { orgID: params.orgID, layoutUUID: layoutData.uuid };
                Axios.post("/orgAdmin/layouts/getTimetableSessions", data)
                    .then((res) => {
                        const sessions = res.data.sessions;
                        let newSessions = {};
                        for (const session of sessions) {
                            newSessions[session.id] = session;
                        }

                        AdminLayoutsSettings.SetSessions(dispatch, newSessions);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                const order = layoutData.sessionOrder.split(",");
                AdminLayoutsSettings.SetOrder(dispatch, order);
                console.log(layoutData.breaks);
                setSettings((prevState) => {
                    return {
                        ...prevState,
                        open: true,
                        sessions: layoutData.sessions,
                        breaks: layoutData.breaks,
                        days: days
                    };
                });
            }
        } else if (layoutData.layout == "Diary") {
            let days = layoutData.days.split(",");
            days.forEach((day, index) => {
                days[index] = day == "true";
            });

            if (layoutData.days != "") {
                setSettings((prevState) => {
                    return {
                        ...prevState,
                        startTime: layoutData.startTime,
                        finishTime: layoutData.finishTime,
                        timeInterval: layoutData.timeInterval,
                        days: days
                        // showDiarySave: true,
                        // showPreview: true
                    };
                });
                findIntervalText(layoutData.timeInterval);
                BuildTimes(layoutData.startTime, layoutData.finishTime, layoutData.timeInterval);
            }
        }
    }

    function findIntervalText(interval) {
        let lbl = "";
        if (interval == 30) lbl = "30 Minutes";
        if (interval == 60) lbl = "1 hour";
        if (interval == 90) lbl = "1 hour, 30 minutes";
        if (interval == 120) lbl = "2 hours";

        setSettings((prevState) => {
            return { ...prevState, timeIntervalLbl: lbl };
        });
    }

    function BuildTimes(sTime, fTime, interval) {
        const ti = [];
        if (sTime !== "Start Time" && fTime !== "Finish Time" && interval !== 0) {
            const start = moment(sTime, "HH:mm");
            const finish = moment(fTime, "HH:mm");

            while (start.isBefore(finish)) {
                ti.push(start.format("HH:mm"));
                start.add(interval, "m");
            }

            setSettings((prevState) => {
                return { ...prevState, times: ti };
            });

            // let show = false;

            // for (const day in settings.days) {
            //     if (settings.days[day] == true) {
            //         show = true;
            //     }
            // }

            // setSettings((prevState) => {
            //     return { ...prevState, showDiarySave: show };
            // });
        }
    }

    function handleReloadTooltip() {
        ReactTooltip.rebuild();
    }

    function handleChangeTemplate(event) {
        const { innerText } = event.target;
        console.log("change template");
        let days = [false, false, false, false, false, false, false];

        let showTimetableSave = false;
        let showDiarySave = false;

        if (settings.layoutData.layout == innerText) {
            loadSettings(settings.layoutData);
        } else {
            if (innerText == "Timetable") {
                setSettings((prevState) => {
                    return { ...prevState, sessions: 1 };
                });
            } else if (innerText == "Diary") {
                null;
            }
        }

        let show = false;
        for (const day of days) {
            if (day == true) {
                show = true;
            }
        }

        setSettings((preState) => {
            return {
                ...preState,
                selectedTemplate: innerText,
                days: days,
                showDiarySave: showDiarySave,
                showTimetableSave: showTimetableSave,
                showPreview: show
            };
        });
    }

    //ADD LAYOUT METHODS
    function handleAddLayout() {
        setAddModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleAddModalTextChange(event) {
        const { value } = event.target;

        setAddModal((prevState) => {
            return { ...prevState, newName: value };
        });
    }

    function handleAddModalSubmit() {
        const data = { orgID: params.orgID, name: addModal.newName };

        Axios.post("/orgAdmin//layouts/addLayout", data)
            .then((res) => {
                if (res.data.message == "Successfully Added") {
                    setSettings((prevState) => {
                        return { ...prevState, layouts: res.data.layouts };
                    });

                    setAddModal((prevState) => {
                        return { ...prevState, newName: "", open: false };
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    //ADD LAYOUT METHODS^^^

    //DAYS CHECKBOXES
    function handleChecked(event) {
        const { name, checked } = event.target;

        const temp = settings.days;

        temp[name] = checked;

        let show = false;

        for (const day in temp) {
            if (temp[day] == true) {
                show = true;
            }
        }

        setSettings((prevState) => {
            return { ...prevState, days: temp, showPreview: show, showTimetableSave: show };
        });

        if (settings.layoutText == "Diary" && settings.startTime !== "time" && settings.finishTime !== "time" && settings.timeInterval !== 30) {
            setSettings((prevState) => {
                return { ...prevState, days: temp, showPreview: show, showDiarySave: show };
            });
        }
    }

    //DIARY DROPDOWNS
    function handleChangeStartTime(event) {
        const { innerText } = event.target;

        setSettings((preState) => {
            return { ...preState, startTime: innerText };
        });

        BuildTimes(innerText, settings.finishTime, settings.timeInterval);
    }

    function handleChangeFinishTime(event) {
        const { innerText } = event.target;

        setSettings((preState) => {
            return { ...preState, finishTime: innerText };
        });

        BuildTimes(settings.startTime, innerText, settings.timeInterval);
    }

    function handleChangeInterval(event) {
        const { innerText, name } = event.target;

        setSettings((preState) => {
            return { ...preState, timeIntervalLbl: innerText, timeInterval: parseInt(name) };
        });

        BuildTimes(settings.startTime, settings.finishTime, parseInt(name));
    }
    //DIARY DROPDOWNS^^^

    //SESSIONS BUTTONS
    function handlePlusSessions() {
        setSettings((prevState) => {
            return { ...prevState, sessions: settings.sessions + 1 };
        });

        AdminLayoutsSettings.addSession(AdminLayoutsGlobalSettings, dispatch, settings.sessions + 1);
    }

    function handleMinusSessions() {
        if (settings.sessions > 1) {
            setSettings((prevState) => {
                return { ...prevState, sessions: settings.sessions - 1 };
            });

            AdminLayoutsSettings.minusSession(AdminLayoutsGlobalSettings, dispatch, settings.sessions);
        }
    }
    //SESSIONS BUTTONS^^^

    //BREAK BUTTONS
    function handleAddBreak() {
        setSettings((prevState) => {
            return { ...prevState, breaks: settings.breaks + 1 };
        });

        AdminLayoutsSettings.addBreak(AdminLayoutsGlobalSettings, dispatch, settings.breaks + 1);
    }

    function handleMoveBreakUp() {
        AdminLayoutsSettings.orderMoveUp(AdminLayoutsGlobalSettings, dispatch);
    }

    function handleMoveBreakDown() {
        AdminLayoutsSettings.orderMoveDown(AdminLayoutsGlobalSettings, dispatch);
    }

    function handleRemoveBreak() {
        setSettings((prevState) => {
            return { ...prevState, breaks: settings.breaks - 1 };
        });
        AdminLayoutsSettings.removeBreak(AdminLayoutsGlobalSettings, dispatch);
    }
    //BREAK BUTTONS^^^

    function handleOnSave() {
        if (settings.selectedTemplate == "Timetable") {
            console.log("saving timetable");
            timetableSave();
        }
        if (settings.selectedTemplate == "Diary") {
            diarySave();
        }
    }

    function timetableSave() {
        const data = {
            orgID: params.orgID,
            uuid: settings.layoutData.uuid,
            layout: settings.selectedTemplate,
            sessionTotal: settings.sessions,
            breakTotal: settings.breaks,
            sessionOrder: AdminLayoutsGlobalSettings.order,
            days: settings.days.toString(),
            sessions: AdminLayoutsGlobalSettings.sessions
        };
        console.log(data);
        Axios.post("/orgAdmin/layouts/saveLayout", data)
            .then((res) => {
                if (res.data.message == "Success") {
                    console.log(res.data);
                    setSettings((prevState) => {
                        return { ...prevState, layouts: res.data.layouts };
                    });

                    setModal((prevState) => {
                        return { ...prevState, heading: "Layout: " + settings.selectedLayout, message: "Layout has been saved!", open: true };
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function diarySave() {
        const data = {
            orgID: params.orgID,
            uuid: settings.layoutData.uuid,
            layout: settings.selectedTemplate,
            days: settings.days.toString(),
            startTime: settings.startTime,
            finishTime: settings.finishTime,
            timeInterval: settings.timeInterval
        };

        Axios.post("/orgAdmin/layouts/saveLayout", data)
            .then((res) => {
                if (res.data.message == "Success") {
                    setSettings((prevState) => {
                        return { ...prevState, layouts: res.data.layouts };
                    });

                    setModal((prevState) => {
                        return { ...prevState, heading: "Layout: " + settings.selectedLayout, message: "Layout has been saved!", open: true };
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div style={AdminLayoutsStyles.body}>
            <div style={AdminLayoutsStyles.topSelect}>
                <div style={AdminLayoutsStyles.dropdownTitle}>
                    Your Layouts:
                    <Dropdown>
                        <Dropdown.Toggle>{settings.selectedLayout}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {settings.layouts.map((layout, index) => {
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() => {
                                            handlePickLayout(layout.uuid);
                                        }}
                                    >
                                        {layout.name}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant="info" onClick={handleAddLayout}>
                        Add New Layout
                    </Button>
                </div>
            </div>
            <div style={AdminLayoutsStyles.controlsContainer}>
                <div style={AdminLayoutsStyles.controlsHalf}>
                    {Object.keys(settings.layoutData).length != 0 && (
                        <div>
                            <div style={AdminLayoutsStyles.templateControlsContainer}>
                                Template:
                                <Dropdown>
                                    <Dropdown.Toggle>{settings.selectedTemplate}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={handleChangeTemplate}>Timetable</Dropdown.Item>
                                        <Dropdown.Item onClick={handleChangeTemplate}>Diary</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <br />
                            {settings.selectedTemplate != "template" && (
                                <div>
                                    What days are available:
                                    <div>
                                        <Form>
                                            <Form.Group style={AdminLayoutsStyles.daysContainer}>
                                                <Form.Check
                                                    name="0"
                                                    onChange={handleChecked}
                                                    type="checkbox"
                                                    checked={settings.days[0]}
                                                    label="Sun"
                                                />
                                                <Form.Check
                                                    name="1"
                                                    onChange={handleChecked}
                                                    type="checkbox"
                                                    checked={settings.days[1]}
                                                    label="Mon"
                                                />
                                                <Form.Check
                                                    name="2"
                                                    onChange={handleChecked}
                                                    type="checkbox"
                                                    checked={settings.days[2]}
                                                    label="Tue"
                                                />
                                                <Form.Check
                                                    name="3"
                                                    onChange={handleChecked}
                                                    type="checkbox"
                                                    checked={settings.days[3]}
                                                    label="Wed"
                                                />
                                                <Form.Check
                                                    name="4"
                                                    onChange={handleChecked}
                                                    type="checkbox"
                                                    checked={settings.days[4]}
                                                    label="Thu"
                                                />
                                                <Form.Check
                                                    name="5"
                                                    onChange={handleChecked}
                                                    type="checkbox"
                                                    checked={settings.days[5]}
                                                    label="Fri"
                                                />
                                                <Form.Check
                                                    name="6"
                                                    onChange={handleChecked}
                                                    type="checkbox"
                                                    checked={settings.days[6]}
                                                    label="Sat"
                                                />
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                            )}
                            <br />
                            {settings.selectedTemplate == "Timetable" && (
                                <div>
                                    <div style={AdminLayoutsStyles.daysContainer}>
                                        Sessions: {settings.sessions}
                                        <Button onClick={handlePlusSessions}>+</Button>
                                        <Button onClick={handleMinusSessions}>-</Button>
                                    </div>
                                    <br />
                                    Breaks:
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Button onClick={handleAddBreak}>Add</Button>
                                                </td>
                                                <td>
                                                    {AdminLayoutsGlobalSettings.breakBtns.view && <Button onClick={handleRemoveBreak}>Remove</Button>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {AdminLayoutsGlobalSettings.breakBtns.view && (
                                                        <Button onClick={handleMoveBreakUp}>Move Up</Button>
                                                    )}
                                                </td>
                                                <td>
                                                    {AdminLayoutsGlobalSettings.breakBtns.view && (
                                                        <Button onClick={handleMoveBreakDown}>Move Down</Button>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {settings.selectedTemplate == "Diary" && (
                                <div>
                                    <table>
                                        <tr>
                                            <td style={AdminLayoutsStyles.tableFont}>Start Time:</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle>{settings.startTime}</Dropdown.Toggle>
                                                    <Dropdown.Menu style={AdminLayoutsStyles.dropdownItems}>
                                                        {times.map((time, index) => {
                                                            return (
                                                                <Dropdown.Item key={index} onClick={handleChangeStartTime}>
                                                                    {time}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={AdminLayoutsStyles.tableFont}>End Time:</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle>{settings.finishTime}</Dropdown.Toggle>
                                                    <Dropdown.Menu style={AdminLayoutsStyles.dropdownItems}>
                                                        {times.map((time, index) => {
                                                            return (
                                                                <Dropdown.Item key={index} onClick={handleChangeFinishTime}>
                                                                    {time}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={AdminLayoutsStyles.tableFont}>Interval:</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle>{settings.timeIntervalLbl}</Dropdown.Toggle>
                                                    <Dropdown.Menu style={AdminLayoutsStyles.dropdownItems}>
                                                        <Dropdown.Item name="30" onClick={handleChangeInterval}>
                                                            30 minutes
                                                        </Dropdown.Item>
                                                        <Dropdown.Item name="60" onClick={handleChangeInterval}>
                                                            1 hour
                                                        </Dropdown.Item>
                                                        <Dropdown.Item name="90" onClick={handleChangeInterval}>
                                                            1 hour, 30 minutes
                                                        </Dropdown.Item>
                                                        <Dropdown.Item name="120" onClick={handleChangeInterval}>
                                                            2 hours
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div style={AdminLayoutsStyles.controlsHalf}>
                    <div style={AdminLayoutsStyles.controlsTopRight}>
                        {settings.selectedTemplate == "Timetable" && (
                            <div style={{ height: "100%" }}>
                                <div style={{ height: "10%" }}>
                                    <strong>Sessions</strong>
                                </div>

                                <div style={AdminLayoutsStyles.listViewContainer}>
                                    {Object.keys(AdminLayoutsGlobalSettings.sessions).length > 0 && (
                                        <div>
                                            <Row>
                                                <Col sm={1}></Col>
                                                <Col style={AdminLayoutsStyles.sessionsHeadings}>Custom Text</Col>
                                                <Col style={AdminLayoutsStyles.sessionsHeadings}>Hover Text</Col>
                                            </Row>
                                            <ListGroup>
                                                {AdminLayoutsGlobalSettings.order.map((session, index) => {
                                                    if (session.toString().includes("b")) {
                                                        return (
                                                            <ListGroup.Item key={index}>
                                                                {" "}
                                                                <BreakSlot
                                                                    key={session}
                                                                    id={AdminLayoutsGlobalSettings.sessions[session].id}
                                                                    breakText={AdminLayoutsGlobalSettings.sessions[session].breakText}
                                                                    bgColor={AdminLayoutsGlobalSettings.sessions[session].bgColor}
                                                                    textColor={AdminLayoutsGlobalSettings.sessions[session].textColor}
                                                                />
                                                            </ListGroup.Item>
                                                        );
                                                    } else {
                                                        return (
                                                            <ListGroup.Item key={index}>
                                                                <SessionSlot
                                                                    key={session}
                                                                    id={AdminLayoutsGlobalSettings.sessions[session].id}
                                                                    customText={AdminLayoutsGlobalSettings.sessions[session].customText}
                                                                    hoverText={AdminLayoutsGlobalSettings.sessions[session].hoverText}
                                                                />
                                                            </ListGroup.Item>
                                                        );
                                                    }
                                                })}
                                            </ListGroup>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={AdminLayoutsStyles.controlsBottomRight}>
                        {settings.selectedTemplate != "template" && <Button onClick={handleOnSave}>Save</Button>}
                    </div>
                </div>
            </div>
            <div style={AdminLayoutsStyles.previewContiner}>
                {settings.selectedTemplate == "template" ? (
                    <div>
                        <strong>No preview to show</strong>
                    </div>
                ) : (
                    <div style={{ width: "80%" }}>
                        <table width="100%">
                            <thead>
                                <tr>
                                    <td style={AdminLayoutsStyles.border}>Session</td>
                                    {settings.days.map((day, index) => {
                                        if (day) {
                                            return (
                                                <td style={AdminLayoutsStyles.border} key={index} data-tip="day">
                                                    {days[index]}
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            </thead>
                            {settings.selectedTemplate == "Timetable" && Object.keys(AdminLayoutsGlobalSettings.sessions).length > 0 ? (
                                <tbody>
                                    {AdminLayoutsGlobalSettings.order.map((session, index) => {
                                        if (session.toString().includes("b")) {
                                            return (
                                                <tr
                                                    key={index}
                                                    style={{
                                                        backgroundColor: AdminLayoutsGlobalSettings.sessions[session].bgColor
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            ...AdminLayoutsStyles.border,
                                                            color: AdminLayoutsGlobalSettings.sessions[session].textColor
                                                        }}
                                                    >
                                                        {AdminLayoutsGlobalSettings.sessions[session].breakText != ""
                                                            ? AdminLayoutsGlobalSettings.sessions[session].breakText
                                                            : AdminLayoutsGlobalSettings.sessions[session].id}
                                                    </td>
                                                    {settings.days.map((day, index) => {
                                                        if (day) {
                                                            return <td style={AdminLayoutsStyles.border} key={index}></td>;
                                                        }
                                                    })}
                                                </tr>
                                            );
                                        } else {
                                            return (
                                                <tr key={index}>
                                                    <td
                                                        style={AdminLayoutsStyles.border}
                                                        data-tip={
                                                            AdminLayoutsGlobalSettings.sessions[session].hoverText != ""
                                                                ? AdminLayoutsGlobalSettings.sessions[session].hoverText
                                                                : null
                                                        }
                                                    >
                                                        {AdminLayoutsGlobalSettings.sessions[session].customText != ""
                                                            ? AdminLayoutsGlobalSettings.sessions[session].customText
                                                            : AdminLayoutsGlobalSettings.sessions[session].id}
                                                    </td>
                                                    {settings.days.map((day, index) => {
                                                        if (day) {
                                                            return <td style={AdminLayoutsStyles.border} key={index}></td>;
                                                        }
                                                    })}
                                                </tr>
                                            );
                                        }
                                    })}
                                    {handleReloadTooltip()}
                                </tbody>
                            ) : null}
                            {settings.selectedTemplate == "Diary" && (
                                <tbody>
                                    {settings.times.map((time, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={AdminLayoutsStyles.border}>{time}</td>
                                                {settings.days.map((day, index) => {
                                                    if (day) {
                                                        return <td style={AdminLayoutsStyles.border} key={index}></td>;
                                                    }
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            )}
                        </table>
                    </div>
                )}
            </div>
            <Modal show={addModal.open} onHide={handleAddModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Layout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control value={addModal.newName} onChange={handleAddModalTextChange} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAddModalSubmit}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AdminLayouts;

import React from "react";
import { useSelector } from "react-redux";

function MenuButton(props) {
    const globalVars = useSelector((state) => state.globalVars);

    return (
        <div>
            <div
                className={
                    globalVars.mainMenu.menu == props.menu && globalVars.mainMenu.followOnID == props.followOnID
                        ? "mainMenu_buttonSelected"
                        : "mainMenu_button"
                }
                onClick={props.click.bind(this, props.followOnID)}
            >
                {props.title}
            </div>
        </div>
    );
}

export default MenuButton;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";

//STYLES
import * as YourBookingsStyles from "../styles/yourBookings";

//COMPONENTS
import BookingItem from "./bookingItem";

function YourBookings() {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const globalVars = useSelector((state) => state.globalVars);

    const [bookings, setBookings] = useState([]);
    const [order, setOrder] = useState("Up");

    const topContainer = useRef(null);

    const [filters, setFilters] = useState({
        user: { column: "user", filter: user.uuid, checked: true },
        createdBy: { column: "createdBy", filter: "no filter", checked: false }
    });

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateMenuID("items", "yourBookings"));
        onLoad(filters, order);
    }, []);

    function handleSortOrder() {
        let newOrder = "";
        if (order == "Up") {
            newOrder = "Down";
        } else {
            newOrder = "Up";
        }
        setOrder(newOrder);
        onLoad(filters, newOrder);
    }

    function onLoad(filters, order) {
        let continu = false;
        for (const [key, value] of Object.entries(filters)) {
            if (value.checked) {
                continu = true;
            }
        }
        if (continu) {
            const data = { filters: filters, orgID: user.orgID, operator: "OR", order };
            Axios.post("/bookingsFilters/getBookings", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setBookings(data.bookings);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setBookings([]);
        }
    }

    function handleFilterSelected(filterID) {
        const newFilters = { ...filters };
        if (newFilters[filterID].checked) {
            newFilters[filterID].filter = "no filter";
            newFilters[filterID].checked = false;
        } else {
            newFilters[filterID].filter = user.uuid;
            newFilters[filterID].checked = true;
        }

        setFilters(newFilters);
        onLoad(newFilters, order);
    }

    return (
        <div style={YourBookingsStyles.body}>
            <div ref={topContainer}>
                <Row>
                    <Col>
                        <h3>Your Bookings</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={YourBookingsStyles.filterSetting} onClick={handleFilterSelected.bind(this, "user")}>
                            {filters.user.checked ? <i className="fa-regular fa-square-check"></i> : <i className="fa-regular fa-square"></i>}{" "}
                            Assigned to me
                        </div>
                    </Col>
                    <Col>
                        <div style={YourBookingsStyles.filterSetting} onClick={handleFilterSelected.bind(this, "createdBy")}>
                            {filters.createdBy.checked ? <i className="fa-regular fa-square-check"></i> : <i className="fa-regular fa-square"></i>}{" "}
                            Created by me
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>Date</Col>
                                    <Col>Room</Col>
                                    <Col>Session</Col>
                                    <Col>Class</Col>
                                    <Col>Booking Type</Col>
                                    <Col style={YourBookingsStyles.sortBox} onClick={handleSortOrder}>
                                        {order == "Up" ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br />
            </div>
            {topContainer.current != null && (
                <Row>
                    <Col style={{ height: `${globalVars.mainBodyHeight - topContainer.current.clientHeight}px`, overflow: "scroll" }}>
                        {bookings.map((booking, index) => {
                            return <BookingItem key={index} admin={false} details={booking} />;
                        })}
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default YourBookings;

export const body = {
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%"
};

export const top = {
    height: "25%"
};

export const topChecks = {
    width: "50%",
    display: "flex",
    justifyContent: "space-around"
};

export const sortBox = {
    cursor: "pointer",
    padding: "auto"
};

export const content = {
    maxHeight: 0,
    overflow: "hidden",
    transition: "all 0.5s cubic-bezier(0,1,0,1)"
};

export const contentShow = {
    height: "auto",
    maxHeight: "9999px",
    overflow: "hidden",
    transition: "all 0.5s cubic-bezier(1,0,1,0)"
};

export const card = {
    cursor: "pointer"
};

export const filterSetting = {
    float: "left",
    fontSize: "1.5rem",
    cursor: "pointer"
};

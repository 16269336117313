import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, ListGroup, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";
import * as OrganisationActions from "../../../../../../../store/actions/organistion";

//STYLES
import * as DeparmentSettingsStyles from "../styles/departmentSettings";

function DeparmentSettings() {
    const dispatch = useDispatch();
    const params = useParams();

    const organisation = useSelector((state) => state.organisation);

    const [settings, setSettings] = useState({
        useDepartments: false,
        departments: []
    });

    const modalDefaultValues = {
        open: false,
        name: "",
        counterMax: 50,
        triedSubmit: false,
        errorMessage: "",
        edit: false,
        editUUID: 0
    };

    const [modal, setModal] = useState(modalDefaultValues);

    function handleModalClose() {
        setModal(modalDefaultValues);
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateAdminMenuID("departments"));
        console.log("loading");
        onLoad();
    }, []);

    function onLoad() {
        setSettings((prevState) => {
            return { ...prevState, departments: organisation.departments, useDepartments: organisation.useDepartments };
        });
    }

    function handleUseDepartmentsChecked(event) {
        const { checked } = event.target;

        setSettings((prevState) => {
            return { ...prevState, useDepartments: checked };
        });

        const data = { useDepartments: checked.toString(), orgID: params.orgID };

        Axios.post("/orgAdmin/departmentSettings/updateUseDepartments", data)
            .then((res) => {
                dispatch(OrganisationActions.orgUpdateUseDepartments(res.data.useDepartments));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleOpenDepartmentModal() {
        setModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleOpenEditModal(uuid) {
        setModal((prevState) => {
            return { ...prevState, edit: true, editUUID: uuid, name: settings.departments.find((depart) => depart.uuid == uuid).name, open: true };
        });
    }

    function handleModalTextChange(event) {
        const { value } = event.target;

        setModal((prevState) => {
            return { ...prevState, name: value };
        });
    }

    function handleAddDepartment() {
        if (modal.name == "") {
            setModal((prevState) => {
                return { ...prevState, errorMessage: "Please enter a name", triedSubmit: true };
            });
        } else {
            if (CheckDepartment(modal.name)) {
                setModal((prevState) => {
                    return { ...prevState, errorMessage: "There is already a department with this name", triedSubmit: true };
                });
            } else {
                const data = { orgID: params.orgID, departmentName: modal.name };

                Axios.post("/orgAdmin/departmentSettings/addDepartment", data)
                    .then((res) => {
                        console.log(res.data);
                        dispatch(OrganisationActions.orgUpdateNoOfDepartments(res.data.noOfDepartments));
                        dispatch(OrganisationActions.orgUpdateDepartments(res.data.departments));

                        setSettings((prevState) => {
                            return { ...prevState, departments: res.data.departments };
                        });

                        setModal(modalDefaultValues);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }

    function CheckDepartment(name) {
        let check = false;

        for (const department of organisation.departments) {
            if (department.name == name) {
                check = true;
                break;
            }
        }

        return check;
    }

    function handleOpenRemoveYN() {
        setModalYN({
            heading: "Remove Department",
            message: `Are you are sure you want to remove this department?`,
            acceptName: "Yes",
            acceptFunction: handleRemoveDepartment,
            showAccept: true,
            cancelName: "No",
            showCancel: true,
            open: true
        });
    }

    function handleRemoveDepartment() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        const data = { orgID: params.orgID, uuid: modal.editUUID };

        Axios.post("/orgAdmin/departmentSettings/removeDepartment", data)
            .then((res) => {
                dispatch(OrganisationActions.orgUpdateDepartments(res.data.departments));

                setSettings((prevState) => {
                    return { ...prevState, departments: res.data.departments };
                });

                setModal(modalDefaultValues);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleUpdate() {
        if (modal.name == "") {
            setModal((prevState) => {
                return { ...prevState, errorMessage: "Please enter a name", triedSubmit: true };
            });
        } else {
            const data = { orgID: params.orgID, uuid: modal.editUUID, name: modal.name };
            Axios.post("/orgAdmin/departmentSettings/updateDepartment", data)
                .then((res) => {
                    const data = res.data;
                    console.log(res.data);
                    if (data.error == "Yes") {
                        setModal((prevState) => {
                            return { ...prevState, errorMessage: data.message, triedSubmit: true };
                        });
                    } else {
                        dispatch(OrganisationActions.orgUpdateDepartments(data.departments));

                        setSettings((prevState) => {
                            return { ...prevState, departments: data.departments };
                        });

                        setModal(modalDefaultValues);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <div style={DeparmentSettingsStyles.body}>
            <div style={DeparmentSettingsStyles.topSection}>
                <div style={DeparmentSettingsStyles.firstHalfTopSection}>
                    <Form.Check label="Use Departments" checked={settings.useDepartments} onChange={handleUseDepartmentsChecked} />
                </div>
                <div style={DeparmentSettingsStyles.secondHalfTopSection}>
                    {settings.useDepartments && <Button onClick={handleOpenDepartmentModal}>Add</Button>}
                </div>
            </div>
            {settings.useDepartments && (
                <div style={DeparmentSettingsStyles.bottomContainer}>
                    Double click on a department to edit it
                    <div style={DeparmentSettingsStyles.listContainer}>
                        <ListGroup>
                            {settings.departments.map((department, index) => {
                                return (
                                    <ListGroup.Item key={index} action onDoubleClick={handleOpenEditModal.bind(this, department.uuid)}>
                                        {department.name}
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    </div>
                </div>
            )}
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.edit ? "Edit" : "Add"} Department</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control type="text" value={modal.name} maxLength={modal.counterMax} onChange={handleModalTextChange} />
                    </Form.Group>
                    <div style={DeparmentSettingsStyles.counter}>{`${modal.counterMax - modal.name.length}`}</div>
                    {modal.triedSubmit && <div style={DeparmentSettingsStyles.error}>{modal.errorMessage}</div>}
                </Modal.Body>
                <Modal.Footer>
                    {modal.edit ? (
                        <div>
                            <Button onClick={handleOpenRemoveYN}>Remove</Button>
                            <Button onClick={handleUpdate}>Update</Button>
                        </div>
                    ) : (
                        <Button onClick={handleAddDepartment}>Add</Button>
                    )}
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DeparmentSettings;

export const body = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center"
    border: "1px solid red"
};

export const topControls = {
    textAlign: "center",
    paddingTop: "20px"
    // border: "1px solid black"
};

export const weekBegin = {
    height: "100%",
    float: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const holidaysWeeks = {
    height: "100%",
    float: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const buttons = {
    height: "100%",
    float: "left",
    display: "flex"
};

export const timetableContiner = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

export const diaryContainer = {
    width: "100%",
    height: "100%",
    overflow: "hidden"
};

export const diaryInnerContainer = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    overflow: "scroll"
};

export const orgLocked = {
    fontWeight: "bold",
    fontSize: "1.5rem"
};

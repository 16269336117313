export const body = {
    paddingLeft: "30px",
    paddingRight: "30px"
};

export const alignRight = {
    textAlign: "right",
    paddingRight: "5%"
};

export const alignLeft = {
    textAlign: "left"
};

export const bulletPiontsSquare = {
    listStyle: "square"
};

export const icon = {
    width: "200px",
    height: "200px"
};

export const featRow = {
    display: "flex",
    flexDirection: "row"
};

export const box = {
    width: "50%",
    display: "flex",
    flexDirection: "row"
};

export const boxImage = {
    width: "40%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};

export const boxText = {
    width: "60%"
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";
import { Button, ListGroup, Modal, Form } from "react-bootstrap";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";

//STYLES
import * as AdminUsersStyles from "../styles/users";

function AdminUsers() {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const organisation = useSelector((state) => state.organisation);

    const [users, setUsers] = useState([]);

    const userModalDefaultValues = {
        open: false,
        departments: [],
        height: 0,
        values: {
            name: "",
            email: "",
            role: ""
        },
        valids: {
            name: false,
            email: false,
            role: false,
            departmentsSelected: false,
            departmentCore: false
        },
        triedSubmmit: false,
        bottomError: ""
    };

    const [userModal, setUserModal] = useState(userModalDefaultValues);

    function handleCloseUserModal() {
        setUserModal(userModalDefaultValues);
    }

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: "",
        showPassword: false,
        password: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateAdminMenuID("users"));

        onLoad();
    }, []);

    function onLoad() {
        const data = { orgID: params.orgID };

        Axios.post("/orgAdmin/users/getUsers", data)
            .then((res) => {
                const data = res.data;

                setUsers(data.users);

                const thisDepartments = [];

                for (const department of organisation.departments) {
                    thisDepartments.push({ selected: false, core: false, name: department.name, uuid: department.uuid });
                }

                setUserModal((prevState) => {
                    return { ...prevState, departments: thisDepartments };
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function reloadUsers() {
        const data = { orgID: params.orgID };

        Axios.post("/orgAdmin/users/getUsers", data)
            .then((res) => {
                const data = res.data;

                setUsers(data.users);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleAddUser() {
        setUserModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleTextChange(event) {
        const { name, value } = event.target;

        const values = { ...userModal.values };
        const valids = { ...userModal.valids };

        values[name] = value;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        valids[name] = isValid;

        setUserModal((prevState) => {
            return { ...prevState, values: values, valids: valids };
        });
    }

    function handleRoleClick(evant) {
        const { name } = event.target;

        const values = { ...userModal.values };
        const valids = { ...userModal.valids };

        values.role = name;
        valids.role = true;

        setUserModal((prevState) => {
            return { ...prevState, values, valids };
        });
    }

    function handleAddNewUser() {
        const wholeForm = Object.keys(userModal.valids).every(function (k) {
            return userModal.valids[k] === true;
        });
        if (!wholeForm) {
            setUserModal((prevState) => {
                return { ...prevState, triedSubmmit: true };
            });
        } else {
            const userDepartments = [];
            for (const department of userModal.departments) {
                if (department.core) {
                    userDepartments.push(department.uuid);
                }
            }

            for (const department of userModal.departments) {
                if (!department.core && department.selected) {
                    userDepartments.push(department.uuid);
                }
            }

            const data = {
                orgID: params.orgID,
                name: userModal.values.name,
                email: userModal.values.email,
                role: userModal.values.role,
                departments: userDepartments,
                newUserPasswords: organisation.newUserPasswords
            };

            Axios.post("/orgAdmin/users/addUser", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);

                    if (data.userError == "Yes") {
                        setUserModal((prevState) => {
                            return { ...prevState, bottomError: data.message };
                        });
                    } else {
                        setUserModal(userModalDefaultValues);

                        reloadUsers();

                        setModal((prevState) => {
                            return {
                                ...prevState,
                                heading: "Add User",
                                message: data.message,
                                showPassword: data.showPassword,
                                password: data.password,
                                open: true
                            };
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function handleSelectedChanged(event) {
        const { name, checked } = event.target;
        const id = name.split("_");

        const departmentList = [...userModal.departments];
        const valids = { ...userModal.valids };

        for (const department of departmentList) {
            if (department.name == id[0]) {
                department.selected = checked;
            }
        }

        let isValid = false;
        for (const department of departmentList) {
            if (department.selected) {
                isValid = true;
                break;
            }
        }

        valids.departmentsSelected = isValid;

        setUserModal((prevState) => {
            return { ...prevState, departments: departmentList, valids: valids };
        });
    }

    function handleCoreChanged(event) {
        const { name, checked } = event.target;
        const id = name.split("_");

        const departmentList = userModal.departments;
        const valids = userModal.valids;

        for (const department of departmentList) {
            department.core = false;
            if (department.name == id[0]) {
                department.core = true;
            }
        }

        valids.departmentCore = true;

        setUserModal((prevState) => {
            return { ...prevState, departments: departmentList, valids: valids };
        });
    }

    function handleUserDoubleClick(uuid) {
        navigate(`./userDetails/${uuid}`);
    }

    return (
        <div style={AdminUsersStyles.body}>
            <div style={AdminUsersStyles.divRight}>
                <Button onClick={handleAddUser}>Add User</Button>
            </div>
            <p>Double click on an user to make changes</p>
            <div style={AdminUsersStyles.usersContainer}>
                <ListGroup>
                    {users.map((user, index) => {
                        return (
                            <ListGroup.Item key={index} style={AdminUsersStyles.listItem} onDoubleClick={handleUserDoubleClick.bind(this, user.uuid)}>
                                {user.displayName}
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            </div>
            <Modal size="lg" show={userModal.open} onHide={handleCloseUserModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={AdminUsersStyles.userModalNameSide}>
                        <strong>User Details</strong>
                    </div>
                    <div style={AdminUsersStyles.userModalNameSide}>
                        <strong>Departments</strong>
                    </div>
                    <br /> <br />
                    <div style={AdminUsersStyles.hiddenDiv}>blankdiv</div>
                    <div style={AdminUsersStyles.departmentsHeadings}>
                        <div style={AdminUsersStyles.departmentSelectedCore}>
                            <strong>Select</strong>
                        </div>
                        <div style={AdminUsersStyles.departmentSelectedCore}>
                            <strong>Core</strong>
                        </div>
                        <div style={AdminUsersStyles.departmentName}>
                            <strong>Name</strong>
                        </div>
                    </div>
                    <div style={AdminUsersStyles.userModalNameSide}>
                        <Form.Group>
                            <Form.Label>Name:</Form.Label>
                            <Form.Control
                                isInvalid={userModal.triedSubmmit && !userModal.valids.name ? true : false}
                                type="text"
                                name="name"
                                value={userModal.values.name}
                                onChange={handleTextChange}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                isInvalid={userModal.triedSubmmit && !userModal.valids.email ? true : false}
                                type="text"
                                name="email"
                                value={userModal.values.email}
                                onChange={handleTextChange}
                            />
                            <Form.Control.Feedback type="invalid">Please enter an email</Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group>
                            <Form.Label>Role:</Form.Label>
                            <Form.Check
                                type="radio"
                                label="Admin"
                                name="admin"
                                checked={userModal.values.role == "admin"}
                                onChange={handleRoleClick}
                            />
                            <Form.Check type="radio" label="User" name="user" checked={userModal.values.role == "user"} onChange={handleRoleClick} />
                            <div
                                style={
                                    userModal.triedSubmmit && userModal.values.role == ""
                                        ? AdminUsersStyles.roleErrorShow
                                        : AdminUsersStyles.roleErrorHidden
                                }
                            >
                                Please select a role
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ ...AdminUsersStyles.userModalDepartmentsSide }}>
                        <div style={AdminUsersStyles.userModalDepartmentsSideInside}>
                            <ListGroup>
                                {userModal.departments.map((department, index) => {
                                    return (
                                        <ListGroup.Item key={index}>
                                            <div style={AdminUsersStyles.departmentSelectedCore}>
                                                <Form.Check
                                                    label=" "
                                                    name={`${department.name}_s`}
                                                    checked={department.selected}
                                                    onChange={handleSelectedChanged}
                                                />
                                            </div>
                                            <div style={AdminUsersStyles.departmentSelectedCore}>
                                                <Form.Check
                                                    label=" "
                                                    name={`${department.name}_c`}
                                                    checked={department.core}
                                                    onChange={handleCoreChanged}
                                                />
                                            </div>
                                            <div style={AdminUsersStyles.departmentName}>{department.name}</div>
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </div>
                    </div>
                    <div style={AdminUsersStyles.hiddenDiv}>blankDiv</div>
                    <div style={AdminUsersStyles.departmentsErrors}>
                        <div
                            style={
                                userModal.triedSubmmit && !userModal.valids.departmentsSelected
                                    ? AdminUsersStyles.roleErrorShow
                                    : AdminUsersStyles.roleErrorHidden
                            }
                        >
                            Please select at least one department
                        </div>
                        <div
                            style={
                                userModal.triedSubmmit && !userModal.valids.departmentCore
                                    ? AdminUsersStyles.roleErrorShow
                                    : AdminUsersStyles.roleErrorHidden
                            }
                        >
                            Please select at one core department
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-auto" style={AdminUsersStyles.addUserError}>
                        {userModal.bottomError}
                    </div>
                    <Button onClick={handleCloseUserModal}>Close</Button>
                    <Button onClick={handleAddNewUser}>Add</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modal.message}
                    {modal.showPassword && (
                        <div>
                            <br />
                            New Password: {modal.password}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AdminUsers;

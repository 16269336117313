import * as Colors from "../../../../globalStyles/colors";

export const body = {
    backgroundColor: Colors.background,
    fontSize: "1rem",
    padding: "15px"
};

export const subHeading = {
    color: "blue",
    textAlign: "center"
};

export const navBarContainer = {
    display: "flex",
    justifyContent: "center"
};

export const mxAuto = {
    fontSize: "1.3rem",
    color: "white"
};

export const navLink = {
    paddingLeft: "30px",
    paddingLight: "30px",
    color: "white"
};

export const navBar = {
    width: "50%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    contentAlign: "center",
    justifyContent: "row",
    backgroundColor: Colors.primary
    // marginLeft: "20%",
    // marginRight: "20%"
};

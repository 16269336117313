import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Image, Modal, ListGroup } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";
import { setIDAndName } from "../../../../../../../globalSettings/adminProfileSettings";

//STYLES
import * as AdminUserProfilesStyles from "../styles/userProfiles";

//IMAGES
import helpImg from "../../../images/help.png";

//MODELS
import { newProfileDefaultSettings } from "../models/accessLevelDefaultSettings";

function AdminUserProfiles() {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const [settings, setSettings] = useState({
        profiles: [],
        useProfiles: false,
        totalUsers: "",
        usersWithoutProfiles: "",
        defaultProfile: false,
        subMenu: false,
        profileUUID: ""
    });

    const [help, setHelp] = useState({
        open: false,
        heading: "",
        message: "",
        message2: "",
        message3: ""
    });

    function handleCloseHelp() {
        setHelp((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [createModal, setCreateModal] = useState({
        open: false,
        newProfileName: ""
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleOpenCreateModal() {
        setCreateModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleCloseCreateModal() {
        setCreateModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateAdminMenuID("userProfiles"));
        getProfiles();
    }, []);

    function getProfiles() {
        const data = { orgID: params.orgID };
        Axios.post("/orgAdmin/userProfiles/getOrgProfiles", data)
            .then((res) => {
                const data = res.data;

                let defaultPro = false;

                if (data.usersWithoutProfiles > 0) {
                    defaultPro = true;
                }

                data.profiles.sort((a, b) => {
                    return a.priority > b.priority ? 1 : -1;
                });

                setSettings((prevState) => {
                    return {
                        ...prevState,
                        profiles: data.profiles,
                        useProfiles: data.useProfiles == "true",
                        totalUsers: data.totalUsers,
                        usersWithoutProfiles: data.usersWithoutProfiles,
                        defaultProfile: defaultPro
                    };
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function onChange() {
        //DO NOTHING
    }

    function handleUpdateUseProfiles(event) {
        const { checked } = event.target;

        const data = { orgID: params.orgID, use: checked };
        Axios.post("/orgAdmin/userProfiles/useProfiles", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setSettings((prevState) => {
                        return { ...prevState, useProfiles: checked };
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function levelPriority(level) {
        const data = { orgID: params.orgID, level: level, profileUUID: settings.profileUUID };
        Axios.post("/orgAdmin/userProfiles/priority", data)
            .then((res) => {
                res.data.profiles.sort((a, b) => {
                    return a.priority > b.priority ? 1 : -1;
                });

                setSettings((prevState) => {
                    return { ...prevState, profiles: res.data.profiles };
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function showHelp() {
        const message =
            "The priority system only affects the rooms in the profiles. If the same room in two different profiles is marked ‘view’, the priority profile settings are set for that room.";
        const message2 =
            "The priority system does not affect any other settings in the profile. If a setting is turned on in any profile that is assigned to a particular user, the setting is on for that user.";
        const message3 = "'1' is the highest profile in the priority system";

        setHelp({ heading: "Help: Priority System", message: message, message2: message2, message3: message3, open: true });
    }

    function removeProfile() {
        setModal({ heading: "Remove Profile", message: "This feature has not be completed yet!", open: true });
    }

    function handleCreateModalTextChange(event) {
        const { value } = event.target;

        setCreateModal((prevState) => {
            return { ...prevState, newProfileName: value };
        });
    }

    function handleCreate() {
        if (createModal.newProfileName == "") {
            setModal({ heading: "Create A Profile", message: "Please enter a name", open: true });
        } else {
            const data = { orgID: params.orgID, name: createModal.newProfileName, defaultSettings: newProfileDefaultSettings };
            Axios.post("/orgAdmin/userProfiles/createProfile", data)
                .then((res) => {
                    const data = res.data;

                    if (data.message == "Profile Created") {
                        setCreateModal((prevState) => {
                            return { ...prevState, open: false };
                        });

                        getProfiles();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function goToSettings(uuid, name) {
        // setIDAndName(dispatch, uuid, name);
        navigate(`./profileSettings/${uuid}`);
    }

    function openSubMenu(uuid) {
        setSettings((prevState) => {
            return { ...prevState, profileUUID: uuid, subMenu: true };
        });
    }

    function closeSubMenu() {
        setSettings((prevState) => {
            return { ...prevState, subMenu: false };
        });
    }

    return (
        <div style={AdminUserProfilesStyles.body}>
            <div style={AdminUserProfilesStyles.topSection}>
                <Form.Check
                    type="checkbox"
                    checked={settings.useProfiles}
                    label="Use User Profiles"
                    onClick={handleUpdateUseProfiles}
                    onChange={onChange}
                />
            </div>
            {settings.useProfiles ? (
                <div style={AdminUserProfilesStyles.profileContainer}>
                    <div style={AdminUserProfilesStyles.profileControls}>
                        <div style={AdminUserProfilesStyles.columnHalfLeft}>
                            <Button variant="primary" onClick={handleOpenCreateModal}>
                                Create User Profile
                            </Button>
                            <p>Double click on a profile to edit it&apos;s settings</p>
                        </div>
                        <div style={AdminUserProfilesStyles.columnHalfRight}>
                            {/* {settings.subMenu ? ( */}
                            <div>
                                <Button onClick={() => levelPriority("increase")}>Increase Priority</Button>{" "}
                                <Button onClick={() => levelPriority("decrease")}>Decrease Priority</Button>{" "}
                                <Button hidden onClick={removeProfile}>
                                    Remove
                                </Button>
                                <Image style={AdminUserProfilesStyles.helpImage} src={helpImg} onClick={showHelp}></Image>
                            </div>
                            {/* ) : null} */}
                        </div>
                    </div>
                    {settings.useProfiles ? (
                        <div style={{ height: "80%" }}>
                            <div style={AdminUserProfilesStyles.listHeadings}>
                                <br />
                                <ListGroup>
                                    <ListGroup.Item>
                                        <div style={AdminUserProfilesStyles.profileCell_Small}>
                                            <strong>Priority</strong>
                                        </div>
                                        <div style={AdminUserProfilesStyles.profileCell_Large}>Name</div>
                                        <div style={AdminUserProfilesStyles.profileCell_Medium}>No. of users</div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                            <br />
                            <div style={AdminUserProfilesStyles.listContainer}>
                                <ListGroup>
                                    {settings.defaultProfile ? (
                                        <ListGroup.Item
                                            style={AdminUserProfilesStyles.default_ListItem}
                                            action
                                            onClick={closeSubMenu}
                                            onDoubleClick={() => goToSettings("default", "Default Profile (Access Level)")}
                                        >
                                            <div style={{ ...AdminUserProfilesStyles.profileCell_Small, ...AdminUserProfilesStyles.hide }}>.</div>
                                            <div style={AdminUserProfilesStyles.profileCell_Large}>Default Profile (Access Level)</div>
                                            <div style={AdminUserProfilesStyles.profileCell_Medium}>
                                                {settings.usersWithoutProfiles} / {settings.totalUsers} users
                                            </div>
                                        </ListGroup.Item>
                                    ) : null}
                                    {settings.profiles.map((profile, index) => {
                                        return (
                                            <ListGroup.Item
                                                href={"#" + index}
                                                key={index}
                                                action
                                                onClick={() => openSubMenu(profile.uuid)}
                                                onDoubleClick={() => goToSettings(profile.uuid, profile.name)}
                                            >
                                                <div style={AdminUserProfilesStyles.profileCell_Small}>
                                                    <strong>{profile.priority}</strong>
                                                </div>
                                                <div style={AdminUserProfilesStyles.profileCell_Large}>{profile.name}</div>
                                                <div style={AdminUserProfilesStyles.profileCell_Medium}>
                                                    {profile.noOfUsers} / {settings.totalUsers} users
                                                </div>
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            </div>
                            <br />
                        </div>
                    ) : null}
                </div>
            ) : null}

            <Modal show={createModal.open} onHide={handleCloseCreateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create A Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please enter a name:
                    <Form.Control type="text" value={createModal.newProfileName} onChange={handleCreateModalTextChange}></Form.Control>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCreate}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={help.open} onHide={handleCloseHelp}>
                <Modal.Header closeButton>
                    <Modal.Title>{help.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {help.message}
                    <br /> <br /> {help.message2} <br /> <br />
                    {help.message3}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseHelp}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AdminUserProfiles;

export const body = {
    paddingTop: "30px",
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%"
};

export const topSelect = {
    height: "10%"
};

export const controlsContainer = {
    height: "50%"
    // border: "1px solid black"
};

export const previewContiner = {
    height: "40%",
    display: "flex",
    justifyContent: "center",
    overflow: "scroll"
};

export const dropdownTitle = {
    float: "left",
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
};

export const border = {
    border: "1px solid black"
};

export const controlsHalf = {
    width: "50%",
    height: "100%",
    float: "left"
};

export const templateControlsContainer = {
    height: "10%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};

export const daysContainer = {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "10px"
};

export const breakTitles = {
    width: "10%",
    float: "left"
};

export const breakButtons = {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "10px"
};

export const diaryTimeContainers = {
    display: "flex",
    alignItems: "center",
    columnGap: "10px"
};

export const tableFont = {
    fontSize: "16px",
    fontWeight: "400"
};

export const dropdownItems = {
    maxHeight: "150px",
    overflowY: "auto"
};

export const controlsTopRight = {
    height: "80%"
};

export const controlsBottomRight = {
    height: "20%",
    textAlign: "right"
};

export const listViewContainer = {
    height: "90%",
    overflow: "scroll"
};

export const sessionsHeadings = {
    textAlign: "center"
};

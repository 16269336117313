import React, { useState, useEffect } from "react";
import { Offcanvas, Dropdown, Form, Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import Axios from "axios";
import { useParams } from "react-router-dom";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";
import * as BookingDataActions from "../../../../../../../store/actions/bookingData";

//STYLES
import "react-datepicker/dist/react-datepicker.css";
import * as BookingCanvasStyles from "../styles/bookingCanvas";

function BookingCanvas(props) {
    const params = useParams();
    const dispatch = useDispatch();

    //STORE
    const user = useSelector((state) => state.user);
    const globalVars = useSelector((state) => state.globalVars);
    const userProfile = useSelector((state) => state.userProfile);
    const organisation = useSelector((state) => state.organisation);
    const bookingData = useSelector((state) => state.bookingData);

    const [enableDelete, setEnableDelete] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);

    const defaultValues = {
        values: {
            user: { uuid: "", name: "" },
            department: { uuid: "", name: "" },
            sessionDes: "",
            comments: "",
            sessionLength: "",
            repeat: false,
            repeatType: "",
            repeatUntil: moment()
        },
        valids: {
            user: false,
            department: false,
            sessionDes: false,
            repeat: true,
            sessionLength: false,
            repeatUntil: true
        },
        wholeFormValid: false,
        errorOnForm: false,
        dateErrorMessage: "null"
    };

    const [formData, setFormData] = useState(defaultValues);

    const [settings, setSettings] = useState({
        users: [],
        showUserDropdown: false,
        departments: [],
        showDepartmentDropdown: false,
        sessionDescriptionTitle: "",
        sessions: [],
        showSessionDropdown: false
    });

    function handleCloseCanvas() {
        dispatch(GlobalVarsActions.UpdateBookingClose());
        dispatch(GlobalVarsActions.UpdateRoomSessionID(""));
        setFormData(defaultValues);
    }

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [errorMsg, setErrorMsg] = useState({
        open: false
    });

    function handleErrorMsgClose() {
        setErrorMsg((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalDelete, setModalDelete] = useState({
        open: false,
        heading: "",
        message: "",
        repeatBooking: ""
    });

    function handleModalDeleteClose() {
        setModalDelete((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        LoadData({ ...formData.values }, { ...settings });
    }, [globalVars.bookingOpen]);

    function LoadData(values, settings) {
        if (globalVars.roomDetail.layoutData.layout == "Timetable") {
            settings.sessionDescriptionTitle = "Class";
            if (globalVars.roomDetail.sessionID.includes("b")) {
                settings.showSessionDropdown = false;
                values.sessionLength = "1";
            } else {
                const IDs = globalVars.roomDetail.sessionID.split("-");
                const firstIndex = parseInt(IDs[1]);

                let sessions = [];
                let sess = 1;
                for (let i = firstIndex; i <= globalVars.roomDetail.totalSessions; i++) {
                    sessions.push(sess);
                    sess++;
                }

                settings.showSessionDropdown = true;
                settings.sessions = sessions;
                values.sessionLength = "1";
            }
        } else if (globalVars.roomDetail.layoutData.layout == "Diary") {
            settings.sessionDescriptionTitle = "Subject";

            const sTime = moment(globalVars.roomDetail.sessionLabel, "HH:mm");

            const ti = [];

            const start = moment(sTime, "HH:mm");
            const finish = moment(globalVars.roomDetail.finishTime, "HH:mm");

            start.add(globalVars.roomDetail.timeInterval, "m");
            values.sessionLength = start.format("HH:mm");

            while (start.isSameOrBefore(finish)) {
                ti.push(start.format("HH:mm"));
                start.add(globalVars.roomDetail.timeInterval, "m");
            }

            settings.showSessionDropdown = true;
            settings.sessions = ti;
        }

        //EDIT MODE

        if (globalVars.bookingEdit) {
            if (globalVars.editBookingData.userID == user.uuid || userProfile.room_Delete) {
                setEnableDelete(true);
            }
            if (globalVars.editBookingData.userID == user.uuid || userProfile.room_Edit) {
                setEnableEdit(true);
            }

            if (userProfile.userSettings.bookings.viewAllUsers) {
                const user = settings.users.find((use) => use.uuid == globalVars.editBookingData.userID);
            }
            values.sessionDes = globalVars.editBookingData.sessionDes;
            values.sessionLength = globalVars.editBookingData.sessionTotal;
            values.comments = globalVars.editBookingData.comments;
        }

        FetchUsersAndDepartments(values, settings);
    }

    function CheckEdit() {
        if (globalVars.bookingEdit) {
            console.log(globalVars.editBookingData);

            const values = {
                user: { uuid: "", name: "" },
                department: { uuid: "", name: "" },
                sessionDes: "",
                comments: globalVars.editBookingData.comments,
                sessionLength: "",
                repeat: false,
                repeatType: "",
                repeatUntil: moment()
            };

            const valids = {
                user: false,
                department: false,
                sessionDes: false,
                repeat: true,
                sessionLength: false,
                repeatUntil: true
            };
            console.log(values);
            setFormData((prevState) => {
                return { ...prevState, values: values, valids };
            });

            console.log("1");
        }
    }

    function FetchUsersAndDepartments(values, settings) {
        const data = {
            orgID: params.orgID,
            users: userProfile.userSettings.bookings.viewAllUsers,
            departments: userProfile.userSettings.bookings.viewAllDepartments,
            edit: globalVars.bookingEdit,
            editIDs: { user: globalVars.editBookingData.userID, department: globalVars.editBookingData.department }
        };
        Axios.post("/bookings/usersAndDepartments", data)
            .then((res) => {
                const data = res.data;

                let updateDepartments = false;
                if (data.userList == "none") {
                    settings.showUserDropdown = false;
                    values.user = { uuid: user.uuid, name: user.name };
                } else {
                    settings.showUserDropdown = true;
                    settings.users = data.userList;
                    values.user = data.userList.find((use) => use.uuid == user.uuid);
                }
                if (data.departmentList == "none") {
                    const splitDeparments = user.userDepartments.split(",");
                    if (splitDeparments.length == 1) {
                        (settings.showDepartmentDropdown = false),
                            (values.department = organisation.departments.find((depart) => depart.uuid == splitDeparments[0]));
                    } else {
                        const comboDepartments = [];
                        for (const department of splitDeparments) {
                            const depart = organisation.departments.find((depart) => depart.uuid == department);
                            if (depart != null) {
                                comboDepartments.push(depart);
                            } else {
                                updateDepartments = true;
                            }
                        }
                        settings.showDepartmentDropdown = true;
                        settings.departments = comboDepartments;
                        values.department = organisation.departments.find((depart) => depart.uuid == splitDeparments[0]);
                    }
                } else {
                    settings.showDepartmentDropdown = true;
                    settings.departments = data.departmentList;

                    const splitDeparments = user.userDepartments.split(",");
                    values.department = organisation.departments.find((depart) => depart.uuid == splitDeparments[0]);
                }

                if (updateDepartments) {
                    let departmentsUpdated = "";
                    const splitDeparments = user.userDepartments.split(",");
                    for (const department of splitDeparments) {
                        const depart = organisation.departments.find((depart) => depart.uuid == department);
                        if (depart != null) {
                            if (departmentsUpdated == "") {
                                departmentsUpdated = department;
                            } else {
                                departmentsUpdated = `${departmentsUpdated},${department}`;
                            }
                        }
                    }

                    const data = { uuid: user.uuid, departments: departmentsUpdated };
                    Axios.post("/orgAdmin/users/updateUsersDepartments", data)
                        .then((res) => {
                            const data = res.data;
                            console.log(data);
                        })
                        .catch((err) => console.log(err));
                }

                if (globalVars.bookingEdit) {
                    values.user = data.editIDs.user;
                    values.department = data.editIDs.department;
                }

                setSettings((prevState) => {
                    return { ...prevState, ...settings };
                });
                ValidateValues(values);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function ValidateValues(values) {
        const valids = { ...formData.valids };

        for (const [key, value] of Object.entries(values)) {
            if (key == "user" || key == "department" || key == "sessionDes") {
                if (value.name != "") {
                    valids[key] = true;
                }
            }
            if (key == "sessionLength") {
                if (value != "") {
                    valids[key] = true;
                }
            }
        }

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });
    }

    function handleDropDownItemSelected(dropdown, item) {
        const values = { ...formData.values };
        const valids = { ...formData.valids };

        let isValid = false;
        if (dropdown == "sessionLength") {
            if (item != "") {
                isValid = true;
            }
        } else {
            if (item.name != "") {
                isValid = true;
            }
        }

        values[dropdown] = item;
        valids[dropdown] = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });
    }

    function handleTextChange(event) {
        const { name, value } = event.target;

        const values = { ...formData.values };
        const valids = { ...formData.valids };

        let isValid = false;
        if (value != "") {
            isValid = true;
        }
        values[name] = value;
        valids[name] = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });
    }

    //REPEAT FUNCTIONS
    function handleRepeatClicked(event) {
        const { name, checked } = event.target;

        const values = { ...formData.values };
        const valids = { ...formData.valids };

        values[name] = checked;
        if (!checked) {
            values["repeatType"] = "";
        }
        valids[name] = !checked;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });
    }

    function handleRepeatTypeClicked(event) {
        const { name, checked } = event.target;

        const values = { ...formData.values };
        const valids = { ...formData.valids };

        values["repeatType"] = name;

        let isValid = false;
        if (values.repeat && values.repeatType != "") {
            isValid = true;
        }

        valids["repeat"] = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        if (name == "endOfYear" && checked) {
            const endDate = endOfYearDate();
            values.repeatUntil = new Date(endDate);
        }

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });
    }

    function endOfYearDate() {
        let endDate = moment();
        const today = moment();

        if (today.month() > 6) {
            const date = moment("31/07/" + today.year(), "DD/MM/YYYY");
            date.add(1, "y");
            endDate = moment(date, "DD/MM/YYYY");
        } else {
            const date = moment("31/07/" + today.year(), "DD/MM/YYYY");
            endDate = moment(date, "DD/MM/YYYY");
        }

        return endDate;
    }

    function handleBook() {
        if (!formData.wholeFormValid) {
            setFormData((prevState) => {
                return { ...prevState, errorOnForm: true };
            });
        } else {
            let startDate = moment(globalVars.roomDetail.date, "DD/MM/YYYY");
            let bookingType = "";
            let repeatType = "";
            let repeatUntil = "";
            let sessions = [];

            const IDs = globalVars.roomDetail.sessionID.split("-");
            if (globalVars.roomDetail.layoutData.layout == "Timetable") {
                if (!IDs[1].toString().includes("b")) {
                    let firstIndex = parseInt(IDs[1]);

                    for (var i = 0; i < formData.values.sessionLength; i++) {
                        sessions.push(firstIndex);
                        firstIndex++;
                    }
                } else {
                    sessions.push(IDs[1]);
                }
            } else if (globalVars.roomDetail.layoutData.layout == "Diary") {
                let sTime = moment(globalVars.roomDetail.sessionLabel, "HH:mm");
                const fTime = moment(formData.values.sessionLength, "HH:mm");

                while (sTime.isBefore(fTime)) {
                    sessions.push(sTime.format("HH:mm").replace(":", ""));
                    sTime.add(globalVars.roomDetail.timeInterval, "m");
                }
            }

            if (formData.values.repeat) {
                bookingType = "repeat";

                if (formData.values.repeatType == "daily") {
                    repeatType = "daily";
                    repeatUntil = formData.values.repeatUntil.toLocaleDateString();
                } else if (formData.values.repeatType == "weekly" || formData.values.repeatType == "endOfYear") {
                    repeatType = "weekly";
                    repeatUntil = formData.values.repeatUntil.toLocaleDateString();
                }
            } else {
                bookingType = "single";
                repeatUntil = "";
            }

            //WEEK SYSTEM
            let weekSysWeeks = "";
            for (const holiday of organisation.holidays) {
                if (holiday.titleUUID == globalVars.roomDetail.weekUUID) {
                    weekSysWeeks = holiday.weeks;
                    break;
                }
            }
            console.log(globalVars.roomDetail.weekUUID);
            const data = {
                orgID: params.orgID,
                roomID: globalVars.roomDetail.roomID,
                user: formData.values.user.uuid,
                departmentID: formData.values.department.uuid,
                sessionDes: formData.values.sessionDes,
                sessionTotal: sessions.length,
                sessions: sessions.toString(),
                comments: formData.values.comments,
                bookingType: bookingType,
                repeatType: repeatType,
                startDate: startDate.format("DD/MM/YYYY"),
                repeatUntil: repeatUntil,
                createdBy: user.uuid,
                dayList: globalVars.roomDetail.dayList,
                weekSystem: globalVars.roomDetail.weekSystem,
                weekSysWeeks: weekSysWeeks
            };

            Axios.post("/bookings/createBooking", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "null") {
                        if (data.userError == "null") {
                            setFormData(defaultValues);
                            handleCloseCanvas();
                            dispatch(BookingDataActions.GetData(bookingData.roomID, bookingData.weekBG, bookingData.days));
                        } else {
                            setModal(() => {
                                return { heading: "Booking", message: data.message, open: true };
                            });
                        }
                    } else {
                        if (data.message == "MSQL error") {
                            setErrorMsg(() => {
                                return { open: true };
                            });
                        } else {
                            setModal(() => {
                                return { heading: "Booking", message: data.message, open: true };
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function handleDateChange(date) {
        const values = { ...formData.values };
        const valids = { ...formData.valids };

        values["repeatUntil"] = date;

        let isValid = true;
        let errorMsg = "";

        const currentDate = moment(globalVars.roomDetail.date, "DD/MM/YYYY");
        const untilDate = moment(values.repeatUntil, "DD/MM/YYYY");
        const twoYearDate = moment(globalVars.roomDetail.date, "DD/MM/YYYY").add(2, "y");

        if (untilDate.isSameOrBefore(currentDate)) {
            errorMsg = "Please select a date after the selected booking date";
            isValid = false;
        }
        if (untilDate.isAfter(twoYearDate)) {
            errorMsg = "You cannot repeat a booking for more than two years";
            isValid = false;
        }

        valids.repeatUntil = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setFormData((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue, dateErrorMessage: errorMsg };
        });
    }

    //DELETE BOOKING
    function handleDeleteBooking() {
        if (globalVars.editBookingData.type == "repeat") {
            setModalDelete({
                heading: "Repeat Booking",
                message: "This is a repeat booking, do you want to delete the full booking or just this session?",
                repeatBooking: true,
                open: true
            });
        } else {
            setModalDelete({
                heading: "Delete Booking",
                message: "Are you sure you want to delete this booking?",
                repeatBooking: false,
                open: true
            });
        }
    }

    function handleDeleteFullBooking() {
        setModalDelete({ open: false });
        deleteFullBooking();
    }

    function deleteFullBooking() {
        const data = { orgID: params.orgID, bookingID: globalVars.editBookingData.uuid };
        Axios.post("/bookings/deleteBooking", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setModal({ heading: "Delete booking", message: "Booking deleted!", open: true });

                    setFormData(defaultValues);
                    handleCloseCanvas();
                    dispatch(BookingDataActions.GetData(bookingData.roomID, bookingData.weekBG, bookingData.days));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleDeleteSessionBooking() {
        setModalDelete({ open: false });

        const data = {
            orgID: params.orgID,
            bookingID: globalVars.editBookingData.uuid,
            sessionID: `${bookingData.week}-${globalVars.editBookingData.sessionID}`
        };
        Axios.post("/bookings/deleteSessionBooking", data)
            .then((res) => {
                const data = res.data;

                if (data.error === "null") {
                    if (data.userError === "Yes") {
                        setModalYN({
                            heading: "Repeat Booking",
                            message: data.message,
                            showAccept: true,
                            acceptName: "Yes",
                            acceptFunction: deleteFullBookingAcceptence,
                            showCancel: true,
                            cancelName: "No",
                            open: true
                        });
                    } else {
                        setFormData(defaultValues);
                        handleCloseCanvas();
                        dispatch(BookingDataActions.GetData(bookingData.roomID, bookingData.weekBG, bookingData.days));
                    }
                } else {
                    setModal(() => {
                        return { heading: "Booking", message: data.message, open: true };
                    });
                }

                console.log(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function deleteFullBookingAcceptence() {
        setModalYN({ open: false });

        deleteFullBooking();
    }

    //EDIT BOOKING
    function handleUpdateBooking() {
        if (!formData.wholeFormValid) {
            setFormData((prevState) => {
                return { ...prevState, errorOnForm: true };
            });
        } else {
            const data = {
                orgID: params.orgID,
                id: globalVars.editBookingData.uuid,
                user: formData.values.user.uuid,
                department: formData.values.department.uuid,
                sessionDes: formData.values.sessionDes,
                comments: formData.values.comments
            };
            Axios.post("/bookings/updateBooking", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setFormData(defaultValues);
                        handleCloseCanvas();
                        dispatch(BookingDataActions.GetData(bookingData.roomID, bookingData.weekBG, bookingData.days));
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const userSection = (
        <div>
            {settings.showUserDropdown ? (
                <div>
                    <Dropdown>
                        <Dropdown.Toggle variant={formData.errorOnForm && !formData.valids.user ? "danger" : "primary"}>
                            {formData.values.user.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={BookingCanvasStyles.dropdownItems}>
                            {settings.users.map((item, index) => {
                                return (
                                    <Dropdown.Item key={index} onClick={handleDropDownItemSelected.bind(this, "user", item)}>
                                        {item.name}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    <p
                        style={
                            formData.errorOnForm && !formData.valids.user ? BookingCanvasStyles.errorText_Show : BookingCanvasStyles.errorText_Hidden
                        }
                    >
                        Please select an user
                    </p>
                </div>
            ) : (
                <div>
                    {formData.values.user.name} <br /> <br />
                </div>
            )}
        </div>
    );

    const departmentSection = (
        <div>
            {settings.showDepartmentDropdown ? (
                <div>
                    <Dropdown>
                        <Dropdown.Toggle variant={formData.errorOnForm && !formData.valids.department ? "danger" : "primary"}>
                            {formData.values.department.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={BookingCanvasStyles.dropdownItems}>
                            {settings.departments.map((item, index) => {
                                return (
                                    <Dropdown.Item key={index} onClick={handleDropDownItemSelected.bind(this, "department", item)}>
                                        {item.name}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    <p
                        style={
                            formData.errorOnForm && !formData.valids.department
                                ? BookingCanvasStyles.errorText_Show
                                : BookingCanvasStyles.errorText_Hidden
                        }
                    >
                        Please select a department
                    </p>
                </div>
            ) : (
                <div>{formData.values.department.name}</div>
            )}
        </div>
    );

    const sessionDes = (
        <Form.Group>
            <Form.Control
                isInvalid={formData.errorOnForm && !formData.valids.sessionDes ? true : false}
                type="text"
                name="sessionDes"
                value={formData.values.sessionDes}
                onChange={handleTextChange}
            />
            <p
                style={
                    formData.errorOnForm && !formData.valids.sessionDes ? BookingCanvasStyles.errorText_Show : BookingCanvasStyles.errorText_Hidden
                }
            >
                Please put a {settings.sessionDescriptionTitle}
            </p>
        </Form.Group>
    );

    return (
        <div>
            <Offcanvas style={{ width: "400px" }} show={globalVars.bookingOpen} onHide={handleCloseCanvas} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{globalVars.bookingEdit ? "Modify Booking..." : "Create a new booking..."}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    User:
                    {globalVars.bookingEdit ? (
                        <div>
                            {enableEdit ? (
                                <div>{userSection}</div>
                            ) : (
                                <div>
                                    {formData.values.user.name}
                                    <br /> <br />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>{userSection}</div>
                    )}
                    Department:
                    {globalVars.bookingEdit ? (
                        <div>
                            {enableEdit ? (
                                <div>{departmentSection}</div>
                            ) : (
                                <div>
                                    {formData.values.department.name}
                                    <br /> <br />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>{departmentSection}</div>
                    )}
                    {settings.sessionDescriptionTitle}:
                    {globalVars.bookingEdit ? (
                        <div>
                            {enableEdit ? (
                                <div>{sessionDes}</div>
                            ) : (
                                <div>
                                    {formData.values.sessionDes} <br /> <br />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>{sessionDes}</div>
                    )}
                    {globalVars.bookingEdit ? (
                        <div>
                            Session Length: {formData.values.sessionLength} <br /> <br />
                        </div>
                    ) : (
                        <div>
                            Session Length:
                            {settings.showSessionDropdown ? (
                                <div>
                                    <Dropdown>
                                        <Dropdown.Toggle variant={formData.errorOnForm && !formData.valids.sessionLength ? "danger" : "primary"}>
                                            {formData.values.sessionLength}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={BookingCanvasStyles.dropdownItems}>
                                            {settings.sessions.map((item, index) => {
                                                return (
                                                    <Dropdown.Item key={index} onClick={handleDropDownItemSelected.bind(this, "sessionLength", item)}>
                                                        {item}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <p
                                        style={
                                            formData.errorOnForm && !formData.valids.sessionLength
                                                ? BookingCanvasStyles.errorText_Show
                                                : BookingCanvasStyles.errorText_Hidden
                                        }
                                    >
                                        Please select a session length
                                    </p>
                                </div>
                            ) : (
                                <> {formData.values.sessionLength}</>
                            )}
                        </div>
                    )}
                    {globalVars.bookingEdit ? (
                        <div>
                            Booking Type: {capitalizeFirstLetter(globalVars.editBookingData.type)} <br />
                            {globalVars.editBookingData.type == "repeat" && (
                                <div>
                                    Repeat Type: {capitalizeFirstLetter(globalVars.editBookingData.repeatType)} <br />
                                    Repeat Until: {globalVars.editBookingData.repeatUntil}
                                </div>
                            )}
                            <br />
                        </div>
                    ) : (
                        <div>
                            {userProfile.room_Repeat && (
                                <div>
                                    <Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            label="Repeat"
                                            name="repeat"
                                            checked={formData.values.repeat}
                                            onChange={handleRepeatClicked}
                                        />
                                    </Form.Group>
                                    {formData.values.repeat && (
                                        <div>
                                            <div style={BookingCanvasStyles.repeatOtions}>
                                                <Form.Check
                                                    label="Daily"
                                                    name="daily"
                                                    checked={formData.values.repeatType == "daily" ? true : false}
                                                    onChange={handleRepeatTypeClicked}
                                                />
                                                <Form.Check
                                                    label="Weekly"
                                                    name="weekly"
                                                    checked={formData.values.repeatType == "weekly" ? true : false}
                                                    onChange={handleRepeatTypeClicked}
                                                />
                                                <Form.Check
                                                    label="End of Year (Weekly)"
                                                    name="endOfYear"
                                                    checked={formData.values.repeatType == "endOfYear" ? true : false}
                                                    onChange={handleRepeatTypeClicked}
                                                />
                                            </div>
                                            {formData.errorOnForm && !formData.valids.repeat && (
                                                <p style={BookingCanvasStyles.errorText_Show}>Please select a repeat type</p>
                                            )}
                                            <div style={BookingCanvasStyles.repeatDate}>
                                                Repeat Until:&nbsp;&nbsp;
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={new Date(formData.values.repeatUntil)}
                                                    onChange={handleDateChange}
                                                />
                                                <p
                                                    style={
                                                        formData.errorOnForm && !formData.valids.repeatUntil
                                                            ? BookingCanvasStyles.errorText_Show
                                                            : BookingCanvasStyles.errorText_Hidden
                                                    }
                                                >
                                                    {formData.dateErrorMessage}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    {globalVars.bookingEdit ? (
                        <div>
                            {enableEdit ? (
                                <div>
                                    Comments:
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="comments"
                                        value={formData.values.comments}
                                        onChange={handleTextChange}
                                    />
                                </div>
                            ) : (
                                <div>
                                    Comments: <br />
                                    {formData.values.comments}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            Comments:
                            <Form.Control as="textarea" rows={3} name="comments" value={formData.values.comments} onChange={handleTextChange} />
                        </div>
                    )}
                    <br />
                    {globalVars.bookingEdit ? (
                        <div>
                            {enableEdit && <Button onClick={handleUpdateBooking}>Update</Button>}

                            {enableDelete && <Button onClick={handleDeleteBooking}>Delete</Button>}
                        </div>
                    ) : (
                        <Button onClick={handleBook}>Book</Button>
                    )}
                    <p
                        style={
                            formData.errorOnForm && !formData.wholeFormValid
                                ? BookingCanvasStyles.errorText_Show
                                : BookingCanvasStyles.errorText_Hidden
                        }
                    >
                        There are errors
                    </p>
                </Offcanvas.Body>
            </Offcanvas>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={errorMsg.open} onHide={handleErrorMsgClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Booking Error!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    I am very sorry but you have run into an error.
                    <br />
                    <br />
                    Please inform the Senior Admin of your organisation&apos;s account on My-STAFF.co.uk <br /> <br />
                    <strong>Week System</strong>
                    <br />
                    <br />
                    If you have the week system enabled, please make sure that all weeks are assigned to a title.
                    <br /> <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleErrorMsgClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalDelete.open} onHide={handleModalDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalDelete.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalDelete.message}</Modal.Body>
                <Modal.Footer>
                    {modalDelete.repeatBooking ? (
                        <div>
                            <Button variant="primary" onClick={handleDeleteFullBooking}>
                                Full Booking
                            </Button>
                            <Button variant="primary" onClick={handleDeleteSessionBooking}>
                                This Session
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <Button variant="primary" onClick={handleDeleteFullBooking}>
                                    Yes
                                </Button>
                            </div>
                        </div>
                    )}
                    <Button variant="primary" onClick={handleModalDeleteClose}>
                        {modalDelete.repeatBooking ? "Cancel" : "No"}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default BookingCanvas;

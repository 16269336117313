export const repeatOtions = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
};

export const repeatDate = {
    columnGap: "10px"
};

export const dropdownItems = {
    maxHeight: "150px",
    overflowY: "auto"
};

export const errorText_Show = {
    color: "red",
    textAlign: "right",
    visibility: "visible"
};

export const errorText_Hidden = {
    color: "red",
    textAlign: "right",
    visibility: "hidden"
};

import * as Colours from "../../../../../../../globalStyles/colors";

export const body = {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};

export const innerContainer = {
    backgroundColor: Colours.primary,
    width: "80%",
    height: "80%",
    borderRadius: "25px",
    color: "white",
    fontSize: "1.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
};

export const buttons = {
    width: "30%",
    display: "flex",
    justifyContent: "space-around"
};

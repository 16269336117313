import * as Colors from "../../../../../globalStyles/colors";

export const body = {
    backgroundColor: Colors.background,
    fontSize: "1rem"
};

export const headingText = {
    font: "1.5rem sans-serif",
    paddingTop: "10px",
    textAlign: "left"
};

export const plusImage = {
    width: "50px",
    height: "50px"
};

export const normalText = {
    fontSize: "1rem",
    paddingLeft: "1%",
    float: "left",
    textAlign: "left",
    width: "90%"
};

export const marginTextHide = {
    font: "1rem",
    paddingLeft: "2px",
    width: "9%",
    float: "left",
    textAlign: "center",
    visibility: "hidden"
};

export const addButton = {
    float: "right"
};

export const scrollable_300 = {
    height: "300px",
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "visible"
};

export const scrollable_150 = {
    height: "150px",
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "visible"
};

export const scrollable_200 = {
    height: "200px",
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "visible"
};

export const scrollable_250 = {
    height: "250px",
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "visible"
};

export const buttonHidden = {
    float: "right",
    visibility: "hidden"
};

export const buttonShow = {
    float: "right"
};

export const sideBySide = {
    float: "left"
};

export const dropdownItems = {
    maxHeight: "150px",
    overflowY: "auto"
};

export const centred = {
    padding: "15px",
    float: "left"
};

export const sideBySideR = {
    float: "right"
};

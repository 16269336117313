import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

//COMPONENTS
import OrgNumberSignIn from "./pods/orgNumberSignIn";
import HomePage from "./pods/homePage";
import Features from "./pods/features";
import Contact from "./pods/contact";

//ACTIONS
import * as HomePageSettingsActions from "../../../../globalSettings/homePageSettings";

//STYLES
import * as HomeStyles from "../styles/home";

function Home() {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const HomePageGlobalSettings = useSelector((state) => state.HomePageGlobalSettings);

    useEffect(() => {
        document.title = "STAFF";
        console.log(HomePageGlobalSettings);
        onOpen();
    }, []);

    function onOpen() {
        if (user.auth == true) {
            if (user.role == "superAdmin") {
                navigate("/administrator");
            } else {
                navigate("/org/" + user.orgID);
            }
        }
    }

    function changeTab(tab) {
        HomePageSettingsActions.UpdateTab(dispatch, tab);
        HomePageSettingsActions.UpdateFormSettings(dispatch, false);
    }

    return (
        <div style={HomeStyles.body}>
            <div style={HomeStyles.subHeading}>
                <h1>
                    <strong>A place to be flexible with your bookings...</strong>
                </h1>
                <br />
            </div>
            <div style={HomeStyles.navBarContainer}>
                <Navbar style={HomeStyles.navBar} variant="dark">
                    <Nav style={HomeStyles.mxAuto}>
                        <Nav.Link style={HomeStyles.navLink} onClick={() => changeTab("home")}>
                            Home &nbsp;
                        </Nav.Link>
                        <Nav.Link style={HomeStyles.navLink} onClick={() => changeTab("features")}>
                            &nbsp;Features &nbsp;
                        </Nav.Link>
                        <Nav.Link style={HomeStyles.navLink} onClick={() => changeTab("contact")}>
                            &nbsp;Contact
                        </Nav.Link>
                    </Nav>
                </Navbar>
            </div>
            <br />
            {HomePageGlobalSettings.tab == "home" ? <HomePage /> : null}
            {HomePageGlobalSettings.tab == "features" ? <Features /> : null}
            {HomePageGlobalSettings.tab == "contact" ? <Contact /> : null}
        </div>
    );
}

export default Home;

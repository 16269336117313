import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";

//COMPONENTS
import ServerPath from "../../../../../ServerPath";

//STYLES
import * as AdminStyles from "../styles/admin";

function SignIn() {
    const [granted, setGranted] = useState(true);
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        ServerPath();
        onOpen();
    }, []);

    function onOpen() {
        if (user.auth) {
            if (user.role == "superAdmin") {
                navigate("/");
            } else {
                setGranted(false);
            }
        }
    }

    function handleGoogleLink() {
        const host = ServerPath();

        window.location.href = `${host}/userLogin/auth/github`;
    }

    return (
        <div style={AdminStyles.body}>
            <Container className="p-3">
                <h1>Administrator Sign In</h1>
                {!granted ? (
                    <div>
                        <h1>Access Denied</h1>
                        <Button variant="warning" href="/">
                            Back to Home
                        </Button>
                        <br />
                        <br />
                    </div>
                ) : (
                    <div>
                        <br />
                        <Button variant="dark" onClick={handleGoogleLink}>
                            <i className="fab fa-github"></i> Log in with Github
                        </Button>
                        <br />
                        <br />
                    </div>
                )}
            </Container>
        </div>
    );
}

export default SignIn;

import React from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Image, Card, Button } from "react-bootstrap";

//COMPONENTS
import OrgNumberSignIn from "./orgNumberSignIn";
import useWindowSize from "./windowSize";

//ACTIONS
import * as HomePageSettings from "../../../../../globalSettings/homePageSettings";

//IMAGES
import screenTimetable from "../../images/screen-timetable.png";
import screenDiary from "../../images/screen-diary.png";

//STYLES
import * as HomePageStyles from "../../styles/pods/homePage";

function HomePage() {
    const [windowWidth] = useWindowSize();

    const dispatch = useDispatch();

    function onClickContact() {
        HomePageSettings.UpdateTab(dispatch, "contact");
    }

    return (
        <div>
            <div style={HomePageStyles.imageContainer}>
                <Image style={HomePageStyles.homeImg} src={screenTimetable} />
                <Image style={HomePageStyles.homeImg} src={screenDiary} />
            </div>
            <br />
            <div style={HomePageStyles.secondRow}>
                <div style={HomePageStyles.textContainer}>
                    <h2 style={HomePageStyles.blueHeading}>
                        <strong>Your very own room booking portal!</strong>
                    </h2>
                    <p style={HomePageStyles.text}>
                        With a My STAFF account, your organisation will have its own portal where all your users can book out rooms for education,
                        office meeting rooms and lots more. With the very dymanic design you can create the rooms to suit your needs. From a timetable
                        format to a 24-hour diary format. If you have two rooms that require a timetable format but each room requires a slightly
                        different format, this system can provide you with that functionality. Have a system so users within your organisation can
                        book out any, or paricular rooms, in seconds!
                    </p>
                </div>
                <div style={HomePageStyles.orgNumberSignIn}>
                    <OrgNumberSignIn />
                </div>
            </div>
            <br /> <br />
            <div style={HomePageStyles.pricesRow}>
                <Card style={{ ...HomePageStyles.mxAuto, ...HomePageStyles.card }}>
                    <Card.Title style={HomePageStyles.cardTitle}>1 - 5 Rooms</Card.Title>
                    <Card.Body>
                        2.99 per room <br /> per month
                        <br /> <br />
                        <Button variant="primary" onClick={onClickContact}>
                            Contact
                        </Button>
                    </Card.Body>
                </Card>
                <Card style={{ ...HomePageStyles.mxAuto, ...HomePageStyles.card }}>
                    <Card.Title style={HomePageStyles.cardTitle}>6 - 10 Rooms</Card.Title>
                    <Card.Body>
                        £2.50 per room <br /> per month
                        <br /> <br />
                        <Button variant="primary" onClick={onClickContact}>
                            Contact
                        </Button>
                    </Card.Body>
                </Card>
                <Card style={{ ...HomePageStyles.mxAuto, ...HomePageStyles.card }}>
                    <Card.Title style={HomePageStyles.cardTitle}>11 - 20 Rooms</Card.Title>
                    <Card.Body>
                        £1.99 per room <br /> per month
                        <br /> <br />
                        <Button variant="primary" onClick={onClickContact}>
                            Contact
                        </Button>
                    </Card.Body>
                </Card>
                <Card style={{ ...HomePageStyles.mxAuto, ...HomePageStyles.card }}>
                    <Card.Title style={HomePageStyles.cardTitle}>21 Rooms +</Card.Title>
                    <Card.Body>
                        £0.99 per room <br /> per month
                        <br /> <br />
                        <Button variant="primary" onClick={onClickContact}>
                            Contact
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}

export default HomePage;

export const body = {
    paddingTop: "30px",
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%"
};

export const topSection = {
    height: "10%"
};

export const firstHalfTopSection = {
    width: "50%",
    float: "left"
};

export const secondHalfTopSection = {
    width: "50%",
    float: "left",
    textAlign: "right"
};

export const bottomContainer = {
    height: "90%"
};

export const listContainer = {
    height: "50%",
    overflow: "scroll"
};

export const columnRight = {
    textAlign: "right"
};

export const counter = {
    textAlign: "right",
    color: "red"
};

export const error = {
    color: "red",
    fontWeight: "bold"
};

export const body = {
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%",
    position: "relative"
};

export const top = {
    height: "20%",
    overflow: "hidden"
};

export const topSelections = {
    width: "25%",
    float: "left",
    textAlign: "center",
    position: "absolute"
};

export const dropdownItems = {
    maxHeight: "300px",
    overflowY: "auto"
};

export const mainHeadings = {
    height: "10%"
};

export const list = {
    height: "70%",
    overflow: "scroll"
};

export const roomsLbl = {
    float: "left",
    padding: "15px"
};

export const rooms = {
    float: "left"
};

export const sortBox = {
    cursor: "pointer",
    padding: "auto"
};

import React, { useEffect, useState } from "react";
import { Form, ListGroup, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

//SETTINGS
import * as AdminUsersSettings from "../../../../../../../globalSettings/adminUserSettings";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";

//STYLES
import * as AdminUsersStyles from "../styles/users";

function UserDetails() {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const organisation = useSelector((state) => state.organisation);
    const AdminUserGlobalSettings = useSelector((state) => state.AdminUserGlobalSettings);

    const [details, setDetails] = useState({
        departments: [],
        values: {
            name: "",
            email: "",
            role: ""
        },
        valids: {
            name: false,
            email: false,
            role: false,
            departmentsSelected: false,
            departmentCore: false
        },
        triedSubmmit: false
    });

    const [modalChangeSA, setModalChangeSA] = useState({
        open: false
    });

    function handleModalChangeSAClose() {
        setModalChangeSA((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: "",
        showPassword: false,
        password: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalTO, setModalTO] = useState({
        open: false,
        heading: "",
        message: "",
        option1Function: "",
        option1Name: "",
        showOption1: false,
        option2Function: "",
        option2Name: "",
        showOption2: false
    });

    function handleModalTOClose() {
        setModalTO((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateAdminMenuID("users"));

        onLoad();
    }, []);

    function onLoad() {
        AdminUsersSettings.setGUserID(dispatch, params.id);

        const data = { uuid: params.id };
        Axios.post("/orgAdmin/users/getJustUser", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "no user") {
                    navigate("/org/" + params.orgID + "/organisationAdmin");
                } else {
                    const values = { ...details.values };
                    const valids = { ...details.valids };

                    values.name = data.user.displayName;
                    values.email = data.user.email;
                    values.role = data.user.role;

                    const thisDepartments = [];

                    for (const department of organisation.departments) {
                        thisDepartments.push({ selected: false, core: false, name: department.name, uuid: department.uuid });
                    }

                    const userDepartments = data.user.departments.split(",");

                    thisDepartments.find((department) => department.uuid == userDepartments[0]).core = true;

                    if (userDepartments.length > 1) {
                        for (const [index, depart] of userDepartments.entries()) {
                            if (index != 0) {
                                thisDepartments.find((department) => department.uuid == depart).selected = true;
                            }
                        }
                    }

                    if (data.user.displayName != "") {
                        valids.name = true;
                    }
                    if (data.user.email != "") {
                        valids.email = true;
                    }
                    if (data.user.role != "") {
                        valids.role = true;
                    }

                    let selectedValid = false;
                    let coreValid = false;
                    for (const department of thisDepartments) {
                        if (department.selected) {
                            selectedValid = true;
                        }
                        if (department.core) {
                            coreValid = true;
                        }
                    }

                    valids.departmentsSelected = selectedValid;
                    valids.departmentCore = coreValid;

                    setDetails((prevState) => {
                        return { ...prevState, values: values, valids: valids, departments: thisDepartments };
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleUpdateUser() {
        const wholeForm = Object.keys(details.valids).every(function (k) {
            return details.valids[k] === true;
        });
        if (!wholeForm) {
            setDetails((prevState) => {
                return { ...prevState, triedSubmmit: true };
            });
        } else {
            const userDepartments = [];
            for (const department of details.departments) {
                if (department.core) {
                    userDepartments.push(department.uuid);
                }
            }

            for (const department of details.departments) {
                if (!department.core && department.selected) {
                    userDepartments.push(department.uuid);
                }
            }

            const data = {
                uuid: AdminUserGlobalSettings.uuid,
                name: details.values.name,
                email: details.values.email,
                role: details.values.role,
                departments: userDepartments,
                orgID: params.orgID
            };

            Axios.post("/orgAdmin/users/updateUser", data)
                .then((res) => {
                    const data = res.data;

                    if (data.message == "User updated successfully") {
                        setModal((prevState) => {
                            return {
                                ...prevState,
                                heading: "Update User",
                                message: "User has been updated",
                                showPassword: false,
                                password: "",
                                open: true
                            };
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function handleSelectedChanged(event) {
        const { name, checked } = event.target;
        const id = name.split("_");

        const departmentList = [...details.departments];
        const valids = { ...details.valids };

        for (const department of departmentList) {
            if (department.name == id[0]) {
                department.selected = checked;
            }
        }

        let isValid = false;
        for (const department of departmentList) {
            if (department.selected) {
                isValid = true;
                break;
            }
        }

        valids.departmentsSelected = isValid;

        setDetails((prevState) => {
            return { ...prevState, departments: departmentList, valids: valids };
        });
    }

    function handleCoreChanged(event) {
        const { name, checked } = event.target;
        const id = name.split("_");

        const departmentList = [...details.departments];
        const valids = { ...details.valids };

        for (const department of departmentList) {
            department.core = false;
            if (department.name == id[0]) {
                department.core = true;
            }
        }

        valids.departmentCore = true;

        setDetails((prevState) => {
            return { ...prevState, departments: departmentList, valids: valids };
        });
    }

    function handleTextChange(event) {
        const { name, value } = event.target;

        const values = { ...details.values };
        const valids = { ...details.valids };

        values[name] = value;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        valids[name] = isValid;

        setDetails((prevState) => {
            return { ...prevState, values: values, valids: valids };
        });
    }

    function handleRoleClick(evant) {
        const { name } = event.target;

        const values = { ...details.values };
        const valids = { ...details.valids };

        values.role = name;
        valids.role = true;

        setDetails((prevState) => {
            return { ...prevState, values, valids };
        });
    }

    //ADVANCED ACTIONS
    function handleSeniorAdminRequest() {
        setModalChangeSA({ open: true });
    }

    function handleAcceptOfSARequest() {
        const data = { orgID: params.orgID, newUUID: AdminUserGlobalSettings.uuid };
        Axios.post("/orgAdmin/users/changeSeniorAdmin", data)
            .then((res) => {
                if (res.data.message == "Request sent successfully") {
                    setModalChangeSA({ open: false });
                    setModal({
                        heading: "Change of Senior Admin Request",
                        message: "A request has been sent to " + details.values.name,
                        showPassword: false,
                        password: "",
                        open: true
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleUserChangeMethod() {
        const data = { uuid: AdminUserGlobalSettings.uuid };
        Axios.post("/orgAdmin/users/getUserLoginMethod", data)
            .then((res) => {
                const method = res.data.method;

                if (method == "") {
                    const message = res.data.name + " has not logged into the website yet, you can choose which method you want them to log in with.";

                    setModalTO({
                        heading: "Change Login Method",
                        message: message,
                        option1Name: "Local",
                        option2Name: "Google",
                        option1Function: acceptToChangeToLocal,
                        option2Function: acceptToChangeToGoogle,
                        showOption1: true,
                        showOption2: true,
                        open: true
                    });
                } else {
                    let newMethod;
                    if (method == "local") {
                        newMethod = "google";
                    } else if (method == "google") {
                        newMethod = "local";
                    }

                    const message =
                        res.data.name + "'s login method is " + method + ". Are you sure you want to change it to a " + newMethod + " method?";

                    setModalYN({
                        heading: "Change Login Method",
                        message: message,
                        acceptName: "Yes",
                        acceptFunction: () => {
                            acceptToChangeMethods(AdminUserGlobalSettings.uuid, newMethod, params.orgID);
                        },
                        showAccept: true,
                        cancelName: "No",
                        showCancel: true,
                        open: true
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function acceptToChangeMethods(id, newMethod, orgID) {
        setModalYN({ open: false });

        const data = { orgID: orgID, uuid: id, method: newMethod };
        Axios.post("/orgAdmin/users/changeUserLoginMethod", data)
            .then((res) => {
                if (res.data.message == "Strategy Updated") {
                    setModal({
                        heading: "Change Login Method",
                        message: "This user's login method has now been changed",
                        showPassword: false,
                        password: "",
                        open: true
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function acceptToChangeToGoogle() {
        setModalTO({ open: false });

        const data = { orgID: params.orgID, uuid: AdminUserGlobalSettings.uuid, method: "google" };
        Axios.post("/orgAdmin/users/changeUserLoginMethod", data)
            .then((res) => {
                if (res.data.message == "Strategy Updated") {
                    setModal({
                        heading: "Change Login Method",
                        message: "This user's login method has now been changed",
                        showPassword: false,
                        password: "",
                        open: true
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function acceptToChangeToLocal() {
        setModalTO({ open: false });

        const data = { orgID: params.orgID, uuid: AdminUserGlobalSettings.uuid, method: "local" };
        Axios.post("/orgAdmin/users/changeUserLoginMethod", data)
            .then((res) => {
                if (res.data.message == "Strategy Updated") {
                    setModal({
                        heading: "Change Login Method",
                        message: "This user's login method has now been changed",
                        showPassword: false,
                        password: "",
                        open: true
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleResetPasswordButtonClick() {
        setModalYN({
            heading: "Reset Password",
            message: `Are you are sure you want to change ${details.values.name}'s password?`,
            acceptName: "Yes",
            acceptFunction: acceptMethodOfResetPassword.bind(this, details.values.email),
            showAccept: true,
            cancelName: "No",
            showCancel: true,
            open: true
        });
    }

    function acceptMethodOfResetPassword(email) {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        const data = { email: email, resetUserPasswords: organisation.resetUserPasswords };

        Axios.post("/security/requestPassword", data)
            .then((res) => {
                const data = res.data;
                if (data.userError === "Yes") {
                    setModal({ heading: "Reset Password", message: data.message, showPassword: false, password: "", open: true });
                } else {
                    if (data.showPassword) {
                        setModal({
                            heading: "Reset Password",
                            message: `${details.values.name}'s password has been reset`,
                            showPassword: true,
                            password: data.password,
                            open: true
                        });
                    } else {
                        setModal({
                            heading: "Reset Password",
                            message: `${details.values.name}'s password has been reset`,
                            showPassword: false,
                            password: "",
                            open: true
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleRemove() {
        if (details.values.role == "seniorAdmin") {
            setModal({ heading: "Remove User", message: "You cannot delete a Senior Admin", showPassword: false, password: "", open: true });
        } else {
            setModalYN({
                heading: "Remove User",
                message: "Are you sure you want to remove this user and all their bookings?",
                acceptName: "Yes",
                acceptFunction: acceptRemove,
                showAccept: true,
                showCancel: true,
                cancelName: "No",
                open: true
            });
        }
    }

    function acceptRemove() {
        setModalYN({ open: false });

        const data = { uuid: AdminUserGlobalSettings.uuid, orgID: params.orgID };

        Axios.post("/orgAdmin/users/removeUser", data)
            .then((res) => {
                const data = res.data;

                if (data.message == "User removed successfully") {
                    setModal((prevState) => {
                        return { ...prevState, heading: "Remove User", message: "User has been removed and deleted", open: true };
                    });

                    navigate("/org/" + params.orgID + "/organisationAdmin");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div style={AdminUsersStyles.body}>
            <div style={AdminUsersStyles.detailsTitle}>User Details</div>
            <br />
            <div style={AdminUsersStyles.halfContainer}>
                <div style={AdminUsersStyles.subTitle}>Basic Details</div>
                <br />
                <Form.Group>
                    <Form.Control
                        style={AdminUsersStyles.formControl}
                        isInvalid={details.triedSubmmit && !details.valids.name ? true : false}
                        type="text"
                        name="name"
                        value={details.values.name}
                        onChange={handleTextChange}
                    />
                    <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                </Form.Group>
                <br />
                <Form.Group>
                    <Form.Control
                        style={AdminUsersStyles.formControl}
                        isInvalid={details.triedSubmmit && !details.valids.email ? true : false}
                        type="text"
                        name="email"
                        value={details.values.email}
                        onChange={handleTextChange}
                    />
                    <Form.Control.Feedback type="invalid">Please enter an email</Form.Control.Feedback>
                </Form.Group>
                <br />
                <Form.Group>
                    <Form.Label>Role:</Form.Label>
                    {details.values.role == "seniorAdmin" ? (
                        <div style={AdminUsersStyles.senoirAdminRole}>Senior Admin</div>
                    ) : (
                        <div>
                            <Form.Check type="radio" label="Admin" name="admin" checked={details.values.role == "admin"} onChange={handleRoleClick} />
                            <Form.Check type="radio" label="User" name="user" checked={details.values.role == "user"} onChange={handleRoleClick} />
                        </div>
                    )}
                    <div
                        style={details.triedSubmmit && details.valids.role == "" ? AdminUsersStyles.roleErrorShow : AdminUsersStyles.roleErrorHidden}
                    >
                        Please select a role
                    </div>
                </Form.Group>
            </div>
            <div style={{ height: "50%", ...AdminUsersStyles.halfContainer }}>
                <div style={AdminUsersStyles.subTitle}>Departments</div>
                <br />
                <div style={AdminUsersStyles.departmentSelectedCore}>
                    <strong>Select</strong>
                </div>
                <div style={AdminUsersStyles.departmentSelectedCore}>
                    <strong>Core</strong>
                </div>
                <div style={AdminUsersStyles.departmentName}>
                    <strong>Name</strong>
                </div>
                <br />
                <div style={AdminUsersStyles.departmentList}>
                    <ListGroup>
                        {details.departments.map((department, index) => {
                            return (
                                <ListGroup.Item key={index}>
                                    <div style={AdminUsersStyles.departmentSelectedCore}>
                                        <Form.Check
                                            label=" "
                                            name={`${department.name}_s`}
                                            checked={department.selected}
                                            onChange={handleSelectedChanged}
                                        />
                                    </div>
                                    <div style={AdminUsersStyles.departmentSelectedCore}>
                                        <Form.Check label=" " name={`${department.name}_c`} checked={department.core} onChange={handleCoreChanged} />
                                    </div>
                                    <div style={AdminUsersStyles.departmentName}>{department.name}</div>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </div>
            </div>
            <div style={AdminUsersStyles.halfContainerCenter}>
                <div style={AdminUsersStyles.subTitle}>Advanced Actions</div>
                <Button style={AdminUsersStyles.advanceButton} onClick={handleSeniorAdminRequest}>
                    S/A Request
                </Button>
                <br />
                <Button style={AdminUsersStyles.advanceButton} onClick={handleUserChangeMethod}>
                    Change Login Method
                </Button>
                <br />
                {organisation.resetUserPasswords && (
                    <div>
                        <Button style={AdminUsersStyles.advanceButton} onClick={handleResetPasswordButtonClick}>
                            Reset Password
                        </Button>
                        <br />
                    </div>
                )}
                <Button style={AdminUsersStyles.advanceButton} onClick={handleRemove}>
                    Delete User
                </Button>
            </div>
            <div style={AdminUsersStyles.halfContainerRight}>
                <div style={AdminUsersStyles.departmentsErrors}>
                    <div
                        style={
                            details.triedSubmmit && !details.valids.departmentsSelected
                                ? AdminUsersStyles.roleErrorShow
                                : AdminUsersStyles.roleErrorHidden
                        }
                    >
                        Please select at least one department
                    </div>
                    <div
                        style={
                            details.triedSubmmit && !details.valids.departmentCore ? AdminUsersStyles.roleErrorShow : AdminUsersStyles.roleErrorHidden
                        }
                    >
                        Please select at one core department
                    </div>
                </div>
                <br />
                <Button onClick={handleUpdateUser}>Update User</Button>
            </div>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modal.message}
                    {modal.showPassword && (
                        <div>
                            <br />
                            New Password: {modal.password}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalChangeSA.open} onHide={handleModalChangeSAClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change of Senior Admin Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You are requesting {details.values.name} to change roles to the Senior Admin of this organisation. You can only have ONE
                        Senior Admin per organisation, so this means you will not have access to the administrator panel for this organisation. Your
                        role will change to Admin
                    </p>
                    <p>This user will also be to new ‘point of contact’ for High-View Studios.</p>
                    <p>This user will be sent an email and they have to accept the request. In the meantime you will still have access.</p>
                    <p>You and High-View Studios will be notified when this request has been accepted.</p>
                    <p>Do you want to continue?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAcceptOfSARequest}>
                        Yes
                    </Button>
                    <Button variant="primary" onClick={handleModalChangeSAClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
            <Modal show={modalTO.open} onHide={handleModalTOClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTO.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalTO.message}</Modal.Body>
                <Modal.Footer>
                    {modalTO.showOption1 ? (
                        <div>
                            <Button variant="primary" onClick={modalTO.option1Function}>
                                {modalTO.option1Name}
                            </Button>
                        </div>
                    ) : null}
                    {modalTO.showOption2 ? (
                        <div>
                            <Button variant="primary" onClick={modalTO.option2Function}>
                                {modalTO.option2Name}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UserDetails;

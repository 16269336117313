export const body = {
    paddingTop: "30px",
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%",
    width: "50%"
};

export const saveButton = {
    textAlign: "right"
};

export const counter = {
    color: "red",
    textAlign: "right"
};

export const body = {
    paddingTop: "30px",
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%"
};

export const prioritiesRight = {
    float: "right",
    verticalAlign: "middle"
};

export const prioritiesLeft = {
    // float: "left"
};

export const helpImage = {
    width: "40px",
    height: "50px",
    cursor: "pointer"
};

export const default_ListItem = {
    color: "red"
};

export const columnHalfLeft = {
    width: "50%",
    float: "left",
    // border: "1px solid black",
    height: "100%"
};

export const columnHalfRight = {
    width: "50%",
    float: "left",
    // border: "1px solid black",
    height: "100%",
    textAlign: "right"
};

export const topSection = {
    height: "5%"
};

export const profileContainer = {
    height: "95%"
};

export const profileControls = {
    height: "10%"
    // border: "1px solid black"
};

export const listHeadings = {
    height: "12%"
    // border: "1px solid black"
};

export const listContainer = {
    height: "88%",
    // border: "1px solid red",
    overflow: "scroll"
};

export const profileCell_Small = {
    width: "10%",
    float: "left"
};

export const profileCell_Medium = {
    width: "40%",
    float: "left"
};

export const profileCell_Large = {
    width: "50%",
    float: "left"
};

export const hide = {
    visibility: "hidden"
};

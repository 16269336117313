import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";

//SETTINGS
import * as AdminLayoutsSettings from "../../../../../../../globalSettings/adminLayoutsSettings";

//STYLES
import * as LayoutStyles from "../styles/slots";

function SessionSlot(props) {
    const AdminLayoutsGlobalSettings = useSelector((state) => state.AdminLayoutsGlobalSettings);
    const dispatch = useDispatch();

    function handleChangeCustomText(event) {
        const { value } = event.target;

        AdminLayoutsSettings.updateCustomText(AdminLayoutsGlobalSettings, dispatch, props.id, value);
    }

    function handleChangeHoverText(event) {
        const { value } = event.target;

        AdminLayoutsSettings.updateHoverText(AdminLayoutsGlobalSettings, dispatch, props.id, value);
    }

    function handleBreakBtns() {
        AdminLayoutsSettings.toggleBreakBtns(dispatch, 0, false);
    }

    return (
        <div>
            <Row>
                <Col sm={1}>
                    <div style={LayoutStyles.slotID}>{props.id}</div>
                </Col>
                <Col>
                    <Form.Control
                        id="txtCustomText"
                        type="text"
                        value={props.customText}
                        onFocus={handleBreakBtns}
                        onChange={handleChangeCustomText}
                    />
                </Col>
                <Col>
                    <Form.Control id="txtHoverText" type="text" value={props.hoverText} onFocus={handleBreakBtns} onChange={handleChangeHoverText} />
                </Col>
            </Row>
        </div>
    );
}

export default SessionSlot;

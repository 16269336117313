import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Modal, ListGroup } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GlobalVarsActions from "../../../../../../../store/actions/globalVars";
import * as OrganisationActions from "../../../../../../../store/actions/organistion";

//STYLES
import * as AdminWeekSystemHolidaysStyles from "../styles/weekSystemHolidays";

function AdminWeekSystemHolidays() {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const yearPreview = useRef(null);

    const [settings, setSettings] = useState({
        startDate: moment(),
        endDate: moment(),
        weeksNum: [],
        weeks: [],
        weekSystem: false,
        orgLocked: false,
        collides: false,
        holidayTitles: [],
        editWeek: false,
        selectedWeek: 20
    });

    const [modal, setModal] = useState({
        open: false,
        heading: "",
        message: ""
    });

    function handleModalClose() {
        setModal(holidayModalDefaultValues);
    }

    const [modalLg, setModalLg] = useState({
        open: false,
        heading: "",
        message1: "",
        message2: "",
        message3: "",
        boldMessage: ""
    });

    function handleModalLgClose() {
        setModalLg((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const holidayModalDefaultValues = {
        open: false,
        name: "",
        valid: false,
        edit: false,
        editUUID: 0,
        triedSubmmit: false
    };

    const [holidayModal, setHolidayModal] = useState(holidayModalDefaultValues);

    function handleHolidayModalClose() {
        setHolidayModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalLock, setModalLock] = useState({
        open: false,
        heading: "",
        message1: "",
        message2: "",
        message3: "",
        boldMessage: ""
    });

    function handleModalLockClose() {
        setModalLock((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GlobalVarsActions.UpdateAdminMenuID("weeksHolidays"));
        onLoad();
    }, []);

    function onLoad() {
        const data = { orgID: params.orgID };
        Axios.post("/orgAdmin/weekSystem/getHolidayData", data)
            .then((res) => {
                const data = res.data;
                console.log(data);

                const weeks = getWeeks(moment(data.org.holidayStartDate, "DD/MM/YYYY"), moment(data.org.holidayEndDate, "DD/MM/YYYY"));

                setSettings((prevState) => {
                    return {
                        ...prevState,
                        startDate: moment(data.org.holidayStartDate, "DD/MM/YYYY"),
                        endDate: moment(data.org.holidayEndDate, "DD/MM/YYYY"),
                        weeksNum: BuildWeekNums(data.org.weeks),
                        weekSystem: data.org.weekSystem == "true",
                        orgLocked: data.locked == "true",
                        collides: data.collideBookings,
                        holidayTitles: data.titles,
                        weeks: workDownloadedHolidays(weeks, data.holidays)
                    };
                });
            })
            .catch((err) => console.log(err));

        yearPreview.current.scrollIntoView();
    }

    function BuildWeekNums(weekNum) {
        let weeks = [];
        for (let i = 1; i <= weekNum; i++) {
            weeks.push(i);
        }

        return weeks;
    }

    function workDownloadedHolidays(weeks, holidays) {
        if (holidays.length > 0) {
            for (const holiday of holidays) {
                for (const week of weeks) {
                    if (holiday.weeks.includes(week.week)) {
                        week.holiday = holiday.titleUUID;
                    }
                }
            }
            return weeks;
        }
    }

    function handleStartDateChange(date) {
        const weeks = getWeeks(moment(date, "DD/MM/YYYY").format("DD/MM/YYYY"), settings.endDate);

        setSettings((prevState) => {
            return { ...prevState, startDate: moment(date, "DD/MM/YYYY"), weeks: weeks };
        });
    }

    function handleEndDateChange(date) {
        const weeks = getWeeks(settings.startDate, moment(date, "DD/MM/YYYY").format("DD/MM/YYYY"));

        setSettings((prevState) => {
            return { ...prevState, endDate: moment(date, "DD/MM/YYYY"), weeks: weeks };
        });
    }

    function getWeeks(startDate, endDate) {
        if (startDate != "" && endDate != "") {
            let sDate = moment(startDate, "DD/MM/YYYY").startOf("week");
            const eDate = moment(endDate, "DD/MM/YYYY").startOf("week");

            let weeks = [];

            while (sDate.isSameOrBefore(eDate)) {
                const weekNum = formatString(sDate.week()) + "-" + sDate.format("YY");
                const week = { week: formatString(sDate.week()) + "-" + sDate.format("YY"), date: sDate.format("DD/MM/YYYY"), holiday: 0 };

                let find = settings.weeks.find((w) => w.week === weekNum);
                if (find != null) {
                    weeks.push(find);
                } else {
                    weeks.push(week);
                }

                sDate.add(1, "w");
            }

            return weeks;
        }
    }

    function handleWeekSystemPlus() {
        const weeksNum = settings.weeksNum;

        let len = weeksNum.length + 1;
        if (len == 100) {
            setModal((prevState) => {
                return { ...prevState, heading: "Weeks", message: "You cannot exceed 99 weeks", open: true };
            });
        } else {
            weeksNum.push(len);

            setSettings((prevState) => {
                return { ...prevState, weeksNum: weeksNum };
            });
        }
    }

    function handleWeekSystemMinus() {
        const weeksNum = settings.weeksNum;
        if (weeksNum.length > 1) {
            const weekNo = weeksNum.pop();
            const newWeek = weekNo - 1;

            let weeks = settings.weeks;
            for (const week of weeks) {
                if (week.holiday == "w" + formatString(weekNo)) {
                    week.holiday = "w" + formatString(newWeek);
                }
            }
            setSettings((prevState) => {
                return { ...prevState, weeksNum: weeksNum, weeks: weeks };
            });
        }
    }

    function formatString(time) {
        if (time.toString().length == 1) {
            return "0" + time;
        } else {
            return time;
        }
    }

    function handleAddHolidayClick() {
        setHolidayModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleHolidayTitleChange(event) {
        const { value } = event.target;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        setHolidayModal((prevState) => {
            return { ...prevState, name: value, valid: isValid };
        });
    }

    function handleHolidayModalAdd() {
        if (!holidayModal.valid) {
            setHolidayModal((prevState) => {
                return { ...prevState, triedSubmmit: true };
            });
        } else {
            const data = { orgID: params.orgID, title: holidayModal.name };
            Axios.post("/orgAdmin/weekSystem/addHolidayTitle", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.message == "Successfully added") {
                        setSettings((prevState) => {
                            return { ...prevState, holidayTitles: data.titles };
                        });
                        setHolidayModal(holidayModalDefaultValues);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function handleHolidayModalUpdate() {
        if (!holidayModal.valid) {
            setHolidayModal((prevState) => {
                return { ...prevState, triedSubmmit: true };
            });
        } else {
            const data = { orgID: params.orgID, uuid: holidayModal.editUUID, title: holidayModal.name };
            Axios.post("/orgAdmin/weekSystem/renameHolidayTile", data)
                .then((res) => {
                    const data = res.data;

                    if (data.message == "Successfully Updated") {
                        setSettings((prevState) => {
                            return { ...prevState, holidayTitles: data.titles };
                        });

                        setHolidayModal(holidayModalDefaultValues);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function handleHolidayModalDelete() {
        const data = { orgID: params.orgID, uuid: holidayModal.editUUID };

        Axios.post("/orgAdmin/weekSystem/deleteHolidayTitle", data)
            .then((res) => {
                const data = res.data;

                if (data.message == "Successfully Deleted") {
                    setSettings((prevState) => {
                        return { ...prevState, holidayTitles: data.titles };
                    });
                    deleteInWeeks(holidayModal.editUUID);
                    setHolidayModal(holidayModalDefaultValues);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function deleteInWeeks(uuid) {
        const weeks = settings.weeks;
        for (const week of weeks) {
            if (week.holiday == uuid) {
                week.holiday = 0;
            }
        }

        setSettings((prevState) => {
            return { ...prevState, weeks: weeks };
        });
    }

    function handleHolidayClick(uuid, name) {
        if (!settings.editWeek) {
            setHolidayModal((prevState) => {
                return { ...prevState, edit: true, editUUID: uuid, name: name, open: true };
            });
        } else {
            const weeks = settings.weeks;

            weeks[settings.selectedWeek].holiday = "h" + formatString(uuid);

            setSettings((prevState) => {
                return { ...prevState, weeks: weeks, selectedWeek: prevState.selectedWeek + 1 };
            });
        }
    }

    function findHoliday(uuid) {
        if (uuid == 0) {
            return "Normal";
        } else {
            let id = "";
            if (uuid.includes("h")) {
                const num = uuid.replace("h", "");

                if (num[0] == "0") {
                    id = num[1];
                } else {
                    id = num;
                }

                for (const title of settings.holidayTitles) {
                    if (title.uuid == id) {
                        return title.name;
                    }
                }
            }
            if (uuid.includes("w")) {
                const num = uuid.replace("w", "");

                if (num[0] == "0") {
                    return "Week " + num[1];
                } else {
                    return "Week " + num;
                }
            }
        }
    }

    function handleWeekInWeeksSection(num) {
        if (settings.editWeek) {
            const weeks = settings.weeks;

            weeks[settings.selectedWeek].holiday = "w" + formatString(num);

            setSettings((prevState) => {
                return { ...prevState, weeks: weeks, selectedWeek: prevState.selectedWeek + 1 };
            });
        }
    }

    function handleWeekInYearPreview(id) {
        setSettings((prevState) => {
            return { ...prevState, selectedWeek: id, editWeek: true };
        });
    }

    function handleStopEditing() {
        setSettings((prevState) => {
            return { ...prevState, editWeek: false };
        });
    }

    function handleWeekSystemClicked(event) {
        const { checked } = event.target;
        let weeksNum = [];
        if (checked) {
            weeksNum = ["1"];
            setSettings((prevState) => {
                return { ...prevState, weekSystem: checked, weeksNum: weeksNum };
            });
            MarkNormalAsWeek();
        } else {
            const data = { orgID: params.orgID };
            Axios.post("/orgAdmin/weekSystem/getRoomsWeekSystem", data)
                .then((res) => {
                    if (res.data.rooms) {
                        setModal({
                            heading: "Week System",
                            message: "There are some rooms still using the week system, delete them first",
                            open: true
                        });
                    } else {
                        setSettings((prevState) => {
                            return { ...prevState, weekSystem: checked, weeksNum: weeksNum };
                        });
                        MarkWeekAsNormal();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function MarkNormalAsWeek() {
        let weeks = settings.weeks;
        for (const week of weeks) {
            if (week.holiday == "0") {
                week.holiday = "w01";
            }
        }

        setSettings((prevState) => {
            return { ...prevState, weeks: weeks };
        });
    }

    function MarkWeekAsNormal() {
        let weeks = settings.weeks;
        for (const week of weeks) {
            if (week.holiday.includes("w")) {
                week.holiday = "0";
            }
        }

        setSettings((prevState) => {
            return { ...prevState, weeks: weeks };
        });
    }

    function handleSave() {
        console.log(settings.weeks);
        const holidays = {};
        for (const holiday of settings.weeks) {
            if (holiday.holiday != "0") {
                let week = holidays[holiday.holiday];
                if (week == "" || week == null) {
                    week = holiday.week;
                } else {
                    week += "," + holiday.week;
                }
                holidays[holiday.holiday] = week;
            }
        }

        const keys = Object.keys(holidays);
        console.log(keys);
        console.log(holidays);

        const data = {
            orgID: params.orgID,
            startDate: settings.startDate.format("DD/MM/YYYY"),
            endDate: settings.endDate.format("DD/MM/YYYY"),
            holidays: keys,
            holidayWeeks: holidays,
            weekSystem: settings.weekSystem.toString(),
            weeksNo: settings.weeksNum.length
        };
        Axios.post("/orgAdmin/weekSystem/saveHolidays", data)
            .then((res) => {
                const data = res.data;
                dispatch(OrganisationActions.orgUpdateHolidays(data.holidays));
                if (data.locked) {
                    dispatch(OrganisationActions.orgUpdateLocked("true"));

                    setSettings((prevState) => {
                        return { ...prevState, orgLocked: data.locked, collides: data.collidedBookings };
                    });

                    const locked =
                        "Some or all of your rooms have the week system enabled on them. Due to this, your organisation will be locked until you double check all the bookings are where they should be.\n\n\n\n";
                    const instructions =
                        "To unlock the organisation, go on 'UNLOCK' and follow the instructions to unlock. Here you will also be able to restore the system back to how it was, by going on 'RESTORE'";
                    const collide =
                        "\n\nSome of your bookings have been collided with single bookings, as single bookings are not affected with changing the week system. Please go to 'COLLIDED BOOKINGS' to see them";

                    let message = "";
                    let bold = "";

                    if (data.collidedBookings) {
                        message = collide;
                        bold = "You will NOT be able to unlock your organisation until you have cleared all the collided bookings";
                    }

                    setModalLg({
                        heading: "Week System",
                        message1: locked,
                        message2: instructions,
                        message3: message,
                        boldMessage: bold,
                        open: true
                    });
                } else {
                    setModal({ heading: "Week System / Holidays", message: "Your settings have been saved!", open: true });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleUnlock() {
        const message =
            "To unlock this organisation make sure that you have cleared all of the collided bookings, the system WILL NOT let you unlock until this is done. Backups only stay active for one cycle of locking organisation. Once unlocked, they are deleted and this is unreservable!!";
        const restore = "If you want to restore everything to the orginial state, before you edit the week system, click 'RESTORE'";

        setModalLock({ heading: "Unlock Organisation", message1: message, message2: restore, open: true });
    }

    function handleModalLockUnlock() {
        setModalLock((prevState) => {
            return { ...prevState, open: false };
        });

        const data = { orgID: params.orgID };
        Axios.post("/orgAdmin/weekSystem/unlock", data)
            .then((res) => {
                const data = res.data;

                if (data.error == "Yes") {
                    setModalLock({ open: false });
                    setModal({ heading: "Error Unlocking", message: data.message, open: true });
                } else {
                    if (data.message == "System Unlocked") {
                        dispatch(OrganisationActions.orgUpdateLocked(data.locked == "true"));
                        setModalYN({
                            heading: "System Unlocked",
                            message: "Your system has been unlocked!",
                            showAccept: true,
                            acceptName: "Ok",
                            acceptFunction: AcceptenceOK,
                            showCancel: false,
                            open: true
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function AcceptenceOK() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
        navigate("/org/" + params.orgID);
    }

    function handleModalLockRestore() {
        setModalYN({
            heading: "Are you sure?",
            message: "Are you sure you want to Restore?",
            acceptName: "Yes",
            showAccept: true,
            acceptFunction: restoreAcceptence,
            cancelName: "No",
            showCancel: true,
            open: true
        });
    }

    function restoreAcceptence() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        setModalLock((prevState) => {
            return { ...prevState, open: false };
        });

        const data = { orgID: params.orgID };
        Axios.post("/orgAdmin/weekSystem/restore", data)
            .then((res) => {
                const data = res.data;

                if (data.message == "System Restored") {
                    dispatch(OrganisationActions.orgUpdateLocked(data.locked == "true"));
                    dispatch(OrganisationActions.orgUpdateHolidays(data.holidays));
                    setModalYN({
                        heading: "System Restored",
                        message: "Your system has been restored!",
                        showAccept: true,
                        acceptName: "Ok",
                        acceptFunction: AcceptenceOK,
                        showCancel: false,
                        open: true
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleCollisionBookings() {
        navigate("./collisionBookings");
    }

    return (
        <div style={AdminWeekSystemHolidaysStyles.body}>
            <div style={AdminWeekSystemHolidaysStyles.helpSection}>
                <div style={AdminWeekSystemHolidaysStyles.sectionTitle}>Help</div>
                <br />
                <br />
                {!settings.editWeek ? (
                    <div>
                        <strong>Dates</strong> <br />
                        Set the &apos;Start Date&apos; and &apos;End Date&apos; to bring up the weeks under Year Preview
                        <br />
                        <br />
                        <strong>Weeks</strong> <br />
                        {settings.weekSystem
                            ? "Click on the plus or minus to alter the amount of weeks in your timetable"
                            : "Check the 'Use weeks system' box to start using the week system"}
                        <br /> <br />
                        <strong>Holidays</strong> <br />
                        Click on a holiday title to edit it
                        <br /> <br />
                        <strong>Year Preview</strong> <br />
                        Click on a week on the right to assign a holiday to it
                    </div>
                ) : (
                    <div>
                        <strong>Dates</strong> <br />
                        Set the &apos;Start Date&apos; and &apos;End Date&apos; to bring up the weeks under Year Preview
                        <br />
                        <br />
                        <strong>Weeks</strong> <br />
                        Click on a week to assign
                        <br /> <br />
                        <strong>Holidays</strong> <br />
                        Click on a holiday to assign
                        <br /> <br />
                        <strong>Year Preview</strong> <br />
                        Select &apos;Stop Editing&apos; to edit the weeks and the holidays
                    </div>
                )}
            </div>
            <div style={AdminWeekSystemHolidaysStyles.controlsSection}>
                <div>
                    <div style={AdminWeekSystemHolidaysStyles.sectionTitle}>Dates</div>
                    <br />
                    <div style={AdminWeekSystemHolidaysStyles.dateContainer}>
                        Start Date:
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={new Date(settings.startDate)}
                            value={settings.startDate.format("DD/MM/YYYY")}
                            onChange={handleStartDateChange}
                        />
                    </div>
                    <br />
                    <div style={AdminWeekSystemHolidaysStyles.dateContainer}>
                        End Date:
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={new Date(settings.endDate)}
                            value={settings.endDate.format("DD/MM/YYYY")}
                            onChange={handleEndDateChange}
                        />
                    </div>
                </div>
                <br />
                <div>
                    <div style={AdminWeekSystemHolidaysStyles.sectionTitle}>Weeks</div>
                    <div style={settings.editWeek ? AdminWeekSystemHolidaysStyles.hidden : AdminWeekSystemHolidaysStyles.show}>
                        <Form.Check label="Use weeks system" checked={settings.weekSystem} onChange={handleWeekSystemClicked} />
                    </div>
                    <br />
                    <div style={settings.weekSystem ? AdminWeekSystemHolidaysStyles.show : AdminWeekSystemHolidaysStyles.hidden}>
                        <div style={AdminWeekSystemHolidaysStyles.weeksNumContainer}>
                            <div style={AdminWeekSystemHolidaysStyles.numWeeksSubSection_title}>Weeks: {settings.weeksNum.length}</div>
                            <div
                                style={
                                    settings.weekSystem && !settings.editWeek
                                        ? AdminWeekSystemHolidaysStyles.numWeeksSubSection_btnsShow
                                        : AdminWeekSystemHolidaysStyles.numWeeksSubSection_btnsHidden
                                }
                            >
                                <Button onClick={handleWeekSystemMinus}>-</Button>
                                <Button onClick={handleWeekSystemPlus}>+</Button>
                            </div>
                        </div>
                        <br />
                        <div style={AdminWeekSystemHolidaysStyles.weeksList}>
                            <ListGroup>
                                {settings.weeksNum.map((week, index) => {
                                    return (
                                        <ListGroup.Item
                                            key={index}
                                            action
                                            onClick={() => {
                                                handleWeekInWeeksSection(week);
                                            }}
                                        >
                                            Week {week}
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </div>
                    </div>
                    <br />
                    <div style={AdminWeekSystemHolidaysStyles.sectionTitle}>Holidays</div>
                    <Button
                        style={!settings.editWeek ? AdminWeekSystemHolidaysStyles.show : AdminWeekSystemHolidaysStyles.hidden}
                        onClick={handleAddHolidayClick}
                    >
                        Add Holiday
                    </Button>
                    <div style={AdminWeekSystemHolidaysStyles.weeksList}>
                        <ListGroup>
                            {settings.holidayTitles.map((title, index) => {
                                return (
                                    <ListGroup.Item key={index} action onClick={() => handleHolidayClick(title.uuid, title.name)}>
                                        {title.name}
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    </div>
                </div>
            </div>
            <div style={AdminWeekSystemHolidaysStyles.weeksSection}>
                <div style={AdminWeekSystemHolidaysStyles.yearPreviewTitle}>
                    <div style={AdminWeekSystemHolidaysStyles.yearTitlefirstHalf}>Year Preview:</div>
                    <div style={AdminWeekSystemHolidaysStyles.yearTitleSecondHalf}>
                        {settings.editWeek && <Button onClick={handleStopEditing}>Stop Editing</Button>}
                    </div>
                </div>
                <div style={AdminWeekSystemHolidaysStyles.yearPreviewWeeksList}>
                    <ListGroup ref={yearPreview}>
                        {settings.weeks.map((week, index) => {
                            return (
                                <ListGroup.Item
                                    key={index}
                                    action
                                    active={settings.editWeek && index == settings.selectedWeek ? true : false}
                                    onClick={() => handleWeekInYearPreview(index)}
                                >
                                    {week.date} - {findHoliday(week.holiday)}
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </div>
                <div style={AdminWeekSystemHolidaysStyles.yearPreviewSaveSection}>
                    {settings.collides && <Button onClick={handleCollisionBookings}>Collided Bookings</Button>}
                    {settings.orgLocked && <Button onClick={handleUnlock}>Unlock</Button>}
                    <Button onClick={handleSave}>Save</Button>
                </div>
            </div>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={holidayModal.open} onHide={handleHolidayModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{holidayModal.edit ? "Edit" : "Add"} Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Title:</Form.Label>
                        <Form.Control
                            isInvalid={holidayModal.triedSubmmit && !holidayModal.valid ? true : false}
                            type="text"
                            value={holidayModal.name}
                            onChange={handleHolidayTitleChange}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a title</Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {holidayModal.edit ? (
                        <div>
                            <Button onClick={handleHolidayModalDelete}>Remove</Button>
                            <Button onClick={handleHolidayModalUpdate}>Update</Button>
                        </div>
                    ) : (
                        <Button onClick={handleHolidayModalAdd}>Add</Button>
                    )}
                    <Button onClick={handleHolidayModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={modalLg.open} onHide={handleModalLgClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalLg.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalLg.message1 != "" ? modalLg.message1 : null}
                    {modalLg.message2 != "" ? (
                        <div>
                            <br />
                            {modalLg.message2}
                        </div>
                    ) : null}
                    {modalLg.message3 != "" ? (
                        <div>
                            <br />
                            {modalLg.message3}
                        </div>
                    ) : null}
                    {modalLg.boldMessage != "" ? (
                        <div>
                            <br />
                            <strong>{modalLg.boldMessage}</strong>
                        </div>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalLgClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={modalLock.open} onHide={handleModalLockClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalLock.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalLock.message1 != "" ? modalLock.message1 : null}
                    {modalLock.message2 != "" ? (
                        <div>
                            <br />
                            {modalLock.message2}
                        </div>
                    ) : null}
                    {modalLock.message3 != "" ? (
                        <div>
                            <br />
                            {modalLock.message3}
                        </div>
                    ) : null}
                    {modalLock.boldMessage != "" ? (
                        <div>
                            <br />
                            <strong>{modalLock.boldMessage}</strong>
                        </div>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalLockRestore}>
                        Restore
                    </Button>
                    <Button variant="primary" onClick={handleModalLockUnlock}>
                        Unlock
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AdminWeekSystemHolidays;

import * as Colors from "../../../../../../../globalStyles/colors";

export const body = {
    paddingTop: "30px",
    paddingRight: "30px",
    paddingLeft: "30px",
    height: "100%"
};

export const mainHeading = {
    textAlign: "center",
    // border: "1px solid black",
    height: "10%"
};

export const topBanner = {
    textAlign: "right",
    height: "10%"
};

export const restBody = {
    height: "80%",
    overflow: "scroll"
};

export const usersListContainer = {
    width: "40%",
    float: "left",
    height: "100%"
    // border: "1px solid black"
};

export const usersCenterContainer = {
    width: "20%",
    float: "left",
    height: "100%",
    // border: "1px solid black",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
};

export const usersCenterContainerBtn = {
    width: "100%",
    float: "left",
    height: "40%",
    // border: "1px solid black",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
};

export const usersList = {
    height: "90%",
    overflow: "scroll"
};

export const usersListHeading = {
    height: "10%",
    textAlign: "center"
};

export const helpImage = {
    width: "40px",
    height: "50px",
    cursor: "pointer"
};

export const roomsContainer = {
    width: "50%",
    // border: "1px solid black",
    float: "left"
};

export const settingsContainer = {
    width: "50%",
    // border: "1px solid black",
    float: "left",
    paddingLeft: "5px"
};

export const roomsWarning = {
    color: "red",
    height: "50px"
    // border: "1px solid red"
};

export const columnRight = {
    textAlign: "right",
    height: "70px"
    // border: "1px solid black"
};

export const buttonWidth = {
    //SO ALL ROOM BUTTONS ARE THE SAME SIZE
    width: "70%",
    height: "50px",
    fontSize: "1rem",
    textAlign: "center",
    padding: "0"
};

export const hide = {
    visibility: "hidden"
};

export const rooms_title = {
    textAlign: "center"
};

export const rooms_buttonContainer = {
    width: "20%",
    float: "left",
    // border: "solid 1px black",
    textAlign: "center"
};

export const roomsListContainer = {
    height: "300px",
    overflow: "scroll"
};

export const settings = {
    height: "300px",
    borderTop: "1px solid black",
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingTop: "10px"
};

export const settingsAbove = {
    height: "120px",
    position: "relative"
};

export const settings_tab = {
    width: "25%",
    height: "30px",
    textAlign: "center",
    backgroundColor: "white",
    position: "absolute",
    bottom: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px 25px 0px 0px",
    cursor: "pointer"
};

export const settings_selectedTab = {
    width: "25%",
    height: "30px",
    textAlign: "center",
    backgroundColor: Colors.primary,
    position: "absolute",
    bottom: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px 25px 0px 0px",
    fontWeight: "bold",
    color: "white",
    borderTop: "2px solid black",
    borderRight: "2px solid black",
    borderLeft: "2px solid black"
};

export const settings_adminHalf = {
    width: "50%",
    float: "left"
};

import React from "react";
import { Image } from "react-bootstrap";

//COMPONENTS
import usersIcon from "../../images/icons/users.png";
import departmentsIcon from "../../images/icons/departments.png";
import roomsIcon from "../../images/icons/rooms.png";
import layoutsIcon from "../../images/icons/layouts.png";
import weekSystemIcon from "../../images/icons/weekSystemHolidays.png";
import userProfilesIcon from "../../images/icons/userProfiles.png";
import adminIcon from "../../images/icons/admin.png";

//STYLES
import * as FeaturesStyles from "../../styles/pods/features";

function Features() {
    return (
        <div style={FeaturesStyles.body}>
            <br />
            <div style={FeaturesStyles.featRow}>
                <div style={FeaturesStyles.box}>
                    <div style={FeaturesStyles.boxImage}>
                        <Image style={FeaturesStyles.icon} src={usersIcon} />
                    </div>
                    <div style={FeaturesStyles.boxText}>
                        <strong>Users:</strong>
                        <ul style={FeaturesStyles.bulletPiontsSquare}>
                            <li>
                                Three types of accounts,
                                <ul>
                                    <li>Basic ‘User’ - can book single bookings in any room</li>
                                    <li>‘Admin’ - can book single and repeat bookings in any room, and can delete any booking.</li>
                                    <li>
                                        ‘Senior Admin’ - with this role you have got access to the organisation’s administration screen where you can
                                        make your organisation’s portal unique to you. See the administration section for more infromation
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div style={FeaturesStyles.box}>
                    <div style={FeaturesStyles.boxImage}>
                        <Image style={FeaturesStyles.icon} src={departmentsIcon} />
                    </div>
                    <div style={FeaturesStyles.boxText}>
                        <strong>Departments:</strong>
                        <ul style={FeaturesStyles.bulletPiontsSquare}>
                            <li>
                                Assign users to departments so when they book out rooms they only can make a booking with their assigned departments.
                            </li>
                            <li>Edit departments’ names or remove them without affecting any bookings</li>
                        </ul>
                    </div>
                </div>
            </div>
            <br />
            <div style={FeaturesStyles.featRow}>
                <div style={FeaturesStyles.box}>
                    <div style={FeaturesStyles.boxImage}>
                        <Image style={FeaturesStyles.icon} src={roomsIcon} />
                    </div>
                    <div style={FeaturesStyles.boxText}>
                        <strong>Rooms:</strong>
                        <ul style={FeaturesStyles.bulletPiontsSquare}>
                            <li>Add as many rooms as you are allocated for</li>
                            <li>Rename rooms in a single click</li>
                            <li>Set different layouts to different rooms</li>
                            <li>Have some rooms on the ‘week’ system and some rooms free running</li>
                        </ul>
                    </div>
                </div>
                <div style={FeaturesStyles.box}>
                    <div style={FeaturesStyles.boxImage}>
                        <Image style={FeaturesStyles.icon} src={layoutsIcon} />
                    </div>
                    <div style={FeaturesStyles.boxText}>
                        <strong>Layouts:</strong>
                        <ul style={FeaturesStyles.bulletPiontsSquare}>
                            <li>Have different layouts for different rooms</li>
                            <li>Have different type of layouts, timetable or diary</li>
                            <li>Include breaks into the layouts that can be coloured coded</li>
                            <li>Add hover text or sessions, e.g. have the time which that session is on</li>
                        </ul>
                    </div>
                </div>
            </div>
            <br />
            <div style={FeaturesStyles.featRow}>
                <div style={FeaturesStyles.box}>
                    <div style={FeaturesStyles.boxImage}>
                        <Image style={FeaturesStyles.icon} src={weekSystemIcon} />
                    </div>
                    <div style={FeaturesStyles.boxText}>
                        <strong>Week System / Holidays:</strong>
                        <ul style={FeaturesStyles.bulletPiontsSquare}>
                            <li>Some education settings have a two-week timetable, you can set this system to handle the timetable</li>
                            <li>Set each week to the correct ‘week’ number</li>
                            <li>Add holidays so repeated bookings miss the holidays</li>
                            <li>Built it locking and backup system for when editing the weeks</li>
                            <li>Collision detection on bookings and options to restore</li>
                        </ul>
                    </div>
                </div>
                <div style={FeaturesStyles.box}>
                    <div style={FeaturesStyles.boxImage}>
                        <Image style={FeaturesStyles.icon} src={userProfilesIcon} />
                    </div>
                    <div style={FeaturesStyles.boxText}>
                        <strong>User Profiles:</strong>
                        <ul style={FeaturesStyles.bulletPiontsSquare}>
                            <li>Control which user sees which room</li>
                            <li>Have different settings for different rooms</li>
                            <li>Priority the user profiles to override some settings from other profiles for particular users</li>
                            <li>Have up to 5 profiles per user</li>
                            <li>Automatically have a default profile set up to support users that are not in a profile</li>
                        </ul>
                    </div>
                </div>
            </div>
            <br />
            <div style={FeaturesStyles.featRow}>
                <div style={FeaturesStyles.box}>
                    <div style={FeaturesStyles.boxImage}>
                        <Image style={FeaturesStyles.icon} src={adminIcon} />
                    </div>
                    <div style={FeaturesStyles.boxText}>
                        <strong>Administration:</strong>
                        <ul style={FeaturesStyles.bulletPiontsSquare}>
                            <li>Have access to all the sections above to edit</li>
                            <li>Provide your organisation a system tailored to suit your needs</li>
                            <li>Have access to change users login method</li>
                            <li>Set an opening message for users at login</li>
                            <li>Direct users to use a particular login method by disabling and enabling them</li>
                            <li>Enable particular users to have access to the administration</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Features;

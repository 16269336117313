import React, { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function WrongOrganisation() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "STAFF - Sign In";
    }, []);

    function toOrgSignIn() {
        navigate("/", { replace: true });
    }

    return (
        <div>
            <div>
                Sorry, this is not your organisation
                <br /> <br /> <br />
                <Button variant="success" onClick={toOrgSignIn}>
                    Back to my organisation
                </Button>
            </div>
        </div>
    );
}

export default WrongOrganisation;
